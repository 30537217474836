<div class="missingPayOuterBlock" *ngIf="global.displaySplashScreens && global && global.content">
    <div class="d-flex justify-content-end">
        <div class="skipBlock ">{{global.content.skipIn}} {{timer}}s</div>
    </div>
    <div class="d-flex flex-wrap h-100">
        <div class="col-12 col-lg-6 d-flex justify-content-center missingPayBannerBlock">
            <img src="assets/images/organicLogin/onboardScreen1.png" alt="onboardScreen1" *ngIf="global.displaySplashScreens == 1">														
            <img src="assets/images/organicLogin/onboardScreen2.png"  alt="onboardScreen2" *ngIf="global.displaySplashScreens == 2">	
        </div>
        <div class="col-12 col-lg-6 text-center d-lg-flex flex-column justify-content-center">
            <div class="missingPayHeading">
                <div *ngIf="global.displaySplashScreens == 1">														
                    {{global.content.onboard1Head}}														
                </div>	
                <div *ngIf="global.displaySplashScreens == 2">														
                    {{global.content.onboard2Head}}																										
                </div>	
            </div>
            <div class="missingPaySubHeading">
                <div *ngIf="global.displaySplashScreens == 1">{{global.content.onboard1SubHead}}</div>														
                <div *ngIf="global.displaySplashScreens == 2">{{global.content.onboard2SubHead}}</div>
            </div>
            <div>
                <button class="missingPayBtn" (click)="navToLogin(global.displaySplashScreens)">{{global.content.getStarted}}<img  class="mx-2" alt="nextArrowWhiteIcon" src="assets/images/icons/nextArrowWhite.png"></button>
            </div>
            <div  class="d-flex justify-content-center mt-5 flex-wrap" *ngIf="global.displaySplashScreens == 2 ">
                <div class="clickHereLeftBlock">{{global.content.whySeeingThis}}</div>
                <div class="clickHereBlock"(click)="notRelevant()" >{{global.content.clickHereKeyword}}</div>
            </div> 
        </div>
    </div>
</div>


																										



