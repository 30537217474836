<form #verifyDobForm="ngForm" *ngIf="global && global.content">
    <div class="bankLoginStepsBottomInnerBlock">
        <div class="bankLoginstepperHeadings text-center">{{heading}}
        </div>
        <div class="bankLoginNumberStep mt-4">
            <div class="d-flex justify-content-center mt-2 mt-lg-3 BankLoginDobBlockNew w-100"
                *ngFor="let data of dataList;index as i" (click)="submitSelectedData(data,i)">
                <div>{{data}}</div>
            </div>
        </div>
    </div>
    <div class="bankLoginBtnBlock d-flex" *ngIf="attemptValidationChild">
        <div>
            <img src="assets/images/icons/cautionMark.png" alt="cautionMarkIcon" class="cautionMarkIcon">
        </div>
        <div *ngIf="attemptValidationChild">
            <div class="incorrectResponseBlock">{{global.content.incorrectRes}}
                <span *ngIf="userRemaningAttemptChild && userRemaningAttemptChild > 0 ">
                    {{global.content.attemptsLeft}} {{userRemaningAttemptChild}} {{global.content.attemptsLeft2}}
                </span>
            </div>
            <div class="selectCarefully">{{global.content.selectCarefully}}</div>
        </div>
    </div>
    <div class="px-2 px-lg-3 orOptionBlock">
        <div class="d-flex orLineBlock align-items-center">
            <div></div><span>OR</span><div></div>
        </div>
        <div  class="d-flex justify-content-center py-2 flex-wrap" *ngIf="!notRelevantYou" >
            <div class="loggedInBlock">{{global.content.notKeyword}} {{mobileNumberChild ? 'xxxxxxx'  : 'xxx' }}{{trimmedChildValue}}?</div>
            <div class="useAnotherLoginBlock" (click)="anotherLogin()">{{global.content.anotherLogin}}</div>
        </div>
        <div  class="d-flex justify-content-center py-2 flex-wrap" *ngIf="notRelevantYou">
            <div class="loggedInBlock">{{global.content.notRelevantToYou}}</div>
            <div class="useAnotherLoginBlock" (click)="openArdPreLogin();clickTrack('notRelevantToYou','click')" >{{global.content.clickHereKeyword}}</div>
        </div> 
    </div>
</form>
