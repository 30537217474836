import { Injectable, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { configObj } from '../configuration';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Global } from '../global';
import * as CryptoJS from 'crypto-js';
import { Router, ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as engContent from '../../assets/languagesConfig/en.json';

@Injectable({
  providedIn: 'root'
})
export class CommonService implements OnInit {

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    public global: Global,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngxService: NgxUiLoaderService,
    private activatedRoute: ActivatedRoute,
  ) { }
  ngOnInit(): void {
  }
  public displayTnc:boolean = false;
  public displayCardTnc:boolean = false;
  public engLang = engContent['default'];
  public showOfferDownload: boolean = false;
  public showStatementDownload: boolean = false;
  public timephase: any;
  public myDate = new Date();
  public timeCalc: any;
  public categories: any = [];
  public settlementSurveyList: any = [];
  public faqexpansion: any = [];
  public FRIfaqexpansion :any = [];
  public preLoginArcQ = []
  public postLoginArcQ = []
  public detailsForLang: any = {
    uuid: '',
    mobileNumber: '',
    language: 'en',
    email: ''
  };
  public bannerProd: any;
  public status: any;
  public bProduct: any;
  private baseUrl = configObj.baseUrl;
  public selectedLanguage: any;
  public responseData: any;
  public selectedResolution: string;
  public selectedAcc: any;
  public userLogedIn: any = false;
  public selectedAccChange: Subject<number> = new Subject<number>();
  public loadingText: string = null;
  public checkNpa: any = 'status';
  public callingExc = false;
  public selectedAccountData: any;
  screen: any = 1
  public payRepeatScreen: object = {
    screen: '',
    googleTrack: '',
    summeryStatus: '',
    payNowAmount: ''
  }
  public openCards: boolean = true;
  private getSessionId() {
    if(window.sessionStorage && sessionStorage && sessionStorage.getItem('sessionId')  ){
      return this.generateDecRequest(sessionStorage.getItem('sessionId'));
    }
  }

  public prepareHeaders() {
    let sessionId = this.getSessionId();
    if (sessionId) {
      var obj = {
        headers: new HttpHeaders({
          'sessionid': sessionId,
          'cache-control': 'no-cache'
        })
      };
      return obj;
    } else {
      this.userLogedIn = false;
      //User is not logged in so redirect to login page
      this.router.navigate(['login']);
      // return false;
    }
  }

  public checkSession() {
    return new Promise((resolve, reject) => {
      //Call API to check session validity
      if (this.getSessionId()) {
        this.ngxService.start();
        this.http.get(configObj.baseUrl + '/user/session', this.prepareHeaders()).subscribe(
          data => {
            //Save response to global variable
            if (data['accounts'] && data['accounts'].length > 0) {
              if (data['accounts'].length == 1) {
                this.customOptions['loop'] = false;
                this.customOptions['items'] = 1
              }
              else{
                this.customOptions['items'] = 1.1
              }
              this.responseData = this.createDataVariouscases(data);
              setTimeout(() => {
                // only for npa and card page 
                this.setDefaultValues()
              }, 1);
            }
            this.userLogedIn = true;
            this.ngxService.stop();
            resolve(data);
          },
          error => {
            this.ngxService.stop();
            this.userLogedIn = false;
            reject('error');
          }
        );
      }
      else {
        reject('error');
      }
    });
  }

  private createDataVariouscases(data) {
    if (data && data.accounts.length) { 
      data['accounts'].forEach(element => {
        element['NameFull'] = (element['FirstName']?element['FirstName']:'') + ' ' + (element['LastName']?element['LastName']:'')
        element['NameFull'] = element['NameFull'].toLowerCase();
        if(!element['ProjectType']){
          element['ProjectType'] = 'normal'
        }
        if (element['Bucket'] && (element['Bucket'] >= 1 && element['Bucket'] <= 8) && element['ProjectType'] && element['ProjectType'] != 'ARD') {
          this.callingExc = true;
        }
        if (element.BusinessProduct.toLocaleLowerCase().includes('overdraft')) {
          element['accType'] = 'overdraft';
        } else if (element.BusinessProduct.toLocaleLowerCase().includes('loan')) {
          element['accType'] = 'loan'
        } else {
          element['accType'] = 'card';
        }
        element.cardCalss = this.setCardImage(element.BusinessProduct, element.Status, element.OdStatus)


        if (element['payments'] && element['payments'].length) {
          var payAmount = 0;
          element['payments'].forEach((element2) => {
            payAmount = payAmount + element2['Amount']
            element['lastPayment'] = {};  
            element['lastPayment']['amount'] = element2['Amount'];
            element['lastPayment']['time'] = element2['PaymentDate'];
          })
          element['payments']['PaymentAmount'] = payAmount;
        }

        if (element['latestOffer'] && element['latestOffer']['Resolution']) {
          var tempRes = element['latestOffer']['Resolution'].split('To');
          element['latestOffer']['shortResolution'] = 'To' + tempRes[1];
        }
        // if (element.Balance) {
        //   element.Balance = 0
        // }
        const ClosureEMIArray = element.ClosureEmiOptions ? (element.ClosureEmiOptions.toString().split("_")) : null;
        const SettlementEMIArray = element.SettlementEmiOptions ? (element.SettlementEmiOptions.toString().split("_")) : null;
        const WaiverEMIArray = element.WaiverEmiOptions ? (element.WaiverEmiOptions.toString().split("_")) : null;
        if (ClosureEMIArray && ClosureEMIArray.length) {
          if (ClosureEMIArray[0] != 1) {
            ClosureEMIArray.unshift(1)
          }
        }
        if (SettlementEMIArray && SettlementEMIArray.length) {
          if (SettlementEMIArray[0] != 1) {
            SettlementEMIArray.unshift(1)
          }
        }
        if (WaiverEMIArray && WaiverEMIArray.length) {
          if (WaiverEMIArray[0] != 1) {
            WaiverEMIArray.unshift(1)
          }
        }
        element.MaxClosureEmiCount = ClosureEMIArray ? ClosureEMIArray[ClosureEMIArray.length - 1] : null
        element.MaxSettlementEmiCount = SettlementEMIArray ? SettlementEMIArray[SettlementEMIArray.length - 1] : null
        element.MaxWaiverEmiCount = WaiverEMIArray ? WaiverEMIArray[WaiverEMIArray.length - 1] : null

        element.DefaultMaxClosureEmiCount = ClosureEMIArray;
        element.DefaultMaxSettlementEmiCount = SettlementEMIArray;
        element.DefaultMaxWaiverEmiCount = WaiverEMIArray;
        element['ogBalance'] = JSON.parse(JSON.stringify(element.Balance || 0));
        element['ogEmiAmount'] = JSON.parse(JSON.stringify(element.EmiAmount || 0));
        element['ogTotalEmiAmount'] = JSON.parse(JSON.stringify(element.TotalEmiAmount || 0));
        element['ogMinimumAmountDue'] = JSON.parse(JSON.stringify(element.MinimumAmountDue || 0));
        element['ogSingleEmiPlusCharges'] = JSON.parse(JSON.stringify(element.singleEmiPlusCharges  || 0));
        element['ogAllEmiOverduePlusCharges'] = JSON.parse(JSON.stringify(element.AllEmiOverduePlusCharges  || 0));
        if(element['baseSettlementAmount'] && element['Balance'] && element['ProjectType'] && element['ProjectType'].toUpperCase() == 'ARD'){
          element['baseSettlementAmount'] = element['Balance']
        }
        if (element['payments'] && element['payments']['PaymentAmount'] && element['payments']['PaymentAmount'] > 0) {
          // if(element.EmiAmount && element.TotalEmiAmount && element.TotalEmiAmount == element.EmiAmount){
          //   element.TotalEmiAmount = null;
          // }
          element['clearAllPayment'] = false;
          if (element.Balance) {
            element.Balance = element.Balance - element['payments']['PaymentAmount'];
            element.Balance = element.Balance < 0 ? 0 : element.Balance;
          }

          if (element.CurrentBalance) {
            element.CurrentBalance = element.CurrentBalance - element['payments']['PaymentAmount'];
            element.CurrentBalance = element.CurrentBalance < 0 ? 0 : element.CurrentBalance;
          }

          if (element.baseSettlementAmount) {
            element['ogBaseSettlement'] = element.baseSettlementAmount;
            element.baseSettlementAmount = element.baseSettlementAmount - element['payments']['PaymentAmount'];
            element.baseSettlementAmount = element.baseSettlementAmount < 0 ? 0 : element.baseSettlementAmount;
          }

          if (element.MinimumAmountDue) {
            element.MinimumAmountDue = (element.MinimumAmountDue - element['payments']['PaymentAmount']);
            element.MinimumAmountDue = element.MinimumAmountDue < 0 ? 0 : element.MinimumAmountDue;
          }

          if (element.OverDueAmount) {
            element.OverDueAmount = (element.OverDueAmount - element['payments']['PaymentAmount']);
            element.OverDueAmount = element.OverDueAmount < 0 ? 0 : element.OverDueAmount;
          }

          if (element.LastBucketAmount) {
            element.LastBucketAmount = (element.LastBucketAmount - element['payments']['PaymentAmount']);
            element.LastBucketAmount = element.LastBucketAmount < 0 ? 0 : element.LastBucketAmount;
          }

          if (element.Penalty && !element.EmiAmount && !element.TotalEmiAmount) {
            if (element['payments']['PaymentAmount'] >= element.Penalty) {
              // element['clearAllPayment'] = true;
            }
            element.Penalty = element.Penalty - element['payments']['PaymentAmount'];
            element.Penalty = element.Penalty < 0 ? 0 : element.Penalty;
          }
          if (element.TotalEmiAmount) {
            if (element['payments']['PaymentAmount'] > element.TotalEmiAmount) {
              element.EmiAmount = null;
              if (element['payments']['PaymentAmount'] >= (element.TotalEmiAmount + element.Penalty)) {
                element.TotalEmiAmount = null;
                element.Penalty = null;
                // element['clearAllPayment'] = true;
              } else {
                let restAmt = element['payments']['PaymentAmount'] - element.TotalEmiAmount;
                if (restAmt > element.Penalty) {
                  element.Penalty = null;
                  // element['clearAllPayment'] = true;
                } else {
                  element.Penalty = element.Penalty - restAmt;
                }
                element.TotalEmiAmount = null;
              }
            } else {
              element.TotalEmiAmount = element.TotalEmiAmount - element['payments']['PaymentAmount'];
              if (!element.TotalEmiAmount) {
                element.EmiAmount = null;
              } else if (element['payments']['PaymentAmount'] > element.EmiAmount) {
                element.EmiAmount = null;
              } else if (element['payments']['PaymentAmount'] < element.EmiAmount) {

              }
              // element['clearAllPayment'] = false;
            }
            // element.TotalEmiAmount = element.TotalEmiAmount - element['payments']['PaymentAmount'];
            // element.TotalEmiAmount = element.TotalEmiAmount < 0 ? 0 : element.TotalEmiAmount;
          }
          if (element.EmiAmount) {
            if (element['payments']['PaymentAmount'] > element.EmiAmount) {
              if (element['payments']['PaymentAmount'] >= (element.EmiAmount + element.Penalty)) {
                element.EmiAmount = null;
                element.Penalty = null;
                // element['clearAllPayment'] = true;
              } else {
                let restAmt = element['payments']['PaymentAmount'] - element.EmiAmount;
                if (restAmt > element.Penalty) {
                  element.Penalty = null;
                  // element['clearAllPayment'] = true;
                } else {
                  element.Penalty = element.Penalty - restAmt;
                }
                element.EmiAmount = null;
              }
            } else {
              element.EmiAmount = element.EmiAmount - element['payments']['PaymentAmount'];
              element['clearAllPayment'] = false;
            }
            // element.EmiAmount = element.EmiAmount - element['payments']['PaymentAmount'];
            // element.EmiAmount = element.EmiAmount < 0 ? 0 : element.EmiAmount;
          }
          //need to test

          if (element.singleEmiPlusCharges ) {
            element.singleEmiPlusCharges  = (element.singleEmiPlusCharges  - element['payments']['PaymentAmount']);
            element.singleEmiPlusCharges  = element.singleEmiPlusCharges  < 0 ? 0 : element.singleEmiPlusCharges ;
          }
          if (element.AllEmiOverduePlusCharges ) {
            element.AllEmiOverduePlusCharges  = (element.AllEmiOverduePlusCharges  - element['payments']['PaymentAmount']);
            element.AllEmiOverduePlusCharges  = element.AllEmiOverduePlusCharges  < 0 ? 0 : element.AllEmiOverduePlusCharges ;
          }
          

          // all payemnts are done

          if ((element.latestOffer && element.latestOffer.CustomerPlacedOfferAmount) && element.latestOffer.CustomerPlacedOfferAmount <= element['payments']['PaymentAmount']) {
            element['clearAllPayment'] = true;
          }
          if (!element.CurrentBalance && !element.Balance && !element.baseSettlementAmount && !element.MinimumAmountDue && !element.singleEmiPlusCharges && !element.AllEmiOverduePlusCharges && !element.OverDueAmount && !element.EmiAmount && !element.TotalEmiAmount && !element.Penalty && !element.Stabalization) {
            element['clearAllPayment'] = true;
          }
        } else {
          element['payments'] = { "PaymentAmount": 0 };
        }

        if(
          this.global && this.global.bankName && this.global.bankName == 'Kotak Mahindra Bank' && !element['ARD'] &&
          ((element['Penalty']  || element['EmiAmount'] || element['TotalEmiAmount'] ) ||
          (element['Balance']) ||
          (element['baseSettlementAmount'] && element['baseSettlementAmount'] != 'null')) && !element['clearAllPayment'] && element['ProjectType'] && element['ProjectType'] != 'ARD'
        ){
          element['FRI'] = true;
        }

        // if (element['payments'] && element['payments'].PaymentAmount > 0 && element['latestOffer'] != null && element['latestOffer']) {
        //   this.showOfferDownload = true;
        // }
        // if (element['BusinessProduct'].toLocaleLowerCase() != 'credit card') {
        //   this.showStatementDownload = true
        // }
        // if (element.MinimumAmountDue == 0 || element.MinimumAmountDue < 0) {
        //   element.MinimumAmountDue = null;
        // }
        // element['fastPayment'] = this.showCardData(element)
      });
    }
    return data
  }

  private setDefaultValues() {
    var showAccount = this.getSelectedCardId();
    var changePosition = this.responseData.accounts[showAccount ? showAccount : 0];
    this.customOptions['startPosition'] = null
    this.customOptions['startPosition'] = 0
    this.openAllCards(showAccount, changePosition['accountId'])
  }

  public openAllCards(i, dataId) {
    this.payRepeatScreen = {}
    this['otherAmountValidate'] = '';
    // setAmount
    if (this.openCards) {
      this['tempWaiverAmount'] = '';
      this['setAmount'] = '';
      let changePosition = this.responseData.accounts[i];
      this.responseData.accounts.splice(i, 1);
      this.responseData.accounts.unshift(changePosition)
      this['selectedAccountData'] = changePosition;
      this.trackUser('selectedAccount_' + this.selectedAccountData.AccountNumber, 'click', this.selectedAccountData);
      this.bannerBusinessProduct(this['selectedAccountData']);
      this.fetchBannerText(this['selectedAccountData'])
      this.changeLangProductAndSataus();
      // set selected card data to sessiondata
      this.setSelectedCardSession(dataId);
      this.showHideOATile();
      // if (this['selectedAccountData']['latestOffer']) {
      //   this.checkLatesOfferAndCreatePaymentPlan(this['selectedAccountData']['latestOffer'])
      // }
    }
    this.openCards = !this.openCards;
  }

  public selectedEmi = 1
  checkLatesOfferAndCreatePaymentPlan(datalist) {
    if (datalist) {
      this['selectedAccountData'].approvedPaymentPlanList = [];
      this.ngxService.start()
      this.getOfferPlanData(datalist).subscribe(
        res =>{
          this.ngxService.stop()
          if(res){
            this.trackUser("getOfferPlanData","apiCall",this.selectedAccountData)
            var data:any = []
            data = res
            data.forEach((element,index) => {
              element['emiAmount'] = element['amount']
              element['remaningAmount'] = (( element['emiAmount']  * element['serialNo']) - this['selectedAccountData']['payments']['PaymentAmount']) > 0 ? (( element['emiAmount']  * element['serialNo']) - this['selectedAccountData']['payments']['PaymentAmount']) : 0
              if(datalist['NumberOfEmi'] == element['serialNo'] && this['selectedAccountData'].approvedPaymentPlanList[element['serialNo'] - 2] && this['selectedAccountData'].approvedPaymentPlanList[element['serialNo'] - 2]['remaningAmount'] && this['selectedAccountData'].approvedPaymentPlanList[element['serialNo'] - 2]['remaningAmount'] < 0.05){
                    this['selectedAccountData'].approvedPaymentPlanList[element['serialNo']-1]['emiAmount'] =  element['emiAmount']  + this['selectedAccountData'].approvedPaymentPlanList[element['serialNo']-2]['remaningAmount']
                    this['selectedAccountData'].approvedPaymentPlanList[element['serialNo']-1]['emiAmount'] =+  this['selectedAccountData'].approvedPaymentPlanList[element['serialNo']-1]['emiAmount'].toFixed(2)
              }
            });
            this['selectedAccountData'].approvedPaymentPlanList = data
            var setDeafult = false
            this['selectedAccountData'].approvedPaymentPlanList.forEach((element, index) => {
              element.remaningAmount = parseFloat(element.remaningAmount.toFixed(2))
              if (!setDeafult && element.remaningAmount && element.remaningAmount > 0.05 ) {
                element.checked = true;
                this.selectedEmi = index;
                this.selectPaymentUser(element);
                setDeafult = true
              }
            });
          }
        },
        err =>{
          this.ngxService.stop()
          this.trackUser("getOfferPlanData_Error","apiCall",this.selectedAccountData)
          this.returnValidError(err)
        }
      )
      // var amount = (datalist['CustomerPlacedOfferAmount'] / datalist['NumberOfEmi'])
      // for (let i = 1; i <= datalist['NumberOfEmi']; i++) {
      //   // this['selectedAccountData'].approvedPaymentPlanList.push({ paymentDate: moment(datalist['paymentStartDate']).add(i - 1, 'M').format('YYYY-MM-DD'), emiAmount: (datalist['offerAmount'] / datalist['noOfEmi']), remaningAmount: ((datalist['offerAmount'] / datalist['noOfEmi']) * i) });
      //   this['selectedAccountData'].approvedPaymentPlanList.push({ paymentDate: moment(datalist['PaymentStartDate']).add(i - 1, 'M').format('YYYY-MM-DD'), emiAmount: amount, remaningAmount: ((amount * i) - this['selectedAccountData']['payments']['PaymentAmount'] > 0 ? (amount * i) - this['selectedAccountData']['payments']['PaymentAmount'] : 0) });
      //   if(datalist['NumberOfEmi'] == i && this['selectedAccountData'].approvedPaymentPlanList[i - 2] && this['selectedAccountData'].approvedPaymentPlanList[i - 2]['remaningAmount'] && this['selectedAccountData'].approvedPaymentPlanList[i - 2]['remaningAmount'] < 0.05){
      //         this['selectedAccountData'].approvedPaymentPlanList[i-1]['emiAmount'] = amount + this['selectedAccountData'].approvedPaymentPlanList[i-2]['remaningAmount']
      //         this['selectedAccountData'].approvedPaymentPlanList[i-1]['emiAmount'] =+  this['selectedAccountData'].approvedPaymentPlanList[i-1]['emiAmount'].toFixed(2)
      //   }
      // }
      // var setDeafult = false
      // this['selectedAccountData'].approvedPaymentPlanList.forEach((element, index) => {
      //   element.remaningAmount = parseFloat(element.remaningAmount.toFixed(2))
      //   if (!setDeafult && element.remaningAmount && element.remaningAmount > 0.05 ) {
      //     element.checked = true;
      //     this.selectedEmi = index;
      //     this.selectPaymentUser(element);
      //     setDeafult = true
      //   }
      // });
      // console.log(this['selectedAccountData'].approvedPaymentPlanList)

    }
  }

  selectedPaymentbyUserFrom: any = {}
  selectPaymentUser(data) {
    this.selectedPaymentbyUserFrom = data
  }


  public customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    center: true,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    // responsive: {
    //   0: {
    //     items: 1.1
    //   },
    //   600: {
    //     items: 1
    //   },
    //   1000: {
    //     items: 3
    //   }
    // }
  };

  public setSelectedCardSession(dataId) {
    sessionStorage.removeItem('selectedCard');
    if(window.sessionStorage && sessionStorage){
      sessionStorage.setItem('selectedCard', dataId);
    }
    this.screen = null;
    this.fetchBannerText(this['selectedAccountData'])

    if (this['selectedAccountData']['latestOffer']) {
      this.checkLatesOfferAndCreatePaymentPlan(this['selectedAccountData']['latestOffer'])
      this.screen = 'settlePaymentPlan'
    }
    // if (this['selectedAccountData'].MinimumAmountDue) {
    //   // this.screen = 1
    // } else {
    //   // this.screen = 2
    // }
  }


  npaPageHeaderSubheading: any = {}
  fetchBannerText(data) {
    this.npaPageHeaderSubheading = undefined;
    let statusObj: any = { CARD: 'CARD', LOAN: 'LOAN', OVERDRAFT: 'OVERDRAFT' }
    let type = data['accType'] == 'card' ? statusObj['CARD'] : statusObj['LOAN']
    if (data['accType'] == 'card') {
      type = statusObj['CARD']
    } else if (data['accType'] == 'loan') {
      type = statusObj['LOAN']
    } else if (data['accType'] == 'overdraft') {
      type = statusObj['OVERDRAFT']
    }

    let headingSubheadLsit = [
      { product: "CARD", status: data['Status'].toUpperCase(), heading: this.global.content.OuterPRENPAInnerLOANElseGoodCreditScoreMeansEasyCredit, subHeading: this.global.content.BANNERSUBHEADMissingYourCreditPayments },
      { product: "LOAN", status: data['Status'].toUpperCase(), heading: this.global.content.OuterPRENPAInnerLOANElseGoodCreditScoreMeansEasyCredit, subHeading: this.global.content.BANNERSUBHEADMissingYourCreditPayments },
      { product: 'OVERDRAFT', status: data['Status'].toUpperCase(), heading: this.global.content.npaLoanH, subHeading: this.global.content.npaLoanS },

      { product: 'CARD', status: 'PRENPA', heading: this.global.content.OuterPRENPAInnerLOANElseGoodCreditScoreMeansEasyCredit, subHeading: this.global.content.BANNERSUBHEADMissingYourCreditPayments },
      { product: 'CARD', status: 'NPA', heading: this.global.content.npaCcH, subHeading: this.global.content.npaCcS + " " + this.global.content.bankName + " " + this.global.content.npaCcSHalf },
      { product: 'CARD', status: 'WRITE OFF', heading: this.global.content.writeoffCCH, subHeading: this.global.content.writeoffCCS + " " + this.global.content.bankName + " " + this.global.content.writeoffCCSHalf },
      { product: 'CARD', status: 'LIVE', heading: this.global.content.liveBannerHeadCard1, subHeading: this.global.content.liveBannerContentLoan1 + ' ' + this.bannerProd + ' ' + this.global.content.liveBannerContentLoan2 },
      { product: 'CARD', status: 'MATURED', heading: this.global.content.maturedBannerHead, subHeading: this.global.content.maturedBannerSubHead },

      { product: 'LOAN', status: 'PRENPA', heading: this.global.content.BANNERHEADBuildASTrongCreditProfile, subHeading: this.global.content.BANNERSUBHEADYouCanAStrongCreditProfile + ' ' + this.bannerProd + ' ' + this.global.content.BANNERSUBHEADGoAHeadAndPayYourUnderstanding },
      { product: 'LOAN', status: 'NPA', heading: this.global.content.npaLoanH, subHeading: this.global.content.npaLoanS },
      { product: 'LOAN', status: 'WRITE OFF', heading: this.global.content.writeoffLoanH, subHeading: this.global.content.writeoffLoanS },
      { product: 'LOAN', status: 'LIVE', heading: this.global.content.liveBannerHeadCard1, subHeading: this.global.content.liveBannerContentLoan1 + ' ' + this.bannerProd + ' ' + this.global.content.liveBannerContentLoan2 },
      { product: 'LOAN', status: 'MATURED', heading: this.global.content.maturedBannerHead, subHeading: this.global.content.maturedBannerSubHead },

      // need to change for od
      { product: 'OVERDRAFT', status: 'PRENPA', heading: this.global.content.BANNERHEADBuildASTrongCreditProfile, subHeading: this.global.content.BANNERSUBHEADYouCanAStrongCreditProfile + ' ' + this.bannerProd + ' ' + this.global.content.BANNERSUBHEADGoAHeadAndPayYourUnderstanding },
      { product: 'OVERDRAFT', status: 'NPA', heading: this.global.content.npaLoanH, subHeading: this.global.content.npaLoanS },
      { product: 'OVERDRAFT', status: 'WRITE OFF', heading: this.global.content.writeoffLoanH, subHeading: this.global.content.writeoffLoanS },
      { product: 'OVERDRAFT', status: 'LIVE', heading: this.global.content.liveBannerHeadCard1, subHeading: this.global.content.liveBannerContentLoan1 + ' ' + this.bannerProd + ' ' + this.global.content.liveBannerContentLoan2 },

    ]

    headingSubheadLsit.forEach(element => {
      if (element.status == data.Status.toUpperCase() && element.product == type.toUpperCase()) {
        this.npaPageHeaderSubheading = element
      }
    });
    this.npaPageHeaderSubheading['bannerClass'] = this.setBannerImage(data['BusinessProduct'], data['Status'])

  }
  private getSelectedCardId() {
    let index: any = 0
    if (window.sessionStorage && sessionStorage && sessionStorage.getItem('selectedCard')) {
      let accountId = sessionStorage.getItem('selectedCard')
      index = this.responseData.accounts.findIndex(x => x.accountId === accountId);
      if (index == -1) {
        index = 0
      }
    }
    return index
  }
  public emitSearchInputChangesEvent(input) {
    this.selectedAccChange.next(input);
  }
  public getQueryCategory() {
    this.categories = [
      { value: 'Account Details', viewValue: this.global.content.accountDetail, catImg: 'assets/images/icons/accountDetails.svg' },
      { value: 'Offers/Settlement', viewValue: this.global.content.offersSettlement, catImg: 'assets/images/icons/offerSettlement.svg' },
      { value: 'Letters', viewValue: this.global.content.letters, catImg: 'assets/images/icons/letter.svg' },
      { value: 'Payments', viewValue: this.global.content.payments, catImg: 'assets/images/icons/payments.svg' },
      { value: 'CIBIL Closure', viewValue: this.global.content.CIBILClosure, catImg: 'assets/images/icons/cibilClosure.svg' },
      { value: 'Others', viewValue: this.global.content.other, catImg: 'assets/images/icons/others.svg' },
    ];
  }
  public getSettlementSurveyOpt() {
    this.settlementSurveyList = [
      { value: 'Job Loss ', viewValue: this.global.content.jobLoss },
      { value: 'Loss of Business', viewValue: this.global.content.lossOfBussiness },
      { value: 'Medical Emergency', viewValue: this.global.content.medicalEmergency },
      { value: 'Other Financial Obligation', viewValue: this.global.content.otherFinObligation },
      { value: 'Others', viewValue: this.global.content.other },
    ]
  }
  public getFaqs() {
    this.setCardTncVal()
    this.setTncVal()
    this.getQueryCategory();
    
    // this.faqexpansion = [
    //   {
    //     heading: this.global.content.faq1,
    //     desription: this.global.content.faqAns1
    //   },
    //   {
    //     heading: this.global.content.faq2,
    //     desription: this.global.content.faqAns2
    //   },
    //   {
    //     heading: this.global.content.faq3,
    //     desription: this.global.content.faqAns3
    //   },
    //   {
    //     heading: this.global.content.faq4,
    //     desription: this.global.content.faqAns4
    //   },
    //   {
    //     heading: this.global.content.faq5,
    //     desription: this.global.content.faqAns5
    //   },
    //   {
    //     heading: this.global.content.faq6,
    //     desription: this.global.content.faqAns6
    //   },
    //   {
    //     heading: this.global.content.faq7,
    //     desription: this.global.content.faqAns7
    //   },
    //   {
    //     heading: this.global.content.faq8,
    //     desription: this.global.content.faqAns8
    //   },
    //   {
    //     heading: this.global.content.faq9,
    //     desription: this.global.content.faqAns9
    //   },
    //   {
    //     heading: this.global.content.faq10,
    //     desription: this.global.content.faqAns10
    //   },
    //   {
    //     heading: this.global.content.faq11,
    //     desription: this.global.content.faqAns11
    //   },
    //   {
    //     heading: this.global.content.faq12,
    //     desription: this.global.content.faqAns12
    //   },
    //   {
    //     heading: this.global.content.faq13,
    //     desription: this.global.content.faqAns13
    //   },
    //   {
    //     heading: this.global.content.faq14,
    //     desription: this.global.content.faqAns14
    //   },
    //   {
    //     heading: this.global.content.faq15,
    //     desription: this.global.content.faqAns15
    //   },
    //   {
    //     heading: this.global.content.faq16,
    //     desription: this.global.content.faqAns16
    //   },
    //   {
    //     heading: this.global.content.faq17,
    //     desription: this.global.content.faqAns17
    //   },
    //   {
    //     heading: this.global.content.faq18,
    //     desription: this.global.content.faqAns18
    //   },
    //   {
    //     heading: this.global.content.faq19,
    //     desription: this.global.content.faqAns19
    //   },
    //   {
    //     heading: this.global.content.faq20,
    //     desription: this.global.content.faqAns20
    //   },
    //   {
    //     heading: this.global.content.faq21,
    //     desription: this.global.content.faqAns21
    //   },
    //   {
    //     heading: this.global.content.faq22,
    //     desription: this.global.content.faqAns22
    //   },
    //   {
    //     heading: this.global.content.faq23,
    //     desription: this.global.content.faqAns23
    //   },
    //   {
    //     heading: this.global.content.faq24,
    //     desription: this.global.content.faqAns24
    //   },
    //   // {
    //   //   heading: this.global.content.faq25,
    //   //   desription: this.global.content.faqAns25
    //   // },
    //   // {
    //   //   heading: this.global.content.faq26,
    //   //   desription: this.global.content.faqAns26
    //   // },
    //   // {
    //   //   heading: this.global.content.faq27,
    //   //   desription: this.global.content.faqAns27
    //   // },
    //   // {
    //   //   heading: this.global.content.faq28,
    //   //   desription: this.global.content.faqAns28
    //   // },
    //   // {
    //   //   heading: this.global.content.faq29,
    //   //   desription: this.global.content.faqAns29
    //   // },
    //   // {
    //   //   heading: this.global.content.faq30,
    //   //   desription: this.global.content.faqAns30
    //   // },
    //   // {
    //   //   heading: this.global.content.faq31,
    //   //   desription: this.global.content.faqAns31
    //   // },
    //   // {
    //   //   heading: this.global.content.faq32,
    //   //   desription: this.global.content.faqAns32
    //   // },
    //   // {
    //   //   heading: this.global.content.faq33,
    //   //   desription: this.global.content.faqAns33
    //   // },
    //   // {
    //   //   heading: this.global.content.faq34,
    //   //   desription: this.global.content.faqAns34
    //   // },
    //   // {
    //   //   heading: this.global.content.faq35,
    //   //   desription: this.global.content.faqAns35
    //   // },
    //   // {
    //   //   heading: this.global.content.faq36,
    //   //   desription: this.global.content.faqAns36
    //   // },
    //   // {
    //   //   heading: this.global.content.faq37,
    //   //   desription: this.global.content.faqAns37
    //   // },
    //   // {
    //   //   heading: this.global.content.faq38,
    //   //   desription: this.global.content.faqAns38
    //   // },
    //   // {
    //   //   heading: this.global.content.faq39,
    //   //   desription: this.global.content.faqAns39
    //   // },
    //   // {
    //   //   heading: this.global.content.faq40,
    //   //   desription: this.global.content.faqAns40
    //   // },
    //   // {
    //   //   heading: this.global.content.faq41,
    //   //   desription: this.global.content.faqAns41
    //   // },
    //   // {
    //   //   heading: this.global.content.faq42,
    //   //   desription: this.global.content.faqAns42
    //   // },
    //   // {
    //   //   heading: this.global.content.faq43,
    //   //   desription: this.global.content.faqAns43
    //   // },
    //   // {
    //   //   heading: this.global.content.faq44,
    //   //   desription: this.global.content.faqAns44
    //   // },
    //   // {
    //   //   heading: this.global.content.faq45,
    //   //   desription: this.global.content.faqAns45
    //   // },
    //   // {
    //   //   heading: this.global.content.faq46,
    //   //   desription: this.global.content.faqAns46
    //   // },
    //   // {
    //   //   heading: this.global.content.faq47,
    //   //   desription: this.global.content.faqAns47
    //   // },

      
    //   // {
    //   //   heading: this.global.content.faq48,
    //   //   desription: this.global.content.faqAns48
    //   // },
    //   // {
    //   //   heading: this.global.content.faq49,
    //   //   desription: this.global.content.faqAns49
    //   // },
    //   // {
    //   //   heading: this.global.content.faq50,
    //   //   desription: this.global.content.faqAns50
    //   // },
    //   // {
    //   //   heading: this.global.content.faq51,
    //   //   desription: this.global.content.faqAns51
    //   // },
    //   // {
    //   //   heading: this.global.content.faq52,
    //   //   desription: this.global.content.faqAns52
    //   // },
    //   // {
    //   //   heading: this.global.content.faq53,
    //   //   desription: this.global.content.faqAns53
    //   // },
    //   // {
    //   //   heading: this.global.content.faq54,
    //   //   desription: this.global.content.faqAns54
    //   // },
    //   // {
    //   //   heading: this.global.content.faq55,
    //   //   desription: this.global.content.faqAns55
    //   // },

    //   // {
    //   //   heading: this.global.content.faq56,
    //   //   desription: this.global.content.faqAns56
    //   // },
    //   // {
    //   //   heading: this.global.content.faq57,
    //   //   desription: this.global.content.faqAns57
    //   // },
    //   // {
    //   //   heading: this.global.content.faq58,
    //   //   desription: this.global.content.faqAns58
    //   // },
    //   // {
    //   //   heading: this.global.content.faq59,
    //   //   desription: this.global.content.faqAns59
    //   // },
    //   // {
    //   //   heading: this.global.content.faq60,
    //   //   desription: this.global.content.faqAns60
    //   // },
    //   // {
    //   //   heading: this.global.content.faq61,
    //   //   desription: this.global.content.faqAns61
    //   // },
    //   // {
    //   //   heading: this.global.content.faq62,
    //   //   desription: this.global.content.faqAns62
    //   // },
    //   // {
    //   //   heading: this.global.content.faq63,
    //   //   desription: this.global.content.faqAns63
    //   // },
    //   // {
    //   //   heading: this.global.content.faq64,
    //   //   desription: this.global.content.faqAns64
    //   // },
    //   // {
    //   //   heading: this.global.content.faq65,
    //   //   desription: this.global.content.faqAns65
    //   // },
    //   // {
    //   //   heading: this.global.content.faq66,
    //   //   desription: this.global.content.faqAns66
    //   // },
    // ];

    if(this.global && this.global.bankName && this.global.bankName == 'Kotak Mahindra Bank'){
      this.FRIfaqexpansion = [
        {
          heading: this.global.content.FRIQ1,
          desription: this.global.content.FRIans1
        },
        {
          heading: this.global.content.FRIQ2,
          desription: this.global.content.FRIans2
        },
        {
          heading: this.global.content.FRIQ3,
          desription: this.global.content.FRIans3
        },
        {
          heading: this.global.content.FRIQ4,
          desription: this.global.content.FRIans4
        },
        {
          heading: this.global.content.FRIQ5,
          desription: this.global.content.FRIans5
        },
        {
          heading: this.global.content.FRIQ6,
          desription: this.global.content.FRIans6
        },
        {
          heading: this.global.content.FRIQ7,
          desription: this.global.content.FRIans7
        },
        {
          heading: this.global.content.FRIQ8,
          desription: this.global.content.FRIans8
        },
        {
          heading: this.global.content.FRIQ9,
          desription: this.global.content.FRIans9
        },
        {
          heading: this.global.content.FRIQ10,
          desription: this.global.content.FRIans10
        },
        {
          heading: this.global.content.FRIQ11,
          desription: this.global.content.FRIans11
        },
        {
          heading: this.global.content.FRIQ12,
          desription: this.global.content.FRIans12
        },
        {
          heading: this.global.content.FRIQ13,
          desription: this.global.content.FRIans13
        },
      ]
    }

    if(this.displayCardTnc && !this.displayTnc){
        this.faqexpansion = [ {
        heading: this.global.content.faq1,
        desription: this.global.content.faqAns1
      },
      {
        heading: this.global.content.faq2,
        desription: this.global.content.faqAns2
      },
      {
        heading: this.global.content.faq3,
        desription: this.global.content.faqAns3
      },
      {
        heading: this.global.content.faq4,
        desription: this.global.content.faqAns4
      },
      {
        heading: this.global.content.faq5,
        desription: this.global.content.faqAns5
      },
      {
        heading: this.global.content.faq6,
        desription: this.global.content.faqAns6
      },
      {
        heading: this.global.content.faq7,
        desription: this.global.content.faqAns7
      },
      {
        heading: this.global.content.faq8,
        desription: this.global.content.faqAns8
      },
      {
        heading: this.global.content.faq9,
        desription: this.global.content.faqAns9
      },
      {
        heading: this.global.content.faq10,
        desription: this.global.content.faqAns10
      },
      {
        heading: this.global.content.faq11,
        desription: this.global.content.faqAns11
      },
      {
        heading: this.global.content.faq12,
        desription: this.global.content.faqAns12
      },
      {
        heading: this.global.content.faq13,
        desription: this.global.content.faqAns13
      },
      {
        heading: this.global.content.faq14,
        desription: this.global.content.faqAns14
      },
      {
        heading: this.global.content.faq15,
        desription: this.global.content.faqAns15
      },
      {
        heading: this.global.content.faq16,
        desription: this.global.content.faqAns16
      },
      {
        heading: this.global.content.faq17,
        desription: this.global.content.faqAns17
      },
      {
        heading: this.global.content.faq18,
        desription: this.global.content.faqAns18
      },
      {
        heading: this.global.content.faq19,
        desription: this.global.content.faqAns19
      }
    ]
    
    }
    else if(!this.displayCardTnc && this.displayTnc){
      this.faqexpansion = [
        {
          heading: this.global.content.faq20,
          desription: this.global.content.faqAns20
        },
        {
          heading: this.global.content.faq21,
          desription: this.global.content.faqAns21
        },
        {
          heading: this.global.content.faq22,
          desription: this.global.content.faqAns22
        },
        {
          heading: this.global.content.faq23,
          desription: this.global.content.faqAns23
        },
        {
          heading: this.global.content.faq24,
          desription: this.global.content.faqAns24
        },
        {
          heading: this.global.content.faq25,
          desription: this.global.content.faqAns25
        },
        {
          heading: this.global.content.faq26,
          desription: this.global.content.faqAns26
        },
        {
          heading: this.global.content.faq27,
          desription: this.global.content.faqAns27
        },
        {
          heading: this.global.content.faq28,
          desription: this.global.content.faqAns28
        },
        {
          heading: this.global.content.faq29,
          desription: this.global.content.faqAns29
        },
        {
          heading: this.global.content.faq30,
          desription: this.global.content.faqAns30
        },
        {
          heading: this.global.content.faq31,
          desription: this.global.content.faqAns31
        },
        {
          heading: this.global.content.faq32,
          desription: this.global.content.faqAns32
        },
        {
          heading: this.global.content.faq33,
          desription: this.global.content.faqAns33
        },
        {
          heading: this.global.content.faq34,
          desription: this.global.content.faqAns34
        },
        {
          heading: this.global.content.faq35,
          desription: this.global.content.faqAns35
        },
        {
          heading: this.global.content.faq36,
          desription: this.global.content.faqAns36
        },
        {
          heading: this.global.content.faq37,
          desription: this.global.content.faqAns37
        },
        {
          heading: this.global.content.faq38,
          desription: this.global.content.faqAns38
        },
        {
          heading: this.global.content.faq39,
          desription: this.global.content.faqAns39
        },
        {
          heading: this.global.content.faq40,
          desription: this.global.content.faqAns40
        },
        {
          heading: this.global.content.faq41,
          desription: this.global.content.faqAns41
        },
        {
          heading: this.global.content.faq42,
          desription: this.global.content.faqAns42
        },
        {
          heading: this.global.content.faq43,
          desription: this.global.content.faqAns43
        },
        {
          heading: this.global.content.faq44,
          desription: this.global.content.faqAns44
        },
        {
          heading: this.global.content.faq45,
          desription: this.global.content.faqAns45
        },
        {
          heading: this.global.content.faq46,
          desription: this.global.content.faqAns46
        },
        {
          heading: this.global.content.faq47,
          desription: this.global.content.faqAns47
        },
        {
          heading: this.global.content.faq48,
          desription: this.global.content.faqAns48
        },
        {
          heading: this.global.content.faq49,
          desription: this.global.content.faqAns49
        },
        {
          heading: this.global.content.faq50,
          desription: this.global.content.faqAns50
        }
      ]
    }
    else{
      this.faqexpansion = [
        {
          heading: this.global.content.faq1,
          desription: this.global.content.faqAns1
        },
        {
          heading: this.global.content.faq2,
          desription: this.global.content.faqAns2
        },
        {
          heading: this.global.content.faq3,
          desription: this.global.content.faqAns3
        },
        {
          heading: this.global.content.faq4,
          desription: this.global.content.faqAns4
        },
        {
          heading: this.global.content.faq5,
          desription: this.global.content.faqAns5
        },
        {
          heading: this.global.content.faq6,
          desription: this.global.content.faqAns6
        },
        {
          heading: this.global.content.faq7,
          desription: this.global.content.faqAns7
        },
        {
          heading: this.global.content.faq8,
          desription: this.global.content.faqAns8
        },
        {
          heading: this.global.content.faq9,
          desription: this.global.content.faqAns9
        },
        {
          heading: this.global.content.faq10,
          desription: this.global.content.faqAns10
        },
        {
          heading: this.global.content.faq11,
          desription: this.global.content.faqAns11
        },
        {
          heading: this.global.content.faq12,
          desription: this.global.content.faqAns12
        },
        {
          heading: this.global.content.faq13,
          desription: this.global.content.faqAns13
        },
        {
          heading: this.global.content.faq14,
          desription: this.global.content.faqAns14
        },
        {
          heading: this.global.content.faq15,
          desription: this.global.content.faqAns15
        },
        {
          heading: this.global.content.faq16,
          desription: this.global.content.faqAns16
        },
        {
          heading: this.global.content.faq17,
          desription: this.global.content.faqAns17
        },
        {
          heading: this.global.content.faq18,
          desription: this.global.content.faqAns18
        },
        {
          heading: this.global.content.faq19,
          desription: this.global.content.faqAns19
        },
        {
          heading: this.global.content.faq20,
          desription: this.global.content.faqAns20
        },
        {
          heading: this.global.content.faq21,
          desription: this.global.content.faqAns21
        },
        {
          heading: this.global.content.faq22,
          desription: this.global.content.faqAns22
        },
        {
          heading: this.global.content.faq23,
          desription: this.global.content.faqAns23
        },
        {
          heading: this.global.content.faq24,
          desription: this.global.content.faqAns24
        },
        {
          heading: this.global.content.faq25,
          desription: this.global.content.faqAns25
        },
        {
          heading: this.global.content.faq26,
          desription: this.global.content.faqAns26
        },
        {
          heading: this.global.content.faq27,
          desription: this.global.content.faqAns27
        },
        {
          heading: this.global.content.faq28,
          desription: this.global.content.faqAns28
        },
        {
          heading: this.global.content.faq29,
          desription: this.global.content.faqAns29
        },
        {
          heading: this.global.content.faq30,
          desription: this.global.content.faqAns30
        },
        {
          heading: this.global.content.faq31,
          desription: this.global.content.faqAns31
        },
        {
          heading: this.global.content.faq32,
          desription: this.global.content.faqAns32
        },
        {
          heading: this.global.content.faq33,
          desription: this.global.content.faqAns33
        },
        {
          heading: this.global.content.faq34,
          desription: this.global.content.faqAns34
        },
        {
          heading: this.global.content.faq35,
          desription: this.global.content.faqAns35
        },
        {
          heading: this.global.content.faq36,
          desription: this.global.content.faqAns36
        },
        {
          heading: this.global.content.faq37,
          desription: this.global.content.faqAns37
        },
        {
          heading: this.global.content.faq38,
          desription: this.global.content.faqAns38
        },
        {
          heading: this.global.content.faq39,
          desription: this.global.content.faqAns39
        },
        {
          heading: this.global.content.faq40,
          desription: this.global.content.faqAns40
        },
        {
          heading: this.global.content.faq41,
          desription: this.global.content.faqAns41
        },
        {
          heading: this.global.content.faq42,
          desription: this.global.content.faqAns42
        },
        {
          heading: this.global.content.faq43,
          desription: this.global.content.faqAns43
        },
        {
          heading: this.global.content.faq44,
          desription: this.global.content.faqAns44
        },
        {
          heading: this.global.content.faq45,
          desription: this.global.content.faqAns45
        },
        {
          heading: this.global.content.faq46,
          desription: this.global.content.faqAns46
        },
        {
          heading: this.global.content.faq47,
          desription: this.global.content.faqAns47
        },
        {
          heading: this.global.content.faq48,
          desription: this.global.content.faqAns48
        },
        {
          heading: this.global.content.faq49,
          desription: this.global.content.faqAns49
        },
        {
          heading: this.global.content.faq50,
          desription: this.global.content.faqAns50
        }
      ];
    }
  }
  /**
* This method submits mobile number
* @param form Form Object
*/
  public trackUser(label: any, action: any, allData: any): void {
    if (!allData) {
      allData = this.selectedAccountData ? this.selectedAccountData : null;
    }

    // //Show Loader
    // this.ngxService.start();
    this.trackUserApi(label, action, allData).subscribe(
      data => {
        //Hide loader
        // this.ngxService.stop();
      },
      error => {
        //Hide loader
        // this.ngxService.stop();
      }
    );
  }

  public trackUserApi(label, action, data) {
    let sessionId = this.getSessionId();
    if (!data) {
      data = this.selectedAccountData ? this.selectedAccountData : null;
    }
    // const httpHeaders = new HttpHeaders({
    //   'sessionid': sessionId
    // });

    var userUniqueCode
    if (window.sessionStorage && sessionStorage && sessionStorage.getItem('userUniqueCode')) {
      userUniqueCode = sessionStorage.getItem('userUniqueCode')
    } else {
        if(window.sessionStorage && sessionStorage){
          sessionStorage.setItem('userUniqueCode', this.generateRandomString(16));
        }
        userUniqueCode = sessionStorage.getItem('userUniqueCode')
    }

    var ucode
    if (window.sessionStorage && sessionStorage && sessionStorage.getItem('ucode')) {
      ucode = sessionStorage.getItem('ucode')
    }
    let requestBody = {
      'pageName': this.router.url,
      'label': label,
      'action': action,
      "customerUniqueId": userUniqueCode,
      "sessionId": sessionId ? sessionId : null,
      "pageCategory": "digitalPortal",
      "uuid":ucode ? ucode : null,
      // "referrer": document.referrer
    };
    if (data && data.MobileNumber) {
      requestBody['mobileNumber'] = data.MobileNumber;
    }
    if (data && data.email) {
      requestBody['email'] = data.email;
    }
    if (data && data.accountId) {
      requestBody['accountId'] = data.accountId;
    }
    var url = configObj.baseUrl + '/account/behaviour'
    if (sessionId) {
      return this.http.post(url, requestBody, this.prepareHeaders());
    } else {
      return this.http.post(url, requestBody);
    }
  }

  /**
   * Method to generate random string
   * @param length string length
   */
  public generateRandomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public logout() {
    this.userLogedIn = false;
    return this.http.get(configObj.baseUrl + '/user/logout', this.prepareHeaders());
  }

  public generateEncRequest(strToEnc) {
    const key = CryptoJS.enc.Utf8.parse(configObj.key);
    const randomIV = this.generateRandomString(16);
    const iv = CryptoJS.enc.Utf8.parse(randomIV);
    const plainText: string = strToEnc.toString();
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return randomIV + encrypted.toString();
  }

  public generateDecRequest(strToEnc) {
    strToEnc = strToEnc ? strToEnc.toString() : 'null';
    let data = decodeURIComponent(strToEnc);
    let iv = data.slice(0, 16);
    let encryptedData = data.substring(16, data.length);
    // var CryptoJS = require('crypto-js');
    var Key = CryptoJS.enc.Utf8.parse(configObj.key);
    var IV = CryptoJS.enc.Utf8.parse(iv);
    return CryptoJS.AES.decrypt(encryptedData, Key, {
      keySize: 16,
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }

  paymentStatus(id) {
    var url = configObj.baseUrl + '/account/tranxStatus/'
    return this.http.get(url + id, this.prepareHeaders());
  }

  paymentStatusReturn(id) {
    var url = configObj.baseUrl + '/account/tranxGenStatus/'
    return this.http.get(url + id);
  }

  public payment(accountId, paymentAmount) {
    let requestBody = {
      "accountId": accountId,
      "request": this.generateEncRequest(paymentAmount),
      "paymentType": this.fetchPaymentModeKey(this['paymentModeStatus'])
    }
    // console.log(requestBody)
    // alert(requestBody.paymentType)
    return this.http.post(this.baseUrl + '/account/makePayment', requestBody, this.prepareHeaders());
  }

  private fetchPaymentModeKey(val) {
    var value = 'OTHER AMOUNT'
    if (val) {
      if (val.includes("DIRPAYMENT_")) {
        val = val.split('_')
        val = val[1]
      }
      if (val.toUpperCase() == 'MINIMUM AMOUNT DUE' || val.toUpperCase() == 'MAD') {
        val = 'MINIMUM AMOUNT DUE'
      } else if (val.toUpperCase() == 'CURRENT BALANCE' || val.toUpperCase() == 'CB') {
        val = 'CURRENT BALANCE'
      } else if (val.toUpperCase() == 'TOTAL AMOUNT DUE' || val.toUpperCase() == 'TAD') {
        val = 'TOTAL AMOUNT DUE'
      }
      else if (val.toUpperCase() == 'OTHER AMOUNT' || val.toUpperCase() == 'OTHER AMOUNT DUE' || val.toUpperCase() == 'OA') {
        val = 'OTHER AMOUNT'
      }
      else if (val.toUpperCase() == 'SETTELMENT AMOUNT DUE' || val.toUpperCase() == 'SETTELMENT AMOUNT' || val.toUpperCase() == 'SA') {
        val = 'SETTLEMENT AMOUNT'
      }
      else if (val.toUpperCase() == 'WAIVER AMOUNT DUE' || val.toUpperCase() == 'WAIVER AMOUNT' || val.toUpperCase() == 'WA') {
        val = 'WAIVER AMOUNT'
      }
      else if (val.toUpperCase() == 'EA') {
        val = 'Stabilization Amount'
      }
      else if (val.toUpperCase() == 'PAYONEOVERDUEEMI' || val.toUpperCase() == 'EMIAMOUNT') {
        val = '1 overdue emi'
      } else if (val.toLowerCase() == 'payemiamountandpenaltycharges' || val.toLowerCase() == 'emiandpenalty') {
        val = '1 Overdue EMI + Penalty charges'
      }
      else if (val.toLowerCase() == 'payalloverdueemi' || val.toLowerCase() == 'totalemiamount') {
        val = 'All Overdue EMI'
      }
      else if (val.toLowerCase() == 'payallemiandpenalty' || val.toLowerCase() == 'totalemiamountandpenalty') {
        val = 'All Overdue EMI + Penalty charges'
      }
      else if (val.toLowerCase() == 'penalty') {
        val = 'Penalty Charges'
      }
      else if (val.toLowerCase() == 'offerfullpayment') {
        val = 'offer full payment'
      }
      else if (val.toLowerCase() == 'offeremiamount' || val.toLowerCase() == 'payfirstemi' || val.toLowerCase() == 'emi amount') {
        val = 'offer emi amount'
      }
      else if (val.toUpperCase() == 'POS') {
        val = 'principal Outstanding'
      }
      else if (val.toUpperCase() == 'ODA') {
        val = 'Overdue Amount'
      }


      value = val
    }
    return value.toLowerCase();
  }


  public checkPenaltyBlock(data) {
    var paymentAmount: any = (data['payments'] && data['payments']['PaymentAmount']) ? (data['payments'] && data['payments']['PaymentAmount']) : 0
    if (data['TotalEmiAmount']) {
      if ((paymentAmount >= data['ogTotalEmiAmount']) && data['Penalty']) {
        return true;
      } else {
        return false;
      }
    } else if (data['EmiAmount']) {
      if ((paymentAmount >= data['ogEmiAmount']) && data['Penalty']) {
        return true;
      } else {
        return false;
      }
    } else if (data['Penalty']) {
      return true;
    } else {
      return false;
    }
  }

  public isCard(value) {
    var check = value.toLocaleLowerCase().includes('card');
    return check;
  }

  public setCardImage(type, status, OdStatus) {
    if (type.toLowerCase().includes('wheeler')) {
      return 'twoWheelerLoanCardBlock';
    } else if (type.toLowerCase().includes('personal')) {
      return 'personalLoanCardBlock';
    } else if (type.toLowerCase().includes('home')) {
      return 'homeLoanCardBlock';
    } else if (type.toLowerCase().includes('consumer')) {
      return 'consumerDurableCardBlock';
    } else if (type.toLowerCase().includes('auto')) {
      return 'autoLoanCardBlock';
    } else if (type.toLowerCase().includes('lap' || 'property')) {
      return 'lapLoanCardBlock';
    } else if (type.toLowerCase().includes('business')) {
      return 'businessLoanCardBlock';
    } else if (type.toLowerCase().includes('payDay')) {
      return 'payDayLoanCardBlock';
      // need to change for od
    } else if (OdStatus && OdStatus.toLowerCase() == 'live') {
      return 'liveOverdraftCardBlock';
    } else if (OdStatus && OdStatus.toLowerCase() == 'expired') {
      return 'expiredOverdraftCardBlock';
    } else if (type.toLowerCase().includes('card')) {
      if (status.toLowerCase() == 'npa') {
        return 'npaCardBlock';
      } else {
        return 'preNpaCardBlock';
      }
    } else {
      if (this.isCard(type)) {
        return 'preNpaCardBlock';
      } else {
        return 'otherLoanCardBlock';
      }
    }
  }

  public setBannerImage(type, status) {
    if (type.toLowerCase().includes('wheeler')) {
      return 'otherLoanBanner';
    }
    else if (type.toLowerCase().includes('personal')) {
      return 'personalLoanBanner';
    }
    else if (type.toLowerCase().includes('home')) {
      return 'homeLoanBanner';
    } else if (type.toLowerCase().includes('consumer')) {
      return 'consumerDurableBanner';
      // need to change for od
    } else if (type.toLowerCase().includes('overdraft')) {
      if (status.toLowerCase() == 'prenpa') {
        return 'preNpaOverdraftBanner';
      }
      else if (status.toLowerCase() == 'npa') {
        return 'npaOverdraftBanner';
      }
      else if (status.toLowerCase() == 'write off') {
        return 'writeOffOverdraftBanner';
      }
      else {
        return 'liveOverdraftBanner';
      }
    } else if (type.toLowerCase().includes('card')) {
      if (status.toLowerCase() == 'npa') {
        return 'npaBanner';
      } else {
        return 'preNpaBanner';
      }
    }
    else {
      if (this.isCard(type)) {
        return 'preNpaBanner';
      } else {
        return 'otherLoanBanner';
      }
    }
  }

  public offerToClosedPlace(data, selectedAccountData, counter?: any, offerStatus?: any,checkOffer?) {
    let requestBody = {
      "accountId": selectedAccountData.accountId,
      "customerPlacedOfferAmount": data.amount * selectedAccountData.MaxClosureEmiCount,
      // "customerPlacedOfferAmount": this.calEmiAmount(data),
      "paymentStartDate": moment(data.paymentDate).format('YYYY-MM-DD'),
      "resolution": this.trimSpaceFun(selectedAccountData['Status']) + "ToClosed",
      "numberOfEmi": selectedAccountData.MaxClosureEmiCount,
      "isCounterOffer": counter ? counter : false,
      "offerStatus": offerStatus ? offerStatus : null
    }
    if(checkOffer){
      requestBody['checkOffer'] = checkOffer
    }
    var url = configObj.baseUrl + '/account/placeOffer'
    return this.http.post(url, requestBody, this.prepareHeaders());
  }

  public offerToSettelPlace(data, selectedAccountData, counter?: any, offerStatus?: any,checkOffer?) {
    let requestBody = {
      "accountId": selectedAccountData.accountId,
      // "customerPlacedOfferAmount": data[0].amount * (data.length),
      "customerPlacedOfferAmount": this.calEmiAmount(data),
      "paymentStartDate": moment(data[0].paymentDate).format('YYYY-MM-DD'),
      "resolution": this.trimSpaceFun(selectedAccountData['Status']) + "ToSettled",
      "numberOfEmi": data.length,
      "isCounterOffer": counter ? counter : false,
      "offerStatus": offerStatus ? offerStatus : null
    }
    if(checkOffer){
      requestBody['checkOffer'] = checkOffer
    }
    var url = configObj.baseUrl + '/account/placeOffer'
    return this.http.post(url, requestBody, this.prepareHeaders());
  }

  public offerToWaiverPlace(data, selectedAccountData, counter?: any, offerStatus?: any) {
    let requestBody = {
      "accountId": selectedAccountData.accountId,
      // "customerPlacedOfferAmount": data[0].amount * (data.length),
      "customerPlacedOfferAmount": this.calEmiAmount(data),
      "paymentStartDate": moment(data[0].paymentDate).format('YYYY-MM-DD'),
      "resolution": this.trimSpaceFun(selectedAccountData['Status']) + "ToWaiver",
      "numberOfEmi": data.length,
      "isCounterOffer": counter ? counter : false,
      "offerStatus": offerStatus ? offerStatus : null
    }
    var url = configObj.baseUrl + '/account/placeOffer'
    return this.http.post(url, requestBody, this.prepareHeaders());
  }

  public transformInr(value: number): string {
    value = Math.round(value);
    var result = value.toString().split('.');
    var lastThree = result[0].substring(result[0].length - 3);
    var otherNumbers = result[0].substring(0, result[0].length - 3);
    if (otherNumbers != '' && otherNumbers != '-')
      lastThree = ',' + lastThree;
    var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

    if (result.length > 1) {
      output += "." + result[1];
    }
    return output;
  }

  public returnValidError(error) {
    if (error && error.error && error.error.cause) {
      if (error.status == 401 || error.status == 404) {
        this.redirecToLoginWithClearAll(error.error)
      } else {
        // this.toastr.error('Sorry your request could not be processed. Please try again.');
        this.toastr.error(this.global.content.somethingWentWrong);
      }
    } else {
      this.toastr.error(this.global.content.somethingWentWrong);
    }
  }

  public redirecToLoginWithClearAll(error) {
    if (error && error.cause && (error.cause.toLowerCase().includes('user is not logged in') || error.cause.toLowerCase().includes('no session found'))) {
      sessionStorage.clear()
      this.toastr.error(this.global.content.userNotLogIn);
      this.router.navigate(['login']);
      return false;
    } else {
      this.toastr.error(this.global.content.somethingWentWrong);
      return false;
    }
  }

  public haveQueries(queries: any) {
    var url = configObj.baseUrl + '/user/query'
    let requestBody = {
      "customerName": queries.name,
      "mobile": queries.contact,
      "category": queries.category,
      "email": queries.email,
      "message": queries.message,
      "registrationId": this.responseData.registrationId,
      "accountNumber": queries.account
    }
    return this.http.post<any>(url, requestBody, this.prepareHeaders());
  }

  public downloadPDF(url) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

  downloadNocLetter(type, id, data) {
    var url;
    // if (data['payments']['PaymentAmount'] >= data['ogBaseSettlement']) {
    //   url = configObj.baseUrl + '/account/download/clletter'
    // }
    // else {
    //   url = configObj.baseUrl + '/account/download/nocLetter/' + type
    // }
    url = configObj.baseUrl + '/account/download/nocLetter/' + type
    return this.http.get(url + '/' + id, this.prepareHeaders());
    
  }

  viewNocLetter(type, id) {
    var url = configObj.baseUrl + '/account/view/nocLetter/'
    return this.http.get(url + type + '/' + id, this.prepareHeaders());
  }
  /**
  * This method subscribe transmitChoosedLang() function
  * @param lang user selected language 
  */
  public chnglang(lang: any) {
    // this.global.userSelectedLang = lang;
    // this.global.returnLangObj(lang);
    // this.detailsForLang['language'] = lang;
    // // global.userSelectedLang = data.short;
    // // commonServices.detailsForLang['language'] = data.short;
    // this.detailsForLang.mobileNumber = (this.responseData && this.responseData.accounts) ? this.responseData.accounts[0].MobileNumber : this.detailsForLang.mobileNumber;
    // this.detailsForLang.email = (this.responseData && this.responseData.accounts) ? this.responseData.accounts[0].email : this.detailsForLang.email;
    // if (this.detailsForLang.email || this.detailsForLang.mobileNumber) {
    //   this.transmitChoosedLang(this.detailsForLang).subscribe(
    //     data => {
    //     },
    //     err => {
    //       if(window.sessionStorage && sessionStorage){
    //         sessionStorage.setItem("setSelectedLang", lang);
    //       }
    //     }
    //   )
    // } else {
    //   if(window.sessionStorage && sessionStorage){
    //     sessionStorage.setItem("setSelectedLang", lang);
    //   }
    // }
    // this.setDataAfterLanguage(this.detailsForLang['language']);
  }

  public getLangFromSessionStorage() {
    // if(window.sessionStorage && sessionStorage && sessionStorage.getItem("setSelectedLang")){
    //   var lang = sessionStorage.getItem("setSelectedLang");
    // }
    // if (lang && ((this.responseData && this.responseData.accounts) || (this.detailsForLang['mobileNumber'] || this.detailsForLang['email']))) {
    //   var languageSetData = {}
    //   languageSetData['language'] = lang;
    //   this.detailsForLang['language'] = lang;
    //   languageSetData['email'] = (this.responseData && this.responseData.accounts) ? this.responseData.accounts[0].email : this.detailsForLang.email;
    //   languageSetData['mobileNumber'] = (this.responseData && this.responseData.accounts) ? this.responseData.accounts[0].MobileNumber : this.detailsForLang.mobileNumber;
    //   if (languageSetData['mobileNumber'] || languageSetData['email']) {
    //     this.transmitChoosedLang(languageSetData).subscribe(
    //       res => {
    //         this.setDataAfterLanguage(lang);
    //       },
    //       err => {
    //       }
    //     )
    //   }
    // }
    // else { return false; }
  }
  /**
  * This method is used to update content as per user selected language
  * @param lang user selected language 
  */
  public setDataAfterLanguage(lang) {
    this.global.userSelectedLang = lang;
    this.global.returnLangObj(lang);
    this.detailsForLang['language'] = lang;
    setTimeout(() => {
      this.ardFormQ()
    }, 100);
    setTimeout(() => {
      if (this.responseData && this.responseData.accounts && this['selectedAccountData']) {
        // this.createDataVariouscases(this.responseData);
        this.getSettlementSurveyOpt();
        this.getFaqs();
        this.bannerBusinessProduct(this['selectedAccountData']);
        this.fetchBannerText(this['selectedAccountData']);
        this.CalculatetimeInterval();
        this.changeLangProductAndSataus();
      }
    }, 500)
  }
  /**
    * This method is used to post user selected language
    * @param detail selected language object
    */
  public transmitChoosedLang(detail: any) {
    // var url = configObj.baseUrl + '/user/language';
    // var requestBody = {
    //   "uuid": detail.uuid,
    //   "language": detail.language
    // }
    // if (detail.mobileNumber) {
    //   requestBody['mobileNumber'] = detail.mobileNumber
    // }
    // else {
    //   requestBody['email'] = detail.email
    // }
    // return this.http.post(url, requestBody);
  }
  /**
    * This method is used to post user mobile number to get  last selected language 
    * @param details user mobile number
    */
  public getLanguage(details) {
    // var url = configObj.baseUrl + '/user/language/last';
    // var requestBody: any;
    // if (details.mobileNumber) {
    //   requestBody = { "mobileNumber": details.mobileNumber }
    // }
    // else {
    //   requestBody = { "email": details.email }
    // }
    // return this.http.post(url, requestBody);
  }

  CalculatetimeInterval() {
    var hrs = this.myDate.getHours();
    if (hrs < 12) {
      this.timephase = this.global.content.morning;
    }
    else if (hrs >= 12 && hrs <= 17) {
      this.timephase = this.global.content.afternoon;
    }
    else if (hrs >= 17 && hrs <= 24) {
      this.timephase = this.global.content.evening;
    }
    else {
      this.timephase = this.global.content.morning
    }
    this.timeCalc = setInterval(() => {
      this.CalculatetimeInterval();
    }, 60000)
  }
  changeLangProductAndSataus() {
    this.responseData.accounts.forEach(element => {
      if (element.BusinessProduct.toUpperCase() == 'CREDIT CARD') {
        element['BusinessProductLang'] = this.global.content.creditCardProduct;
      }
      else if (element.BusinessProduct.toUpperCase() == 'PERSONAL LOAN') {
        element['BusinessProductLang'] = this.global.content.personalLoanProduct;
      }
      else if (element.BusinessProduct.toUpperCase() == 'BUSINESS LOAN') {
        element['BusinessProductLang'] = this.global.content.businessLoanProduct;
      }
      else if (element.BusinessProduct.toUpperCase() == 'HOME LOAN') {
        element['BusinessProductLang'] = this.global.content.homeLoanProduct
      }
      else if (element.BusinessProduct.toUpperCase() == 'LOAN AGAINST PROPERTY') {
        element['BusinessProductLang'] = this.global.content.lapProduct
      }
      else if (element.BusinessProduct.toUpperCase() == 'PAYDAY LOAN ') {
        element['BusinessProductLang'] = this.global.content.paydayLoanProduct
      }
      else if (element.BusinessProduct.toUpperCase() == 'CONSUMER DURABLE LOAN') {
        element['BusinessProductLang'] = this.global.content.cdLoanProduct
      }
      else if (element.BusinessProduct.toUpperCase() == 'OVERDRAFT') {
        if (element.OdStatus.toUpperCase() == 'EXPIRED') {
          element['BusinessProductLang'] = this.global.content.expired + ' ' + this.global.content.overdraft
        }
        else {
          element['BusinessProductLang'] = this.global.content.live + ' ' + this.global.content.overdraft
        }
      }
      else {
        element['BusinessProductLang'] = element.BusinessProduct
      }
      if (element.Status.toUpperCase() == 'WRITE OFF') {
        element.statusLang = this.global.content.writeOffStatus;
      }
      else if (element.Status.toUpperCase() == 'PRENPA') {
        element.statusLang = this.global.content.overdueStatus;
      }
      else if (element.Status.toUpperCase() == 'LIVE') {
        if(element['Bucket'] &&  (/^[a-zA-Z()]+$/).test(element['Bucket']) && element['Bucket'].toUpperCase() == 'P'){
          element.statusLang = 'Past Due Date';
        }else if(element['Bucket'] && (/^[a-zA-Z()]+$/).test(element['Bucket']) && element['Bucket'].toUpperCase() == 'O'){
          element.statusLang = 'Over Credit Limit';
        }else{
          element.statusLang = this.global.content.dueStatus;
        }
      }
      else if (element.Status.toUpperCase() == 'NPA') {
        element.statusLang = this.global.content.npaStatus;
      }
      else if (element.Status.toUpperCase() == 'MATURED') {
        element.statusLang = this.global.content.maturedStatus;
      }
      else {
        element.statusLang = element.Status;
      }
    })


  }
  bannerBusinessProduct(prod: any) {
    if (prod.BusinessProduct.toUpperCase() == 'CREDIT CARD') {
      this.bannerProd = this.global.content.creditCardProduct;
    }
    else if (prod.BusinessProduct.toUpperCase() == 'PERSONAL LOAN') {
      this.bannerProd = this.global.content.personalLoanProduct;
    }
    else if (prod.BusinessProduct.toUpperCase() == 'BUSINESS LOAN') {
      this.bannerProd = this.global.content.businessLoanProduct;
    }
    else if (prod.BusinessProduct.toUpperCase() == 'HOME LOAN') {
      this.bannerProd = this.global.content.homeLoanProduct
    }
    else if (prod.BusinessProduct.toUpperCase() == 'LOAN AGAINST PROPERTY') {
      this.bannerProd = this.global.content.lapProduct
    }
    else if (prod.BusinessProduct.toUpperCase() == 'PAYDAY LOAN ') {
      this.bannerProd = this.global.content.paydayLoanProduct
    }
    else if (prod.BusinessProduct.toUpperCase() == 'CONSUMER DURABLE LOAN') {
      this.bannerProd = this.global.content.cdLoanProduct
    }
    else {
      this.bannerProd = prod.BusinessProduct
    }
  }


  public callingservice(value) {
    let requestBody = {
      "mobileNumber": value,
      "source": "CXPORTAL"
    }
    return this.http.post(this.baseUrl + '/dialer/makecall', requestBody, this.prepareHeaders());
  }

  public getSettlementSurvey(accountId) {
    return this.http.get(configObj.baseUrl + '/account/settlement/ans/' + accountId, this.prepareHeaders());
  }

  public postSettlementSurvey(value, qNum, accountId) {
    let requestBody = {
      "accountId": accountId,
      "questionId": qNum,
      "answer": value.surveyReasonOpt,
    }
    if (value.surveyReasonOpt.toUpperCase() == 'OTHERS') {
      requestBody.answer = value.otherArea
    }
    return this.http.post(this.baseUrl + '/account/settlement/ans', requestBody, this.prepareHeaders());
  }

  public trimSpaceFun(value) {
    var trimVal = value.replace(/\s/g, "")
    return trimVal;
  }
  
  startDragging(event: any, label) {
    this['otherAmountValidate'] = ''
    if (event && label == 'caroCard') {
      this.customOptions.startPosition = event.data.startPosition;
    }
    else if (event && label == 'expandCard') {
      this.customOptions.startPosition = event.cardDataInner.startPosition;
    }
    this['selectedAccountData'] = this.responseData.accounts[this.customOptions.startPosition ? this.customOptions.startPosition : 0]

    this.trackUser('selectedAccount_' + this['selectedAccountData'].AccountNumber, 'click', this['selectedAccountData']);
    // set selected card data to sessiondata
    this.setSelectedCardSession(this['selectedAccountData']['accountId'])
  }

  public submitRegId(regID) {
    var url = configObj.baseUrl + '/account/onboard/' + regID;
    return this.http.get(url, this.prepareHeaders());
  }

  showHideOATile(){
    var minMAxArray = []
      if (this.selectedAccountData.MinimumAmountDue) {
        minMAxArray.push(this.selectedAccountData.MinimumAmountDue)
      }
      if (this.selectedAccountData.OverDueAmount) {
        minMAxArray.push(this.selectedAccountData.OverDueAmount)
      }
      if (this.selectedAccountData.CurrentBalance) {
        minMAxArray.push(this.selectedAccountData.CurrentBalance)
      }
      if (this.selectedAccountData.baseSettlementAmount) {
        minMAxArray.push(this.selectedAccountData.baseSettlementAmount)
      }
      if (this.selectedAccountData.LastBucketAmount) {
        minMAxArray.push(this.selectedAccountData.LastBucketAmount)
      }
      if (this.selectedAccountData.Balance) {
        minMAxArray.push(this.selectedAccountData.Balance)
      }
      if (this.selectedAccountData.EmiAmount && this.selectedAccountData.Penalty) {
        let amo = this.selectedAccountData.EmiAmount + this.selectedAccountData.Penalty
        minMAxArray.push(amo)
      }
      if (this.selectedAccountData.EmiAmount && !this.selectedAccountData.Penalty) {
        let amo = this.selectedAccountData.EmiAmount
        minMAxArray.push(amo)
      }
      if (this.selectedAccountData.TotalEmiAmount && this.selectedAccountData.Penalty) {
        let amo = this.selectedAccountData.TotalEmiAmount + this.selectedAccountData.Penalty
        minMAxArray.push(amo)
      }
      if (this.selectedAccountData.TotalEmiAmount && !this.selectedAccountData.Penalty) {
        let amo = this.selectedAccountData.TotalEmiAmount
        minMAxArray.push(amo)
      }
      if (this.selectedAccountData.singleEmiPlusCharges ) {
        minMAxArray.push(this.selectedAccountData.singleEmiPlusCharges )
      }
      if (this.selectedAccountData.AllEmiOverduePlusCharges ) {
        minMAxArray.push(this.selectedAccountData.AllEmiOverduePlusCharges )
      }
      
      if ((!this.selectedAccountData.Balance && !this.selectedAccountData.TotalEmiAmount && !this.selectedAccountData.EmiAmount) && this.selectedAccountData.Penalty) {
        let amo = this.selectedAccountData.Penalty
        minMAxArray.push(amo)
      }

      var sortedArray = minMAxArray.sort(function (a, b) { return a - b })
      var minValue = 0
      var maxValue = 0

      if (sortedArray && sortedArray.length > 0) {
        minValue = sortedArray[0]
        maxValue = sortedArray[sortedArray.length - 1]
      }

      var finalMinValue = minValue
      var finalMaxValue = Math.ceil(maxValue)
      // console.log(minValue + '--' + maxValue)
      if( (this.global.bankName && this.global.bankName == 'Kotak Mahindra Bank') && ( (minValue < 100) || (maxValue <= 100) ) ){
        this.selectedAccountData['hideOtherAmount'] = true; 
      }else{
        this.selectedAccountData['hideOtherAmount'] = false;
      }
  }

  postCallingDetails(value,mobileNumber,date){
    var requestBody = {
      "mobileNumber" : mobileNumber,
      "source" : "CXPORTAL",
      "accountNumber" : value.account,
      "typeOfQuery" : value.queryType,
      // "prefferedTimeToCallBack" : value.selectedTime ? date + ' '+ value.selectedTime  : '',
      "descriptionOfQuery" : value.description,
      "preferredContactNumberToCallBack" : value.contact ? value.contact : ''
    }
    return this.http.post(this.baseUrl + '/dialer/makecall', requestBody, this.prepareHeaders());
  }

  getCallDetails(){
    return this.http.get(this.baseUrl + '/dialer/request/status', this.prepareHeaders());
  }



  includesFRIamount(){
    if(
      (this['selectedAccountData']['Penalty'] ) ||
      (this['selectedAccountData']['EmiAmount'] )|| 
      (this['selectedAccountData']['EmiAmount'] && this['selectedAccountData']['Penalty'] && !this['selectedAccountData']['TotalEmiAmount']) || 
      (this['selectedAccountData']['TotalEmiAmount'])||
      (this['selectedAccountData']['TotalEmiAmount'] && this['selectedAccountData']['Penalty']) ||
      (!this['selectedAccountData']['latestOffer'] && this['selectedAccountData']['Balance'] && this['selectedAccountData']['accType'] == 'loan') ||
      (!this['selectedAccountData']['latestOffer'] && this.selectedAccountData['baseSettlementAmount'] && this.selectedAccountData['baseSettlementAmount'] != 'null' && this['selectedAccountData']['accType'] == 'loan')
    )
    {
      return true;
    }
    else{
      return false;
    }
  }


  
  public postLoginARC(data,type?:any,number?:any){
    let requestBody = {
      "type":"ALTERNATE",
    }
    if(type  == 'POSTLOGIN'){
        requestBody['questionText'] = "Not Relevant To You -Post Login"
        requestBody['mobileNumber'] = this.selectedAccountData['MobileNumber']
        if(data['arcPostLogin'].toLowerCase() == 'others'){
          requestBody['answerText'] = data.otherArea
        }
        else{
          requestBody['answerText'] = data.arcPostLogin
        }
    }
    else{
      requestBody['questionText'] = "Not Relevant To You -Pre Login"
      requestBody['mobileNumber'] = number
      if(data['arcPreLogin'].toLowerCase() == 'others'){
        requestBody['answerText'] = data.otherArea
      }
      else{
        requestBody['answerText'] = data.arcPreLogin
      }
    }
    return this.http.post(this.baseUrl + '/account/question/answer', requestBody);

  }

  ardFormQ(){
    if(this.userLogedIn){
      this.postLoginArcQ = [
        {postLoginOpt:this.global.content.dontHaveBankAcc + ' ' + this.global.content.bankFullName + ' ' + this.global.content.dontHaveBankAcc1 ,value:this.engLang.dontHaveBankAcc + ' ' + this.engLang.bankFullName + ' ' + this.engLang.dontHaveBankAcc1 },
        {postLoginOpt:this.global.content.dontWantCommOnThisNum,value:this.engLang.dontWantCommOnThisNum},
        {postLoginOpt:this.global.content.accountNoMoreInUse,value:this.engLang.accountNoMoreInUse},
        {postLoginOpt:this.global.content.other,value:this.engLang.other}
      ]
    }
   else{
    this.preLoginArcQ = [
      {preLoginOpt:this.global.content.dontHaveBankAcc + ' ' + this.global.content.bankFullName + ' ' + this.global.content.dontHaveBankAcc1,value:this.engLang.dontHaveBankAcc + ' ' + this.engLang.bankFullName + ' ' + this.engLang.dontHaveBankAcc1 },
      {preLoginOpt:this.global.content.dontWantCommOnThisNum,value:this.engLang.dontWantCommOnThisNum},
      {preLoginOpt:this.global.content.other,value:this.engLang.other}
    ]
   }

  }

  
  okayForSettlementGet(){
    var url =  configObj.baseUrl + "/account/metainfo/" + this.selectedAccountData['accountId'];
    return this.http.get(url,this.prepareHeaders())
  }

  okayForSettlementPost(){
    var url = configObj.baseUrl + "/account/settlement/consent";
    var requestBody ={
      "accountId":this.selectedAccountData['accountId']
    }
    return this.http.post(url,requestBody,this.prepareHeaders())
  }

  getViewCardDetails(value){
    var url = configObj.baseUrl + '/account/ondemandbalance/' + value;
    return this.http.get(url, this.prepareHeaders());
  }

  postDownloadStatement(month,year){
    var url = configObj.baseUrl + '/user/account/cc/statement/' + this.selectedAccountData['accountId'];
    var requestBody = {
      "month":month,
      "year":year
    }
    return this.http.post(url,requestBody, this.prepareHeaders());
  }

  postConsentOtp(otp,authId){
    var url = configObj.baseUrl + '/account/settlement/consent'
    var requestBody = {
      "id":authId,
      "operationType":"ValidateOtp",
      "authKey":this.generateEncRequest(otp)
    }
    return this.http.post(url,requestBody, this.prepareHeaders());
  }

  postConsentTnC(){
    var url = configObj.baseUrl + '/account/settlement/consent'
    var requestBody = {
      "accountId":this.selectedAccountData['accountId']
    }
    return this.http.post(url,requestBody, this.prepareHeaders());
  }

  resendConsentOtp(authId){
    var url = configObj.baseUrl + '/account/resendOtp/' + authId +'/SettlementConsentOtp'
    return this.http.get(url, this.prepareHeaders());
  }

  maskMobileNumber(){
    var maskedValue:any;
    var orgVal = this.selectedAccountData['MobileNumber'].toString();
    var temp1 = orgVal.slice(0,2)
    var temp2 = orgVal.slice(5,7)
    var temp3 = orgVal.slice(9,10)
    maskedValue = temp1 + "***" + temp2 + "**" + temp3
    return maskedValue
  }



  setTncVal(){
    if(this.responseData && this.responseData.accounts){
      for(let i = 0 ; i < this['responseData']['accounts'].length ; i++){
        if(this['responseData']['accounts'][i]['accType'] == 'loan' ){
          this.displayTnc = true;
          break;
        }
      } 
    }
  }

  setCardTncVal(){
      if(this.responseData && this.responseData.accounts){
        for(var i = 0 ; i < this.responseData['accounts'].length ; i++){
          if(this['responseData'].accounts[i]['accType'] == 'card' ){
            this.displayCardTnc = true;
            break;
          }
        }
      }
  }
  getOfferPlanData(offerData){
    var url = configObj.baseUrl + '/account/paymentplan'
    var requestBody = {
      "customerPlacedOfferAmount":offerData.CustomerPlacedOfferAmount.toString(),
      "numberOfEmi":offerData.NumberOfEmi,
      "paymentStartDate":moment(offerData['paymentStartDate']).format('YYYY-MM-DD')
    }
    return this.http.post(url, requestBody, this.prepareHeaders());
  }

  private calEmiAmount(data){
    if(data && data.length){
      var value = 0;
      for(let i = 0; i < data.length; i++){
        value = value + data[i]['amount']
      }
    }
    return value
  }

  getApprovalStatus(offerData){
    var url =  configObj.baseUrl + "/account/metainfo/" + this.selectedAccountData['accountId'];
    var requestBody = {}
    return this.http.post(url,requestBody,this.prepareHeaders())
  }

}

