import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Global } from '../../global';
import {CommonService} from '../../services/common.service'
@Component({
  selector: 'app-logindobform',
  templateUrl: './logindobform.component.html',
  styleUrls: ['./logindobform.component.scss']
})
export class LogindobformComponent implements OnInit {

  @Input() heading:any;
  @Input() dataList:any;
  @Input() attemptValidationChild:any;
  @Input() userRemaningAttemptChild:any;
  @Input() trimmedChildValue:any;
  @Input() mobileNumberChild:number;
  @Input() notRelevantYou:any = false ;
  @Output() returnSelectedValue = new EventEmitter();
  @Output() returnAnotherLogin = new EventEmitter();
  @Output() retunrPreLogin = new EventEmitter();
  constructor(
    public global:Global,
    public commonServices:CommonService
  ) { }

  public orgData:any = []

  ngOnInit(): void {
    setTimeout(() => {
      if(this.global.showLoginScreen == 11){
        this.orgData = [];
        this.orgData  = this.dataList;
        this.maskMobileNumber(this.orgData)  
      }
    }, 100);
  }

  submitSelectedData(data,ix){
    if(this.global.showLoginScreen == 11){
      this.dataList = []
      this.dataList = this.orgData;
      this.returnSelectedValue.emit(this.dataList[ix])
    }
    else{
      this.returnSelectedValue.emit(data)
    }
  }

  anotherLogin(){
    this.returnAnotherLogin.emit()
  }

  maskMobileNumber(numberData:any,type?:any){
    this.dataList = []
    numberData.forEach(element => {
      var tempElement = element.toString();
      var tempHoldData1 = tempElement.slice(0,2);
      var tempHoldData2 = tempElement.slice(6,10);
      var maskedValue = tempHoldData1+'xxxx'+tempHoldData2 
      this.dataList.push(maskedValue)
    });
  }

  openArdPreLogin(){
    this.retunrPreLogin.emit()
  }


  clickTrack(label,action){
    this.commonServices.trackUser(label,action,null);
  }

}
