import { Injectable } from '@angular/core';
import { configObj } from '../../configuration';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../../services/common.service';
import { Global } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class OrganicuserService {

  constructor(
    private http: HttpClient,
    private commonServices: CommonService,
    private global:Global
  ) { }

  private baseUrl = configObj.baseUrl;

  /**
   * This method is used to submit mobile number and get name list
   * @param mobile User Mobile Number
   */
  public submitMobile(mobile: string, uuid,type?:any,id?:any) {
    let requestBody = {
      // "operationType": "CheckAttempt",
      // "mobileNumber": mobile
    };
    if(type == 'primaryNumber' ){
      requestBody['operationType'] = "ValidatePrimaryData",
      requestBody['primaryContact'] = this.commonServices.generateEncRequest(mobile),
      requestBody['id'] = id
      // requestBody['source'] = "ARD"
    }
    else{
      requestBody['operationType'] = "CheckAttempt",
      requestBody['mobileNumber'] =  mobile
      if(this.global.relevantData){
        requestBody['source'] = 'ARD'
      }
    }
    // if (uuid) {
    //   requestBody['uuid'] = uuid
    // }
    return this.http.post(this.baseUrl + '/user/attempt', requestBody);
  }

  /**
   * This method is used to submit email address and get name list
   * @param email User Email Address
   */
  public submitEmail(email: string, uuid) {
    let requestBody = {
      "operationType": "CheckEmailAttempt",
      "email": email
    };
    if (uuid) {
      requestBody['uuid'] = uuid
    }
    return this.http.post(this.baseUrl + '/user/attempt/email', requestBody);
  }

  /**
   * This method is used to submit name and get address list
   * @param name User Name
   * @param id Response ID
   */
  public submitName(name: string, id: number) {
    let requestBody = {
      "operationType": "ValidateName",
      "name": name,
      "id": id
    };
    return this.http.post(this.baseUrl + '/user/attempt', requestBody);
  }

  /**
   * This method is used to submit address and get dob list
   * @param address User Address
   * @param id Response ID
   */
  public submitAddress(address: string, id: number) {
    let requestBody = {
      "operationType": "ValidateAddress",
      "id": id,
      "address": address
    };
    return this.http.post(this.baseUrl + '/user/attempt', requestBody);
  }

  /**
   * This method is used to submit dob and get OTP list
   * @param dob User DOB
   * @param id Response ID
   * @param dobList Old DOB List
   */
  public submitDOB(dob: string, id: number,dobList:any) {
    let requestBody = {
      "operationType": "ValidateDOB",
      "id": id,
      "dob": dob,
      "dobList":dobList
    };
    return this.http.post(this.baseUrl + '/user/attempt', requestBody);
  }

  /**
   * This method is used to submit OTP and get accounts list
   * @param otp User OTP
   * @param id Response ID
   */
  public submitOTP(otp: string, id: number) {
    //Encrypt OTP
    // let encryptedOtp = btoa(this.commonServices.generateRandomString(6) + '_' + otp + '_' + this.commonServices.generateRandomString(6));

    let requestBody = {
      "registrationId": id,
      "otp": this.commonServices.generateEncRequest(otp),
      // "captcha": captchaResponse
    };
    return this.http.post(this.baseUrl + '/user/authentication', requestBody);
  }

  /**
   * This method is used to resend otp list
   * @param id Response ID
   */
  public resendOtp(id: number) {
    return this.http.get(this.baseUrl + '/user/getOTP/' + id);
  }

  /**
   * This method is used to delete all the session
   * @param id is the user id
   */
  public sessionDelete(id) {
    let requestBody = {
      "operationType": "DeleteSession",
      "id": id
    }
    return this.http.post(this.baseUrl + '/user/attempt', requestBody);
  }

  public uuidData(uuid: string,isARD?:any) {
    var requestBody = {
      "operationType": "CheckUuidAttempt",
      "uuid": uuid
    }
    if(isARD){
      requestBody['source'] = "ARD"
    }
    // Create the URL
    var url = configObj.baseUrl + '/user/attempt';
    // post the data
    return this.http.post(url,requestBody);  
  }

  public submitUserDetails(data,id,value){
    let requestBody = {
      "crnNumber" : data.crnNumber ? data.crnNumber : null ,
      "fullName" : data.userName,
      "registrationId" : id ,
      "comments" : data.comment? data.comment : null,
      "accountOrCardNumber":data.accNum
    }
    var val = value.toString()
    if((val && val.includes('@'))){
      requestBody['email'] =  value
    }
    if( data.emailAdd ){
      requestBody['email'] =  data.emailAdd
    }
    if((val && !val.includes('@'))){
      requestBody['mobileNumber'] = value 
    }
    if(data.mobNum){
      requestBody['mobileNumber'] =  data.mobNum;
    }
    return this.http.post(this.baseUrl + '/user/direct/details', requestBody);
  }




}

