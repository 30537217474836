import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()

export class Global {
	public mobile = false;
	public currentPage;
	public bankName = 'IndusInd Bank'
	public expandCard = false;
	public openMenu = false;
	public userSelectedLang = 'en';
	public content: any;
	public displayLang = true;
	public downloadsOpenRef: any;
	public showScreens = 'null';
	public showDocLink:boolean = false;
	public showLoginScreen = 1;
	public genericUser: boolean = true;
	public displaySplashScreens: any = null;
	private allLanguage: object = {};
	public creditScore: boolean = false;
	public creditScoreClose: boolean = false;
	public relevantData: boolean = false;
	public tempShowLoginScreen:any = 9;
	public directUser:boolean;

	constructor(
		private http: HttpClient
	) { this.returnLangObj(this.userSelectedLang); }

	public returnLangObj(userSelectedLang) {
		let url = "assets/languagesConfig/" + userSelectedLang + ".json";
		if (this.allLanguage[userSelectedLang]) {
			this.content = this.allLanguage[userSelectedLang]
		} else {
			this.http.get(url).subscribe(data => {
				if (!!data) {
					this.allLanguage[userSelectedLang] = data;
					this.content = data;
				}
			},
				error => {
					this.content = this.allLanguage['en']
				});
		}
	}
}