<router-outlet></router-outlet>
<ngx-ui-loader fgsColor="#ffffff" overlayColor="rgb(0 0 0 / 60%)" hasProgressBar="false" pbThickness="0"
    textPosition="center-center" gap="0" logoPosition="center-center" logoSize="80"
    logoUrl="assets/images/icons/loader.gif" [fgsTemplate]="foregroundSpinner">
</ngx-ui-loader>
<ng-template #foregroundSpinner></ng-template>
<ng-template #paymentSuccessful let-modal>
    <div class="modal-body p-0 text-center" *ngIf="global && global.content">
        <div class="mt-4">
            <img src="assets/images/icons/acceptedOffer.png" alt="offerAcceptedIcon" class="resolveApproval">
        </div>
        <div class="paymentSuccessfulHeading ">
            {{global.content.PaymentSuccessfull}}
        </div>
        <div class="paymentSuccessSubHead mb-3"
            *ngIf="commonServices && commonServices.userPaymentData && commonServices.userPaymentData['paymentAmount']">
            ₹ {{commonServices.userPaymentData['paymentAmount']}} {{global.content.paidSuccessfully}}<br>
            {{global.content.trackingId}}: {{commonServices.userPaymentData['bankRefNo']}}
        </div>
        <div class="goToDashBoard mt-0 cursorPointer" (click)="closepaymentModal()"
            *ngIf="global.currentPage != 'dirPayReturn'">
            {{global.content.goToDashboard}}
        </div>
    </div>
</ng-template>

<ng-template #paymentRejectedModal let-modal>
    <div class="modal-body p-0 text-center" *ngIf="global && global.content">
        <div class="mt-4">
            <img src="assets/images/icons/rejectedIcon.png" alt="rejectedIcon" class="resolveApproval">
        </div>
        <div class="paymentSuccessfulHeading ">
            {{global.content.paymentFailed}}
        </div>
        <div class="paymentSuccessSubHead mb-3"
            *ngIf="commonServices && commonServices.userPaymentData && commonServices.userPaymentData['paymentAmount']">
            ₹ {{commonServices.userPaymentData['paymentAmount']}} {{global.content.paymentFailedTryAgain}}<br>
            {{global.content.trackingId}}: {{commonServices.userPaymentData['bankRefNo']}}
        </div>
        <div class="goToDashBoard mt-0 cursorPointer" (click)="closepaymentModal()"
            *ngIf="global.currentPage != 'dirPayReturn'">
            {{global.content.goToDashboard}}
        </div>
    </div>
</ng-template>

<ng-template #paymentPlan let-modal>
    <div class="modal-body p-0 text-center" *ngIf="global && global.content">
        <div class="p-3">
            <div class="paymentSuccessfulCloseIcon cursorPointer text-end p-0" (click)="closeModal()">
                <img src="assets/images/icons/languagecrossicon.svg" alt="crossIcon">
            </div>
            <div class="py-lg-4 py-3 paymentPlanImg">
                <img src="assets/images/icons/paymentPlan.svg" alt="paaymentPlanIcon">
            </div>
            <div class="paymentSuccessfulHeading">
                {{global.content.paymentPlan}}
            </div>
            <div class="paymentSuccessfulContent">
                {{global.content.verifyPaymentDetails}}
            </div>
        </div>
        <div>
            <table class="table text-start paymentSuccessfulContent">
                <tr>
                    <th>
                        {{global.content.paymentDueDate}}
                    </th>
                    <th>
                        {{global.content.amount}}
                    </th>
                </tr>
                <tr *ngFor="let data of commonServices['paymentplanData']['paymentPlan']"
                    class=paymentSuccessfulContent>
                    <td>
                        {{data.paymentDate | date:'dd MMM yyyy'}}
                    </td>
                    <td>
                        ₹ {{data.amount | convertToINR}}
                    </td>
                </tr>

            </table>
        </div>
        <div class="px-lg-4 px-3 pb-3 pb-lg-0">
            <div>
                <button class="paymentSuccessfulGoToDash"
                    (click)="submitPaymentplan(commonServices['paymentplanData']['paymentPlan'][0],'payInTotal')">
                    {{global.content.submit}}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #paymentPlanSettelment let-modal>
    <div class="modal-body p-0 text-center" *ngIf="global && global.content">
        <div class="p-3">
            <div class="paymentSuccessfulCloseIcon cursorPointer text-end p-0" (click)="closeModal()">
                <img src="assets/images/icons/languagecrossicon.svg" alt="crossIcon">
            </div>
            <div class="py-lg-4 py-3 paymentPlanImg">
                <img src="assets/images/icons/paymentPlan.svg" alt="paymentPlanIcon">
            </div>
            <div class="paymentSuccessfulHeading ">
                {{global.content.paymentPlan}}
            </div>
            <div class="paymentSuccessfulContent">
                {{global.content.verifyPaymentDetails}}
            </div>
        </div>
        <div>
            <table class="table text-start paymentSuccessfulContent">
                <tr>
                    <th>
                        {{global.content.paymentDueDate}}
                    </th>
                    <th>
                        {{global.content.amount}}
                    </th>
                </tr>
                <tr *ngFor="let data of commonServices['paymentplanData']['paymentPlan']"
                    class=paymentSuccessfulContent>
                    <td>
                        {{data.paymentDate | date:'dd MMM yyyy'}}
                    </td>
                    <td>
                        ₹ {{data.amount | convertToINR}}
                    </td>
                </tr>

            </table>
        </div>
        <div class="px-lg-4 px-3 pb-3 pb-lg-0">
            <div>
                <button class="paymentSuccessfulGoToDash"
                    (click)="!commonServices.selectedAccountData['waiverAmount'] && getSettlementApprovalStatus(commonServices['paymentplanData']['paymentPlan']) ; commonServices.selectedAccountData['waiverAmount'] && submitWaiverPaymentplan(commonServices['paymentplanData']['paymentPlan']) ; clickTrack('proceedToPayment','click')">
                    {{global.content.proceedToPayment}}
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #offerRejected let-modal>
    <div class="modal-body text-center" *ngIf="global && global.content">
        <div class=" paymentSuccessfulCloseIcon cursorPointer text-end" (click)="closeModal()">
            <img src="assets/images/icons/languagecrossicon.svg" alt="crossIcon">
        </div>
        <div class="pb-4 rejectedIcon">
            <img src="assets/images/icons/rejectedIcon.png" alt="rejectedIcon">
        </div>
        <div class="paymentRejectHeading">
            <span *ngIf="commonServices['offerRequestResponse']">
                {{global.content.offerRejectedFirstHalf}}
                {{commonServices['offerRequestResponse'].remainigPlacementChances}}
                {{global.content.offerRejectedSecondHalf}}
            </span>
            <span *ngIf="!commonServices['offerRequestResponse']">
                {{global.content.exhaustedAllSettlementAttempts}}
            </span>
        </div>
        <div class="offerRejectedpopuptext" *ngIf="commonServices['offerRequestResponse']">
            {{global.content.offerRejectedAmmountCloserTo}}
            <span *ngIf="commonServices.selectedAccountData.baseSettlementAmount">
                {{commonServices.selectedAccountData.baseSettlementAmount | convertToINR }} </span>
            <span
                *ngIf="!commonServices.selectedAccountData.baseSettlementAmount && commonServices.selectedAccountData.Balance">
                {{commonServices.selectedAccountData.Balance | convertToINR }} </span>
            <span
                *ngIf="!commonServices.selectedAccountData.baseSettlementAmount && !commonServices.selectedAccountData.Balance && commonServices.selectedAccountData.Penalty">
                {{commonServices.selectedAccountData.Penalty | convertToINR }} </span>
            {{global.content.offerRejectedContent}}
        </div>
        <div class="offerRejectedpopuptext" *ngIf="!commonServices['offerRequestResponse']">
            {{global.content.recordedLastsettlement}}
        </div>
        <div class="px-4 pb-3 pb-lg-0">
            <div class="paymentSuccessfulGoToDash " (click)="closeModal()">
                {{commonServices['offerRequestResponse']?global.content.tryAgain:global.content.CloseBtn}}
            </div>
        </div>
    </div>
</ng-template>
<ng-template #timeOutModal let-modal>
    <div class="modal-body" *ngIf="global && global.content">
        <div class="col-12 px-0 d-flex flex-wrap py-3">
            <div class="col-12 text-center mb-2"><img src="assets/images/icons/timeout.png" alt="timeouIcon"
                    width="70px"></div>
            <div class="col-12 my-3 f-16 px-0 text-center">
                {{global.content.sessionTerminate}} <b> {{convertHoursForTable(countdownTimeSecond)}}</b>
                {{global.content.dueToInactivity}}
            </div>
            <div class="col-12 d-flex justify-content-around p-0 pb-3">
                <button class="btn timeoutPopUpClose"
                    (click)="modal.close();clickTrack('TimeOutOk','click')">{{global.content.okay}}</button>
                <button class="btn timeoutPopUpWait"
                    (click)="restartTimeOut();clickTrack('TimeOutWait','click')">{{global.content.wait}}</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #paymentPageRedirectionModal let-modal>
    <div class="modal-body" [ngStyle]="{'background': '#f3f6f9'}" *ngIf="global && global.content">
        <ngx-ui-loader fgsColor="#ffffff" overlayColor="rgba(59,83,111,.9)" hasProgressBar="false" pbThickness="0"
            textPosition="center-center" gap="0" logoPosition="center-center" logoSize="80"
            logoUrl="assets/images/icons/loader.gif" [fgsTemplate]="foregroundSpinner" [loaderId]="'redirectLoader'">
        </ngx-ui-loader>
        <div class="col-12 p-0 d-flex flex-wrap flex-column">
            <div class="loaderPayment text-center" title="0">
                <img id="loader-1" src="assets/images/icons/loader.gif" alt="loaderGif" class="loaderGif">
            </div>
            <p class="text-4a f-14 text-center p-3">
                <strong>{{global.content.redirectingForPayment}}</strong>
            </p>
        </div>
    </div>
</ng-template>

<ng-template #paymentplanApproved let-modal>
    <div class="modal-body p-0 text-center" *ngIf="global && global.content">
        <div class="p-3">
            <div class="paymentSuccessfulCloseIcon cursorPointer text-end p-0" (click)="closeModal()">
                <img src="assets/images/icons/languagecrossicon.svg" alt="crossIcon">
            </div>
            <div class=" py-3 paymentPlanImg">
                <img src="assets/images/icons/acceptedOffer.png" alt="acceptedOfferIcon">
            </div>
            <div class="paymentSuccessfulHeading">
                {{global.content.offerAccepted}}
            </div>


            <div class="paymentSuccessfulContent"
                *ngIf="commonServices['selectedAccountData']['latestOffer'] && commonServices['selectedAccountData']['latestOffer']['Resolution'] && commonServices['selectedAccountData']['latestOffer']['shortResolution']">
                <span
                    *ngIf="commonServices['selectedAccountData']['latestOffer']['shortResolution'] == 'ToSettled'">{{global.content.settlemetOfferApproved}}</span>
                <span
                    *ngIf="commonServices['selectedAccountData']['latestOffer']['shortResolution'] == 'ToClosed'">{{global.content.emiOfferApproved}}</span>
                <span
                    *ngIf="commonServices['selectedAccountData']['latestOffer']['shortResolution'] == 'ToWaiver'">{{global.content.waiverOfferApproved}}</span><br>
                <span
                    *ngIf="commonServices['selectedAccountData']['latestOffer']['shortResolution'] == 'ToWaiver' && waiverRes['accountTos'] && waiverRes['customerPlacedOfferAmount'] && (waiverRes['accountTos'] - waiverRes['customerPlacedOfferAmount'] ) > 0">
                    <span>
                        ₹ {{global.content.waivedOffAmmount2}} {{ (waiverRes['accountTos'] -
                        waiverRes['customerPlacedOfferAmount'] ) | convertToINR }} {{global.content.waivedOffAmmount}}
                    </span>
                </span>
            </div>
        </div>
        <div class="viewOfferLetter" (click)="opennocOfferLetterPopup()">
            {{global.content.viewOfferLetter}}<br>
        </div>
        <div class="px-3 pb-1">
            <div>
                <button class="paymentSuccessfulGoToDash"
                    (click)="paymentSumary('payFirstEmi', selectedPaymentplanAmount['amount'] ? selectedPaymentplanAmount['amount'] : selectedPaymentplanAmount[0]['amount'] );clickTrack('offerEmiAmount', 'click');">
                    {{global.content.proceedToPay}}
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #OfferLetterModal let-modal>
    <div class="modal-body p-0 text-center" *ngIf="global && global.content">
        <div class="p-3 pb-0" >
            <div class="waterMark" *ngIf="config && config.bankId && config.bankId == 13 "><div>Draft</div></div>
            <div class="paymentSuccessfulCloseIcon cursorPointer text-end p-0" (click)="closeModal()">
                <img src="assets/images/icons/languagecrossicon.svg" alt="crossIcon">
            </div>
            <div class="paymentSuccessfulHeading py-0 ">
                <span
                    *ngIf="commonServices['selectedAccountData']['latestOffer']['shortResolution'] == 'ToSettled'">{{global.content.settlementLetter}}</span>
                <span
                    *ngIf="commonServices['selectedAccountData']['latestOffer']['shortResolution'] == 'ToClosed'">{{global.content.emiLetter}}</span>
                <span
                    *ngIf="commonServices['selectedAccountData']['latestOffer']['shortResolution'] == 'ToWaiver' ">{{global.content.waiverLetter}}</span>
            </div>
            <div class="letterFramePopup">
                <iframe width="100%" height="100%" [src]="nochtml"></iframe>
            </div>

        </div>
        <div class="px-3 pb-1">
            <div>
                <button class="offerLetteBtn"
                    (click)="paymentSumary('payFirstEmi',selectedPaymentplanAmount['amount'] ? selectedPaymentplanAmount['amount'] : selectedPaymentplanAmount[0]['amount']);clickTrack('offerEmiAmount', 'click')">
                    {{global.content.proceedToPay}}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #paymentAlerts let-modal>
    <div class="modal-body p-0 text-center" *ngIf="global && global.content">
        <div class="paymentSuccessfulCloseIcon cursorPointer text-end " (click)="closeModal()">
            <img src="assets/images/icons/languagecrossicon.svg" alt="crossicon">
        </div>
        <div class=" py-3 payAlertMessage">
            {{offerErrorText}}
        </div>
        <div class="goToDashBoard cursorPointer" (click)="closeModal()">
            {{global.content.tryAgain}}
        </div>
    </div>
</ng-template>
<ng-template #languagepopup let-modal>
    <div class="modal-body" *ngIf="global && global.content">
        <div class="col-12 pt-4 pb-4 languageinnerpopup">
            <div class="col-12 px-3 px-lg-4 d-flex align-items-center">
                <div class="col d-flex">
                    <div><img class="languagepopupupperimg" src="assets/images/icons/languagepopuplogo.png"
                            alt="languageIcon"></div>
                    <div class="laguagepopupuppertext1">
                        <div class="laguagepopuptext">{{global.content.Language}}</div>
                        <div class="mt-2 laguagepopuptext2" [innerHtml]="global.content.ChooseyourPreferredLanguage">
                        </div>
                    </div>
                </div>
                <div class="align-self-start">
                    <img src="assets/images/icons/languagecrossicon.svg" alt="crossIcon" (click)="bottomSheet.dismiss()"
                        class="languagecrossimage cursorPointer">
                </div>
            </div>
            <div class="col-12  px-lg-4 px-3 mt-4">
                <!---------commonServices.chnglang(data.short)----------------->
                <div (click)="clickTrack('selectLang_' + data.short,'click');commonServices.chnglang(data.short);commonServices.setDataAfterLanguage(data.short);bottomSheet.dismiss()"
                    *ngFor="let data of languageoption;index as i"
                    class="laguagepopupbox d-flex justify-content-between align-items-stretch">
                    <div>{{data.full}}</div>
                    <div class="languagestatustick d-flex"
                        *ngIf="commonServices.detailsForLang['language'] == data.short"><img width="20px"
                            alt="selectTickIcon" src="assets/images/icons/statusTick.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #callingPopup let-modal>
    <div class="modal-body" *ngIf="global && global.content">
        <div class="col-12 px-0">
            <div class="callingpopupcloseouter cursorPointer text-end" (click)="closeModal();selectedTime = null">
                <img class="callingcloseicon" src="assets/images/icons/languagecrossicon.svg" alt="crossicon">
            </div>
            <!-- <div class="d-flex justify-content-center">
                <img class="callIcon" src="assets/images/icons/callingRedIcon.png" alt="callIcon">
            </div>
            <div class="text-center callingPopupText mt-4">
                <span *ngIf="!setupACall">
                    {{global.content.callingPopupContent1}}
                </span>
                <span *ngIf="setupACall">
                    {{global.content.callingPopupContent2}}
                </span>
            </div>
            <div class="col-12 px-0 d-flex justify-content-center mt-4 mb-3">
                <div *ngIf="!setupACall" (click)="usercallingService();clickTrack('call_popup_placeACall','click')"
                    class="callingbtn d-flex justify-content-center align-items-center">
                    {{global.content.callingPopupButtonContent1}}
                </div>
                <div *ngIf="setupACall" (click)="closeModal();clickTrack('call_popup_Okay','click')"
                    class="callingbtn d-flex justify-content-center align-items-center">
                    {{global.content.callingPopupButtonContent2}}
                </div>
            </div> -->
            <div class="placeCallFormBlock" *ngIf="callPopupContent && callPopupContent == 'operational' ">
                <form #callPlaceForm="ngForm" (ngSubmit)="callPlaceForm.valid && callPlaceDetails(callPlaceForm.value)">
                    <div class="callPlaceFormLabel">{{global.content.selectAccount}}<span class="text-danger">*</span></div>
                    <div class="categorySelectBox">
                        <mat-form-field>
                            <mat-select name="account" #account="ngModel" ngModel required
                                [ngClass]="{ 'is-invalid': callPlaceForm.submitted && account.invalid }">
                                <mat-option value="" selected disabled>{{global.content.chooseAcc}}</mat-option>
                                <mat-option *ngFor="let data of commonServices.responseData['accounts']"
                                    [value]="data.AccountNumber">
                                    <div>
                                        {{data['accType'] == 'card' ?'xxxx xxxx xxxx ' +
                                        data.CardNumber:data.AccountNumber}}
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="queryValidationBox text-danger">
                        <div
                            *ngIf="callPlaceForm.submitted && account.invalid && account.errors && account.errors.required">
                            {{global.content.accReq}}
                        </div>
                    </div>
                    <div class="callPlaceFormLabel">{{global.content.queryType}}<span class="text-danger">*</span></div>
                    <!-- <div>
                        <input type="text" name="queryType" #queryType="ngModel"  ngModel  
                        [ngClass]="{ 'is-invalid': callPlaceForm.submitted && queryType.invalid }" required/>
                    </div> -->
                    <div class="categorySelectBox">
                        <mat-form-field>
                            <mat-select name="queryType" #queryType="ngModel" ngModel required
                                [ngClass]="{ 'is-invalid': callPlaceForm.submitted && queryType.invalid }">
                                <mat-option value="" selected disabled>{{global.content.chooseQueryType}}</mat-option>
                                <mat-option *ngFor="let query of queryTypeData"
                                    [value]="query.value">
                                    <div>
                                        {{query.showValue}}
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="queryValidationBox text-danger">
                        <div
                            *ngIf="callPlaceForm.submitted && queryType.invalid && queryType.errors && queryType.errors.required">
                                {{global.content.queryTypeReq}}
                        </div>
                    </div>
                    <div class="callPlaceFormLabel">{{global.content.queryDesc}}<span class="text-danger">*</span></div>
                    <textarea  name="description" ngModel
                        #description="ngModel" minlength="20" required pattern="^[a-zA-Z\d-_., ]+$" ></textarea>
                    <div class="queryValidationBox text-danger">
                        <div
                            *ngIf="callPlaceForm.submitted && description.invalid && description.errors && description.errors.required">
                            {{global.content.messageReq}}
                        </div>
                        <div *ngIf="description.invalid && description.errors && description.errors.pattern">
                            {{global.content.queryMsgError}}.
                        </div>
                        <div *ngIf="description.invalid && description.errors && description.errors.minlength">
                            {{global.content.minlengthError}}
                        </div>
                    </div>
                    <!-- <div class="callPlaceFormLabel">{{global.content.prefferedTime}}</div>
                    <div>
                        <mat-form-field class="w-100">
                            <div class="d-flex timePickInput align-items-center cursorPointer">
                                <input matInput
                                   name="selectedTime"
                                   format="12"
                                   [(ngModel)]="selectedTime"
                                   [ngxMatTimepicker]="pickerB"
                                   readonly 
                                   min="09:30"
                                    max="18:30"
                                    class="p-0"
                                   />
                                   <div><img src="assets/images/icons/clock.png" alt="clockIcon" width="20" height="auto" (click)="pickerB.open();getDate()"></div>
                            </div>
                        </mat-form-field>
                        <ngx-mat-timepicker id="picker" #pickerB></ngx-mat-timepicker>  
                    </div> -->
                    <div class="callPlaceFormLabel">{{global.content.prefferedNumber}}</div>
                    <div>
                        <input type="number" name="contact" #contact="ngModel" 
                        [(ngModel)]="mobileNumber" ngModel pattern="^([6-9]){1}([0-9]){9}?$" 
                        [ngClass]="{ 'is-invalid': callPlaceForm.submitted && contact.invalid }"
                         />
                    </div>
                    <div class="queryValidationBox text-danger">
                        <div
                            *ngIf="callPlaceForm.submitted && contact.invalid && contact.errors && contact.errors.required">
                            {{global.content.MobileNoisRequired}}
                        </div>
                        <div *ngIf="contact.invalid && contact.errors && contact.errors.pattern">
                            {{global.content.EnterValidMobileNumber}}
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class=" submitCallDeatilBtn  my-2 w-100">{{global.content.submit}}</button>
                    </div>
                </form>
            </div>

            <div class="text-center my-4" *ngIf=" callPopupContent && callPopupContent == 'non-operational' ">
                <div>
                    <img class="callIcon" src="assets/images/icons/callingRedIcon.png" alt="callIcon">
                </div>
                <div class="callingPopupText my-4">{{global.content.nonOpCall}} {{global.content.bankFullName}} {{global.content.nonOpCall1}}</div>
            </div>
            <div class="text-center my-4" *ngIf=" callPopupContent && callPopupContent == 'holiday' ">
                <div>
                    <img class="callIcon" src="assets/images/icons/callingRedIcon.png" alt="callIcon">
                </div>
                <div class="callingPopupText my-4">{{global.content.OpCall}} {{global.content.bankFullName}} {{global.content.OpCall1}}</div>
            </div>
        </div>

    </div>
</ng-template>
<ng-template #thankuCallingPopup let-modal>
    <div class="modal-body  text-center" *ngIf="global && global.content">
        <div class="mt-4">
            <img src="assets/images/icons/queryThanku.svg" alt="callingThankuIcon" class="callingThankuIcon">
        </div>
        <div class="paymentSuccessfulHeading py-3">
            {{global.content.thankYou}}
        </div>
        <div class="paymentSuccessfulContent">
            {{global.content.queryContent}}
        </div>
        <div class="goToDashBoard cursorPointer" (click)="closeModal()">
            {{global.content.CloseBtn}}
        </div>
    </div>
</ng-template>

<form #paymentForm name="paymentForm" [action]="config['paymentUrl']" method="post">
    <input type="hidden" name="key" [value]="paymentResponse['encKey']" />
    <input type="hidden" name="txnid" [value]="paymentResponse['transactionId']" />
    <input type="hidden" name="productinfo" [value]="paymentResponse['productInfo']" />
    <input type="hidden" name="amount" [value]="paymentResponse['amountReturn']" />
    <input type="hidden" name="email" [value]="paymentResponse['email']?paymentResponse['email']:'-'" />
    <input type="hidden" name="firstname" [value]="paymentResponse['fullName']" />
    <input type="hidden" name="phone" [value]="paymentResponse['phoneNumber']?paymentResponse['phoneNumber']:'-'" />

    <input type="hidden" name="surl" [value]="config['paymentSurl']" *ngIf="global.currentPage != 'dirPayment'" />
    <input type="hidden" name="furl" [value]="config['paymentFurl']" *ngIf="global.currentPage != 'dirPayment'" />

    <input type="hidden" name="surl" [value]="config['dirPaymentSurl']" *ngIf="global.currentPage == 'dirPayment'" />
    <input type="hidden" name="furl" [value]="config['dirPaymentFurl']" *ngIf="global.currentPage == 'dirPayment'" />

    <input type="hidden" name="hash" [value]="paymentResponse['encryptedRequest']" />
    <input type="hidden" name="udf1" [value]="paymentResponse['pgIdentifier']" />
    <input type="hidden" name="udf2" [value]="paymentResponse['udf2']" />
    <input type="hidden" name="udf3" [value]="paymentResponse['udf3']"
        *ngIf="commonServices.selectedAccountData && commonServices.selectedAccountData['BusinessProduct'] && commonServices.selectedAccountData['accType'] == 'loan'" />
    <input type="hidden" name="udf4" [value]="paymentResponse['udf4']"
        *ngIf="commonServices.selectedAccountData && commonServices.selectedAccountData['BusinessProduct'] && commonServices.selectedAccountData['accType'] == 'loan'" />
    <input type="hidden" name="udf5" [value]="paymentResponse['udf5']"
        *ngIf="commonServices.selectedAccountData && commonServices.selectedAccountData['BusinessProduct'] && commonServices.selectedAccountData['accType'] == 'loan'" />
    <input type="submit" style="display: none;" value="submit">
</form>