import { Injectable } from '@angular/core';
import { configObj } from '../configuration';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class NewloginService {

  constructor(
    private http: HttpClient,
    private commonServices: CommonService
  ) { }

  private baseUrl = configObj.baseUrl;

  /**
   * This method is used to submit mobile number and get name list
   * @param mobile User Mobile Number
   */
  public submitMobile(mobile: string, uuid) {
    let requestBody = {
      "operationType": "CheckAttempt",
      "mobileNumber": mobile
    };
    if (uuid) {
      requestBody['uuid'] = uuid
    }
    return this.http.post(this.baseUrl + '/user/attempt', requestBody);
  }

  /**
   * This method is used to submit email address and get name list
   * @param email User Email Address
   */
  public submitEmail(email: string, uuid) {
    let requestBody = {
      "operationType": "CheckEmailAttempt",
      "email": email
    };
    if (uuid) {
      requestBody['uuid'] = uuid
    }
    return this.http.post(this.baseUrl + '/user/attempt/email', requestBody);
  }

  /**
   * This method is used to submit name and get address list
   * @param name User Name
   * @param id Response ID
   */
  public submitName(name: string, id: number) {
    let requestBody = {
      "operationType": "ValidateName",
      "id": id,
      "name": name
    };
    return this.http.post(this.baseUrl + '/user/attempt', requestBody);
  }

  /**
   * This method is used to submit address and get dob list
   * @param address User Address
   * @param id Response ID
   */
  public submitAddress(address: string, id: number) {
    let requestBody = {
      "operationType": "ValidateAddress",
      "id": id,
      "address": address
    };
    return this.http.post(this.baseUrl + '/user/attempt', requestBody);
  }

  /**
   * This method is used to submit dob and get OTP list
   * @param dob User DOB
   * @param id Response ID
   */
  public submitDOB(dob: string, id: number) {
    let requestBody = {
      "operationType": "ValidateDOB",
      "id": id,
      "dob": dob
    };
    return this.http.post(this.baseUrl + '/user/attempt', requestBody);
  }

  /**
   * This method is used to submit OTP and get accounts list
   * @param otp User OTP
   * @param id Response ID
   */
  public submitOTP(otp: string, id: number) {
    //Encrypt OTP
    // let encryptedOtp = btoa(this.commonServices.generateRandomString(6) + '_' + otp + '_' + this.commonServices.generateRandomString(6));

    let requestBody = {
      "registrationId": id,
      "otp": this.commonServices.generateEncRequest(otp),
      // "captcha": captchaResponse
    };
    return this.http.post(this.baseUrl + '/user/authentication', requestBody);
  }

  /**
   * This method is used to resend otp list
   * @param id Response ID
   */
  public resendOtp(id: number) {
    return this.http.get(this.baseUrl + '/user/getOTP/' + id);
  }

  /**
   * This method is used to delete all the session
   * @param id is the user id
   */
  public sessionDelete(id) {
    let requestBody = {
      "operationType": "DeleteSession",
      "id": id
    }
    return this.http.post(this.baseUrl + '/user/attempt', requestBody);
  }

  public uuidData(uuid: string) {
    // Create the URL
    var url = configObj.baseUrlUuid + uuid;
    // post the data
    return this.http.get(url);
  }



}
