import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { Global } from "./global";
import { ToastrModule } from "ngx-toastr";
import { RecaptchaModule } from "ng-recaptcha";
import { SharedModule } from "./shared/shared.module";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { AppGlobalErrorhandler } from "src/app/error-handler.service";
import { ErrorHandler } from "@angular/core";
import { ConvertToINRPipe } from "./shared/custom.pipe";
import { CommonModule } from "@angular/common";
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatBottomSheetModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    HttpClientModule,
    RecaptchaModule,
    ToastrModule.forRoot({ positionClass: "toast-center-center" }),
    SharedModule,
    NgxUiLoaderModule,
    CommonModule,
    MatSelectModule
  ],
  providers: [
    Global,
    AppComponent,
    ConvertToINRPipe,
    { provide: ErrorHandler, useClass: AppGlobalErrorhandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
