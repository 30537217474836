import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../global';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from '../../app.component';
import { OrganicuserService } from '../organicuser/organicuser.service';
import { configObj } from 'src/app/configuration';
@Component({
  selector: 'app-organicuser',
  templateUrl: './organicuser.component.html',
  styleUrls: ['./organicuser.component.scss']
})
export class OrganicuserComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
    public global: Global,
    private loginService: OrganicuserService,
    private activatedRoute: ActivatedRoute,
    public commonServices: CommonService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private modalService: NgbModal,
    public app: AppComponent
  ) {
   }

   config = {
     allowNumbersOnly: true,
     length: 6,
     isPasswordInput: false,
     disableAutoFocus: false,
     placeholder: '',
     inputStyles: {
       'width': '50px',
       'height': '50px'
     }
   };
   public configObj = configObj
   public registerScreen:any = 0;
   public dobList: any ;
   public nameList:any = [];
   public mobileNumberList:any =  [];
   private uuid: any;
   public mobileNumber: any;
   public emailAddress: any;
   private responseId: any;
   public otp: string;
   public modalReference: any;
   public trimmedValue:any
   public attemptValidation:boolean = false;
   public noAccountFound:boolean;
   public userRemaningAttempt:any = 3;
   public showOptOthers:boolean;
   public showRelevant:any = false;

   @ViewChild('logoutSessoin') logoutSessoin
   @ViewChild('logoutConfirmation') logoutConfirmation: any;
   @ViewChild('ngOtpInput') ngOtpInputRef: any;
   @Input() relevantKey:any;
  //  @Output() relevantContent = new EventEmitter();
  ngOnInit(): void {

    this.otp = '';
    this.global.showLoginScreen = 1;
    this.commonServices.checkSession().then(
      data => {
        this.router.navigate(['dashboard'])
      }
    ).catch(
      error => {
        this.checkForCreditScore();
        this.checkUUID();
      }
    );
  }

  ngAfterViewInit() {
    var x = window.matchMedia("(max-width: 992px)")
    if (('OTPCredential' in window) && x.matches) {
      const ac = new AbortController();
      var reqObj = {
        otp: { transport: ['sms'] },
        signal: ac.signal
      };
      navigator.credentials.get(
        reqObj
      ).then((otp) => {
        if (otp) {
          setTimeout(() => {
            this.ngOtpInputRef.setValue(otp['code']);
            this.otp = otp['code'];
            this.submitOTP();
          }, 1000)
        }
      }).catch(err => { });
    }
  }



  private checkUUID() {
    // this.checkUrlForOnboardScreen();
    this.checkSessionLanguage();
    this.activatedRoute.params.subscribe(routeParams => {
      let uuid = routeParams.uuid;
      if (uuid) {
        this.getDataFromUUID(uuid);
        this.checkUrlLang();
        this.commonServices.detailsForLang.uuid = uuid;
      }
      else {
        this.checkUrlForOnboardScreen();
        this.global.showLoginScreen = 1;
      }
    });
  }

  public checkUrlForOnboardScreen(){									
      if(this.router.url.includes('onboarding01') && window.sessionStorage && sessionStorage && !sessionStorage.getItem('FSOU1') ){									
        this.commonServices.trackUser('onboarding01', 'load', null)									
        sessionStorage.setItem('FSOU1','01')									
        this.global.displaySplashScreens = 1;									
      }									
      else if(this.router.url.includes('onboarding02') &&  window.sessionStorage && sessionStorage && !sessionStorage.getItem('FSOU2')){									
        this.commonServices.trackUser('onboarding02', 'load', null)									
        sessionStorage.setItem('FSOU2','02')									
        this.global.displaySplashScreens = 2;									
      }																	
      else{									
        this.global.displaySplashScreens = null;									
      }									
  }		


  private checkForCreditScore(){
    if(this.router.url.includes('creditScore')){
      if(window.sessionStorage && sessionStorage){
        sessionStorage.setItem('CS','credit score')
      }
      this.global.creditScore = true;
    }
    else if(this.router.url.includes('ardLogin')){
      this.global.relevantData = true;
    }
    else{
      this.global.creditScore = false;
      this.global.relevantData = false;
    }
  }
  
  private checkUrlLang() {
    // this.activatedRoute.params.subscribe(routeParams => {
    //   let urlLang = routeParams.lang;
    //   if (urlLang) {
    //     this.global.userSelectedLang = urlLang;
    //     this.global.returnLangObj(urlLang);
    //     this.commonServices.detailsForLang['language'] = urlLang;
    //   }
    //   else {       
    //   }

    // });
  }


  private getDataFromUUID(userUuid:string){
      this.showRelevant = false;
      this.ngxService.start();
        this.commonServices.trackUser('fetchingData_FromUUID','load',null)    
        this.loginService.uuidData(userUuid,this.global.relevantData).subscribe(
        data => {
          if(data){
              this.commonServices.trackUser('dataFetchedSuccessfully_FromUUID','load',null)    
              this.uuid = userUuid;
              if(window.sessionStorage && sessionStorage){
                sessionStorage.setItem('ucode',data['uuid']);
              }
              this.global.genericUser = false;
              this.commonServices.getLangFromSessionStorage();
              this.checkUrlForOnboardScreen()
              // this.checkQueryParam()
              this.userRemaningAttempt = data['remainingWrongAttemptCount'];
              if(this.relevantKey){
                this.global.showLoginScreen = 7;
                this.relevantKey = null
              }
              else if(data['nameList']){
                  this.nameList  = []
                  this.mobileNumber = data['mobileNumber'] ;
                  this.commonServices.trackUser('loadNameList', 'load', null)
                  this.nameList =  data['nameList'];
                  this.responseId = data['id'];
                  this.global.showLoginScreen = 9;
                  if(data['alternateLanding']){
                    this.showRelevant = true
                  }
                  // this.relevantContent.emit(true)
                // sessionStorage.setItem("ARD",'ARD');
              }
              else if(data['mobileNumber']){
                this.mobileNumber = data['mobileNumber'];
                this.trimRegisterUserData(data['mobileNumber'],'mobile');
                this.commonServices.detailsForLang['mobileNumber'] = data['mobileNumber'];
                this.getUserLanguage(data['mobileNumber'],'mobileNumber');
              }
              else{
                this.emailAddress = data['email'];
                this.trimRegisterUserData(data['email'],'email');
                this.commonServices.detailsForLang['email'] = data['email'];
                this.getUserLanguage(data['email'],'email');
              }
              if(data['dobList']){
                this.commonServices.trackUser('loadDOB', 'load', null)
                this.dobList = []
                this.dobList = data['dobList'];
                this.responseId = data['id'];
                this.global.showLoginScreen = 2;
              }
              if(data['remainingWrongAttemptCount'] == 0 ){
                this.global.showLoginScreen = 6;
              }

          }else{
            this.commonServices.trackUser('dataFetchedUnSuccessfully_FromUUID','load',null)    
            this.global.showLoginScreen = 1;
          }
          this.ngxService.stop();
        },
        error => {
          this.ngxService.stop();
          this.commonServices.trackUser('getError_FromUUIDAPI','load',null)    
          if (error.status == 404) {
            this.global.showLoginScreen = 1;
            this.attemptValidationMsg()
            // this.toastr.warning(this.global.content.noAccountFound);
          } else if (
            error.error && error.error.cause && 
            ( error.error.cause.toLowerCase() == 'you have reached max allowed initial authentication attempt for today' || 
              error.error.cause.toLowerCase().includes('otp send daily limit exhausted') ) ) {
                this.global.showLoginScreen = 6;
          } else {
            this.toastr.error(this.global.content.somethingWentWrong);
          }
        }
      );
  }

  resendOtp() {
    if (this.responseId) {
      this.commonServices.trackUser('resendOtp', 'click', null)
      this.ngxService.start();
      this.loginService.resendOtp(this.responseId).subscribe(
        data => {
          this.toastr.success(this.global.content.OTPSentAgain);
          //Hide loader
          this.ngxService.stop();
        },
        error => {
          //Hide loader
          this.ngxService.stop();
          if (error.error && error.error.cause && error.error.cause.toLowerCase() == 'you have crossed limit for resend otp') {
            // this.toastr.error(this.global.content.otpGeneratedManyTimes);
            this.global.showLoginScreen = 6;
          } else {
            this.toastr.error(this.global.content.somethingWentWrong);
          }
        }
      );
    }
  }

  /**
  * This method submits mobile number
  * @param form Form Object
  */
  public submitMobile(number: any,type?:any): void {
    this.global.directUser = false;
    this.showRelevant = false;
    this.global.genericUser = true;
    this.commonServices.detailsForLang['mobileNumber'] = number;
    this.commonServices.getLangFromSessionStorage();
    this.otp = ''
    var body = {
      MobileNumber: number
    }
    this.mobileNumber = number;
    this.trimRegisterUserData(number, 'mobile')
    this.commonServices.trackUser('mobileSubmit', 'submit', body)
    this.dobList = [];
    //Show Loader
    this.ngxService.start();
    this.loginService.submitMobile(number, null,type,this.responseId).subscribe(
      data => {
        this.getUserLanguage(number, 'mobileNumber');
        this.commonServices.detailsForLang['mobileNumber'] = number;
        this.userRemaningAttempt = data['remainingWrongAttemptCount']
        //Hide loader
        this.ngxService.stop();
        this.responseId = data['id'];
        if(data['directOtpUser']){
          this.global.directUser = true;
          this.global.showLoginScreen = 3 ;
        }
        else if(data['nameList']){
          this.nameList = []
          this.mobileNumber = data['mobileNumber'] ;
          this.commonServices.trackUser('loadNameList', 'load', null)
          this.nameList =  data['nameList'];
          this.responseId = data['id'];
          this.global.showLoginScreen = 9;
          if( data['alternateLanding']){
            this.showRelevant = true
          }
        }
        else if(data['alternateLanding']){
          this.showRelevant = true
          this.global.showLoginScreen = 3 ;
          sessionStorage.setItem("ARDFlow",'ARD')
        }
        else{
          this.global.directUser = false;
          if (data['dobList']) {
            this.dobList = []
            this.global.showLoginScreen = 2;
            this.dobList = data['dobList'];
          }
        }
      },
      error => {
        this.mobileNumberList = []
        // this.mobileNumber = null
        //Hide loader
        this.ngxService.stop();
       
        if (error.status == 404) {
          this.attemptValidationMsg();
        } 
        else if((error.error && error.error.cause && ( error.error.cause.toLowerCase() == 'wrong primary contact entered.')) ){
          this.toastr.error(this.global.content.selectedWrongNumber);
          this.checkUUID()
        }
        else if (
          error.error && error.error.cause && 
          ( error.error.cause.toLowerCase() == 'you have reached max allowed initial authentication attempt for today' || 
            error.error.cause.toLowerCase().includes('otp send daily limit exhausted') ) ) {
              this.global.showLoginScreen = 6;
        } 
        else if(this.global.relevantData){
          this.toastr.error(this.global.content.somethingWentWrong);
          this.checkUUID()
        }
        else {
          this.toastr.error(this.global.content.somethingWentWrong);
        }
   
      }
 
    );
  }

  public submitEmail(value: any) {
    this.global.genericUser = true;
    this.commonServices.detailsForLang['email'] = value.email;
    this.commonServices.getLangFromSessionStorage();
    this.otp = '';
    var body = {
      email: value.email
    }
    this.emailAddress = value.email;
    this.trimRegisterUserData(value.email, 'email');
    this.commonServices.trackUser('emailSubmit', 'submit', body)
    this.dobList = [];
    //Show Loader
    this.ngxService.start();
    this.loginService.submitEmail(value.email, null).subscribe(
      data => {
        this.getUserLanguage(value.email, 'email');
        this.commonServices.detailsForLang['email'] = value.email;
        this.userRemaningAttempt = data['remainingWrongAttemptCount']
        //Hide loader
        this.ngxService.stop();
        this.responseId = data['id'];
        if(data['directOtpUser']){
          this.global.directUser = true;
          this.global.showLoginScreen = 3 ;
        }
        else{
          this.global.directUser = false;
          if (data['dobList']) {
            this.global.showLoginScreen = 2;
            this.dobList = data['dobList'];
          }
        }
      },
      error => {
        //Hide loader
        this.ngxService.stop();
        if (error.status == 404) {
          this.attemptValidationMsg();
        }else if (
          error.error && error.error.cause && 
          ( error.error.cause.toLowerCase() == 'you have reached max allowed initial authentication attempt for today' || 
            error.error.cause.toLowerCase().includes('otp send daily limit exhausted') ) ) {
              this.global.showLoginScreen = 6;
        } else {
          this.toastr.error(this.global.content.somethingWentWrong);
        }
      }
    );
  }

  public submitDob(dob) {
    this.showRelevant = false;
    this.commonServices.trackUser('dobSubmit', 'submit', null)
    this.ngxService.start();
    this.userRemaningAttempt = this.userRemaningAttempt - 1 ;
    this.loginService.submitDOB(dob,this.responseId,this.dobList).subscribe(
      data => {
        this.ngxService.stop();
        if(data && data['primaryDataList'] && data['primaryDataList'] .length && data['alternateLanding']){
          this.responseId = data['id']
          this.mobileNumberList = []
          data['primaryDataList'].forEach(element => {
            this.mobileNumberList.push(this.commonServices.generateDecRequest(element));
          });
          this.global.showLoginScreen = 11;
          if(data['alternateLanding']){
            this.showRelevant = true
          }
        }
        else if(data && data['dobList']){
          this.attemptValidationMsg()
          this.dobList = []
          // this.toastr.error(this.global.content.lastDobAttempt);
          this.dobList = data['dobList'];
        }
        else{
          this.global.showLoginScreen = 3;
        }
      },
      error => {
        // this.mobileNumber = null
        if (error.status == 404) {
          this.attemptValidationMsg()
          this.checkUUID();
        }else if(this.userRemaningAttempt == 0 || (error.error && error.error.cause && error.error.cause.toLowerCase().includes('wrong dob entered even after second attempt'))){
          this.global.showLoginScreen = 6;
        }
        else if (error.status == 400) {
          if (error.error && error.error.cause && error.error.cause.toLowerCase().includes('otp send daily limit exhausted')) {
            this.global.showLoginScreen = 6;
          }else if(this.uuid && error.error && error.error.cause && error.error.cause.toLowerCase().includes('wrong dob entered')){
            this.attemptValidationMsg()
            this.getDataFromUUID(this.uuid)
          } else {
            this.global.showLoginScreen = 1;
            this.toastr.error(this.global.content.wrongDOB);
          }
        } else {
          this.global.showLoginScreen = 1;
          this.toastr.error(this.global.content.somethingWentWrong);
        }
        this.ngxService.stop();
      }
    );
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.otp.length == 6 && this.global.showLoginScreen == 3) {
      this.submitOTP();
    }
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  public submitOTP(): void {
    if (this.otp) {
      this.commonServices.trackUser('otpSubmit', 'submit', null)
      //Show Loader
      this.ngxService.start();
      this.loginService.submitOTP(this.otp, this.responseId).subscribe(
        data => {
          //Hide loader
          this.ngxService.stop();
          if(this.global.directUser){
            this.global.showLoginScreen = 4;
            this.responseId = data['registrationId']
          }
          else {
            sessionStorage.removeItem('sessionId');
            this.commonServices['openCards'] = true;
            if(window.sessionStorage && sessionStorage){
              sessionStorage.setItem('sessionId', this.commonServices.generateEncRequest(data['sessionId']));
            }
            this.commonServices['responseData'] = data;
            this.commonServices.userLogedIn = true;
            this.responseId = data['id'];
            this.router.navigate(['/dashboard']);
          }
          this.otp = '';
        },
        error => {
          this.commonServices.userLogedIn = false;
          if (error.status == 409) {
            this.ngxService.stop();
            this.modalReference = this.modalService.open(this.logoutSessoin, { centered: true });
          } else {
            if (error.error && error.error.cause && error.error.cause.toLowerCase() == "wrong otp entered") {
              this.toastr.error(this.global.content.enteredWrongOtp);
              this.checkUUID();

              // this.attemptValidationMsg();
              // this.otp = '';
              // this.ngOtpInputRef.setValue('');
            }
            else if((error.error && error.error.cause) && (error.error.cause.toLowerCase() == "already otp max attempt crossed." || error.error.cause.toLowerCase() == "you have reached max allowed initial authentication attempt for today")){
              this.global.showLoginScreen = 6;
            }
            else if(error.error && error.error.cause && error.error.cause.toLowerCase() == "data not exist"){
              this.global.showLoginScreen = 4;
            }
            else {
              this.toastr.error(this.global.content.somethingWentWrong);
              this.checkUUID();
            }
            this.otp = '';
            this.ngxService.stop();
          }
          this.ngxService.stop();
        }
      );
    }
  }

  public clickTrack(label, action) {
    this.commonServices.trackUser(label, action, this.commonServices['selectedAccountData'])
  }

  logoutSessoinHistory(value) {
    if (value == 'Yes') {
      //Show Loader
      this.ngxService.start();
      this.loginService.sessionDelete(this.responseId).subscribe(
        data => {
          this.submitOTP();
        },
        error => { }
      );
    } else {
      this.checkUUID();
      this.ngxService.stop();
    }
  }

  //get user selected language 
  public getUserLanguage(value, type) {
    // this.ngxService.start();
    // var reqLang:any;
    // if(type == 'mobileNumber'){
    //   reqLang = { mobileNumber: value }
    // }
    // else{
    //   reqLang = { email: value }
    // }
    // this.commonServices.getLanguage(reqLang).subscribe(
    //   data => {
    //     if (data && data['language']) {
    //       this.global.userSelectedLang = data['language']
    //       this.global.returnLangObj(data['language'])
    //       this.commonServices.detailsForLang['language'] = data['language'];
    //     }
    //     else {
    //       this.commonServices.detailsForLang['language'] = 'en';
    //       // this.clickTrack('open language popup','auto')
    //       // this.app.openlangPopup()
    //     }
    //   },
    //   error => {
    //     this.commonServices.detailsForLang['language'] = 'en';
    //     // this.clickTrack('open language popup','auto')
    //     // this.app.openlangPopup()
    //   }
    // );
  }
  
  private checkSessionLanguage() {
    // if(window.sessionStorage && sessionStorage && sessionStorage.getItem("setSelectedLang")){
    //   var lang = sessionStorage.getItem("setSelectedLang");
    // }
    // if (lang) {
    //   this.global.userSelectedLang = lang;
    //   this.global.returnLangObj(lang);
    //   this.commonServices.detailsForLang['language'] = lang;

    }

  anotherLogin(){
    this.router.navigate(['/']);
    sessionStorage.removeItem('ucode')
    this.global.genericUser = true;
    if (this.mobileNumber) {
      this.registerScreen = 0;
    }
    else {
      this.registerScreen = 1;
    }
    this.global.showLoginScreen = 1;
    this.mobileNumber = null;
    this.emailAddress = null;
    this.clickTrack('useAnotherLogin', 'click')
  }

  trimRegisterUserData(value: any, type: any) {
    value = value.toString();
    if (type == 'mobile') {
      this.trimmedValue = value.slice(6);
    }
    else {
      this.trimmedValue = value.split('@')
      this.trimmedValue = this.trimmedValue[0].slice(-3) + '@' + this.trimmedValue[1]
    }
  }

  submitUserDetails(details: any) {
    details['crnNumber'] = details['crnNumber'] ? details['crnNumber'].toString() : null
    var value;
    if (this.mobileNumber) {
      value = this.mobileNumber;
    }
    else {
      value = this.emailAddress
    }
    this.ngxService.start();
    this.loginService.submitUserDetails(details, this.responseId, value).subscribe(
      data => {
        //Hide loader
        this.clickTrack('Submit User Details', 'submit')
        this.ngxService.stop();
        this.global.showLoginScreen = 5;
        this.mobileNumber = '';
        this.emailAddress = ''
      },
      error => {
        //Hide loader
        this.ngxService.stop();
        this.toastr.error(this.global.content.somethingWentWrong);
      }
    );
  }

  attemptValidationMsg(){
    this.attemptValidation = true;
    setTimeout(() => {
      this.attemptValidation = false;
    }, 3000);
  }

  selectedOption(event){
    if (event.value.toUpperCase() == 'OTHERS') {
      this.showOptOthers = true;
    }
    else {
      this.showOptOthers = false;
    }
  }

  submitArcPreLoginForm(value){
    this.ngxService.start();
    this.commonServices.trackUser('submitted ARD Pre Login', 'submit', this.commonServices['selectedAccountData'])
    this.commonServices.postLoginARC(value,'PRETLOGIN',this.mobileNumber).subscribe(
      data => {
          this.ngxService.stop();
          this.global.showLoginScreen = 8
      },
      err => {
        this.ngxService.stop();
        this.commonServices.returnValidError(err);
      }
    )
  }

  submitNameARC(event){
    this.showRelevant = false;
    this.ngxService.start();
    this.clickTrack('submitName','submit')
    this.loginService.submitName(event,this.responseId).subscribe(
      res =>{
        this.ngxService.stop();
        if(res && res['dobList'] && res['alternateLanding']){
            this.showRelevant = true
            this.dobList = res['dobList'];
            this.responseId = res['id'];
            this.global.showLoginScreen = 2;
        }
        else if(res['customerStatus'] && res['customerStatus'].toLowerCase() == 'showmobileotp'){
          this.global.showLoginScreen = 3;
        }
      },
      err =>{
        this.ngxService.stop();
        // this.mobileNumber = null
        if(err.error && err.error.cause && err.error.cause.toLowerCase() == "wrong name entered"){
          this.checkUUID()
          this.toastr.error(this.global.content.selectedWrongName);
        }
        else if(err.error && err.error.cause && err.error.cause.toLowerCase() == "data not exist"){
          this.checkUUID()
          this.toastr.error(this.global.content.noAccountFound);
        }
        else if( err.error && err.error.cause && err.error.cause.toLowerCase() == "you have reached max allowed initial authentication attempt for today"){
          this.global.showLoginScreen = 6;
        }
        else{
          this.checkUUID()
          this.toastr.error(this.global.content.somethingWentWrong);
        }
      }
    )
    
  }

  
  openArdPreLogin(){
    this.global.tempShowLoginScreen =  this.global.showLoginScreen;
    this.global.showLoginScreen = 7
  }


}
