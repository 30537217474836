import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../global';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { configObj } from '../../configuration';
import { AppComponent } from '../../app.component';
import { NewloginService } from '../../newlogin/newlogin.service';
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
    public global: Global,
    private loginService: NewloginService,
    private activatedRoute: ActivatedRoute,
    public commonServices: CommonService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private modalService: NgbModal,
    public app: AppComponent
  ) { }

  public configuration = configObj;

  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '60px',
      'height': '60px'
    }
  };
  public dobList: any;
  private uuid: any;
  public mobileNumber: any;
  public emailAddress: any;
  private responseId: any;
  public otp: string;
  public selectDobCount: any;
  public modalReference: any;
  public mainObj: any = {};
  public selectedTabIndex: any = 0;
  @ViewChild('logoutSessoin') logoutSessoin
  @ViewChild('mobileForm') mobileForm;
  @ViewChild('otpForm') otpForm;
  @ViewChild('logoutConfirmation') logoutConfirmation: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;


  ngOnInit(): void {
    this.otp = '';
    this.global.showLoginScreen = 1;
    this.commonServices.checkSession().then(
      data => {
        this.router.navigate(['dashboard'])
      }
    ).catch(
      error => {
        this.checkUUID();
      }
    );
  }

  ngAfterViewInit() {
    var x = window.matchMedia("(max-width: 992px)")
    if (('OTPCredential' in window) && x.matches) {
      const ac = new AbortController();
      var reqObj = {
        otp: { transport: ['sms'] },
        signal: ac.signal
      };
      navigator.credentials.get(
        reqObj
      ).then((otp) => {
        if (otp) {
          setTimeout(() => {
            this.ngOtpInputRef.setValue(otp['code']);
            this.otp = otp['code'];
            this.submitOTP();
          }, 1000)
        }
      }).catch(err => { });
    }
  }

  private checkUUID() {
    this.checkSessionLanguage();
    this.activatedRoute.params.subscribe(routeParams => {
      let uuid = routeParams.uuid;
      if (uuid) {
        this.uuidGet(uuid);
        this.checkUrlLang();
        this.commonServices.detailsForLang.uuid = uuid;
      }
      else {
      }
    });
  }

  private checkUrlLang() {
    // this.activatedRoute.params.subscribe(routeParams => {
    //   let urlLang = routeParams.lang;
    //   if (urlLang) {
    //     this.global.userSelectedLang = urlLang;
    //     this.global.returnLangObj(urlLang);
    //     this.commonServices.detailsForLang['language'] = urlLang;
    //   }
    //   else {
    //   }
    // });
  }

  private uuidGet(userUuid: string) {
    this.ngxService.start();
    this.uuid = '';
    this.loginService.uuidData(userUuid).subscribe(
      data => {
        if (data['primaryKey']) {
          this.uuid = userUuid;
          if(window.sessionStorage && sessionStorage){
            sessionStorage.setItem("ucode", userUuid);
          }
          if (data['type'].toUpperCase() == 'EMAIL') {
            // this.emailLogin = true;
            this.emailAddress = data['primaryKey'];
            this.commonServices.detailsForLang['email'] = data['primaryKey'];
            this.selectedTabIndex = 1;
            this.getUserLanguage(this.emailAddress, 'email');
          } else {
            // this.emailLogin = false;
            this.mobileNumber = data['primaryKey'];
            this.commonServices.detailsForLang['mobileNumber'] = data['primaryKey'];
            this.selectedTabIndex = 0;
            this.getUserLanguage(this.mobileNumber, 'mobileNumber');
          }
        }
        this.ngxService.stop();
      },
      error => {
        this.ngxService.stop();
      }
    );
  }

  resendOtp() {
    if (this.responseId) {
      this.commonServices.trackUser('resendOtp', 'click', null)
      this.ngxService.start();
      // this.emptyOtpForm();
      this.loginService.resendOtp(this.responseId).subscribe(
        data => {
          this.toastr.success(this.global.content.OTPSentAgain);
          //Hide loader
          this.ngxService.stop();
        },
        error => {
          //Hide loader
          this.ngxService.stop();
          if (error.error.cause == 'You have crossed limit for resend otp') {
            this.toastr.error(this.global.content.otpGeneratedManyTimes);
          } else {
            this.toastr.error(this.global.content.somethingWentWrong);
          }
        }
      );
    }
  }

  /**
  * This method submits mobile number
  * @param form Form Object
  */
  public submitMobile(number: any): void {
    this.commonServices.detailsForLang['mobileNumber'] = number;
    this.commonServices.getLangFromSessionStorage();
    this.otp = ''
    var body = {
      MobileNumber: number
    }
    this.commonServices.trackUser('mobileSubmit', 'submit', body)
    this.dobList = [];
    //Show Loader
    this.ngxService.start();
    this.loginService.submitMobile(number, this.uuid).subscribe(
      data => {
        this.getUserLanguage(number, 'mobileNumber');
        this.commonServices.detailsForLang['mobileNumber'] = number;
        //Hide loader
        // this.disableTab = true;
        this.ngxService.stop();
        this.responseId = data['id'];
        this.mobileNumber = null;
        if (data['dobList']) {
          this.global.showLoginScreen = 2;
          this.dobList = data['dobList'];
        }
        // this.emptyOtpForm();
      },
      error => {
        //Hide loader
        this.ngxService.stop();
        if (error.status == 404) {
          this.toastr.warning(this.global.content.noAccountFound);
        } else if (
          error.error && error.error.cause &&
          (error.error.cause.toLowerCase() == 'you have reached max allowed initial authentication attempt for today' ||
            error.error.cause.toLowerCase().includes('otp send daily limit exhausted'))) {
          this.toastr.error(this.global.content.accountLocked);
        } else {
          this.toastr.error(this.global.content.somethingWentWrong);
        }
        this.setLoginScreen();
      }
    );
  }

  public submitEmail(value: any) {
    this.commonServices.detailsForLang['email'] = value.email;
    this.commonServices.getLangFromSessionStorage();
    this.otp = '';
    var body = {
      email: value.email
    }
    this.commonServices.trackUser('emailSubmit', 'submit', body)
    this.dobList = [];
    //Show Loader
    this.ngxService.start();
    this.loginService.submitEmail(value.email, this.uuid).subscribe(
      data => {
        this.getUserLanguage(value.email, 'email');
        this.commonServices.detailsForLang['email'] = value.email;
        //Hide loader
        this.ngxService.stop();
        this.responseId = data['id'];
        this.emailAddress = null;
        if (data['dobList']) {
          this.global.showLoginScreen = 2;
          this.dobList = data['dobList'];
        }
      },
      error => {
        //Hide loader
        this.ngxService.stop();
        if (error.status == 404) {
          this.toastr.warning(this.global.content.noAccountFound);
        } else if (
          error.error && error.error.cause &&
          (error.error.cause.toLowerCase() == 'you have reached max allowed initial authentication attempt for today' ||
            error.error.cause.toLowerCase().includes('otp send daily limit exhausted'))) {
          this.toastr.error(this.global.content.accountLocked);
        } else {
          this.toastr.error(this.global.content.somethingWentWrong);
        }
        this.setLoginScreen();
      }
    );
  }

  public submitDob(dob) {
    this.commonServices.trackUser('dobSubmit', 'submit', null)
    this.ngxService.start();
    this.loginService.submitDOB(dob, this.responseId).subscribe(
      data => {
        //DOB submit
        this.selectDobCount = dob;
        setTimeout(() => {
          this.global.showLoginScreen = 3;
          this.selectDobCount = undefined;
        }, 10);
        //Hide loader
        this.ngxService.stop();
      },
      error => {
        //DOB submit
        this.selectDobCount = undefined;
        //Hide loader
        if (error.status == 404) {
          this.toastr.warning(this.global.content.noAccountFound);
        }
        else if (error.status == 400) {
          if (error.error && error.error.cause && error.error.cause.toLowerCase().includes('otp send daily limit exhausted')) {
            this.toastr.error(this.global.content.accountLocked);
          } else {
            this.toastr.error(this.global.content.wrongDOB);
          }
        } else {
          this.toastr.error(this.global.content.somethingWentWrong);
        }
        this.setLoginScreen();
        this.ngxService.stop();
      }
    );
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.otp.length == 5) {
      this.submitOTP();
    }
  }

  onOtpChange(otp) {
    this.otp = otp;
  }


  // keyup(event){
  //   if(this.otpLen == 5 ){
  //     this.otp.slice(this.otp.length);
  //     this.otpLen = ''
  //   }
  //   else{
  //     this.otp = this.otp.slice(0,-1);
  //   }
  //   this.ngOtpInputRef.setValue(this.otp);
  //   this.onOtpChange(this.otp);
  // }
  /**
* This method submits OTP
* @param otp Selected OTP
*/


  public submitOTP(): void {
    if (this.otp) {
      this.commonServices.trackUser('otpSubmit', 'submit', null)
      // this.userSelectedOtp = this.otp;
      //Show Loader
      this.ngxService.start();
      this.loginService.submitOTP(this.otp, this.responseId).subscribe(
        data => {
          //Hide loader
          this.ngxService.stop();
          sessionStorage.removeItem('sessionId');
          this.commonServices['openCards'] = true;
          if(window.sessionStorage && sessionStorage){
            sessionStorage.setItem('sessionId', this.commonServices.generateEncRequest(data['sessionId']));
          }
          this.commonServices['responseData'] = data;
          this.commonServices.userLogedIn = true;
          this.responseId = data['id'];
          this.router.navigate(['/dashboard']);
        },
        error => {
          //Hide loader
          this.commonServices.userLogedIn = false;
          if (error.status == 409) {
            this.ngxService.stop();
            this.modalReference = this.modalService.open(this.logoutSessoin, { centered: true });
          } else {
            if (error.error.cause == "Wrong OTP entered") {
              this.toastr.error(this.global.content.enteredWrongOtp);
            } else {
              this.toastr.error(this.global.content.somethingWentWrong);
            }
            this.setLoginScreen();
            this.ngxService.stop();
          }
          this.ngxService.stop();
        }
      );
    }
  }

  public clickTrack(label, action) {
    this.commonServices.trackUser(label, action, this.commonServices['selectedAccountData'])
  }

  private setLoginScreen() {
    this.global.showLoginScreen = 1;
    this.selectDobCount = undefined;
    this.otp = '';
  }


  logoutSessoinHistory(value) {
    if (value == 'Yes') {
      //Show Loader
      this.ngxService.start();
      this.loginService.sessionDelete(this.responseId).subscribe(
        data => {
          this.submitOTP();
        },
        error => { }
      );
    } else {
      this.setLoginScreen();
      this.ngxService.stop();
    }
  }

  //get user selected language 

  public getUserLanguage(value, type) {
    // this.ngxService.start();
    // var reqLang: any;
    // if (type == 'mobileNumber') {
    //   reqLang = { mobileNumber: value }
    // }
    // else {
    //   reqLang = { email: value }
    // }
    // this.commonServices.getLanguage(reqLang).subscribe(
    //   data => {
    //     if (data && data['language']) {
    //       this.global.userSelectedLang = data['language']
    //       this.global.returnLangObj(data['language'])
    //       this.commonServices.detailsForLang['language'] = data['language'];
    //     }
    //     else {
    //       this.app.openlangPopup()
    //     }
    //   },
    //   error => {
    //     this.app.openlangPopup()
    //   }
    // );
  }

  public changeTab(value: any) {
    this.commonServices.trackUser('changeLoginTab', 'click', null);
    this.selectedTabIndex = value.index;
  }

  private checkSessionLanguage() {
    // if(window.sessionStorage && sessionStorage && sessionStorage.getItem("setSelectedLang")){
    //   var lang = sessionStorage.getItem("setSelectedLang");
    // }
    // if (lang) {
    //   this.global.userSelectedLang = lang;
    //   this.global.returnLangObj(lang);
    //   this.commonServices.detailsForLang['language'] = lang;
    // }
  }

  changeTabViaLink(indexVal) {
    if (this.global.showLoginScreen > 1) {
      this.setLoginScreen();
      this.selectedTabIndex = indexVal;
    }
    else {
      this.selectedTabIndex = indexVal;
    }
  }

  ngOnDestroy() {
    this.global.showLoginScreen = 1;
  }

}
