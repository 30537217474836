import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },{
    path: 'loginOld',
    loadChildren: () => import('./newlogin/newlogin.module').then(m => m.NewloginModule)
  },
  {
    path: 'loginOld/:uuid',
    loadChildren: () => import('./newlogin/newlogin.module').then(m => m.NewloginModule)
  },
  {
    path: 'loginOld/:lang/:uuid',
    loadChildren: () => import('./newlogin/newlogin.module').then(m => m.NewloginModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'login/:uuid',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'login/:lang/:uuid',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'directLogin/:uuid',
    loadChildren: () => import('./directlogin/directlogin.module').then(m => m.DirectloginModule)
  },
  {
    path: 'ardLogin',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'ardLogin/:uuid',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'ardLogin/:lang/:uuid',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'onboarding01',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'onboarding01/:uuid',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'onboarding01/:lang/:uuid',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'onboarding02',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'onboarding02/:uuid',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'onboarding02/:lang/:uuid',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'creditScore/:uuid',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'creditScore/:lang/:uuid',
    loadChildren: () => import('./organicuserlogin/organicuserlogin.module').then(m => m.OrganicuserloginModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboards/dashboards.module').then(m => m.BankDashboardsModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'payment/:status',
    loadChildren: () => import('./payment-module/payment-module.module').then(m => m.PaymentModuleModule)
  },
  {
    path: 'dirPayReturn/:status',
    loadChildren: () => import('./payment-module/payment-module.module').then(m => m.PaymentModuleModule)
  },
  {
    path: 'dirPay/:uuid',
    loadChildren: () => import('./dirpayment/dirpayment.module').then(m => m.DirpaymentModule)
  },
  {
    path: 'chart',
    loadChildren: () => import('./charts/charts.module').then(m => m.ChartsAppModule)
  },
  // {
  //   path: 'scratch-card',
  //   loadChildren: () => import('./scratch-card/scratch-card.module').then(m => m.ScratchCardModule)
  // },
  {
    path: 'ethPay',
    loadChildren: () => import('./ethpayment/ethpayment.module').then(m => m.EthpaymentModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pagenotfound/pagenotfound.module').then(m => m.PagenotfoundModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
