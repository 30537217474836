import { ErrorHandler } from "@angular/core";
declare var newrelic: any;

import { environment } from "../environments/environment";

export class AppGlobalErrorhandler implements ErrorHandler {
  handleError(error) {
    // console.error(error);
    environment.production ? newrelic.noticeError(error) : null;
  }
}
