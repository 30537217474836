import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvertToINRPipe } from './custom.pipe';
import { LoginFormComponent } from './login-form/login-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { OrganicuserComponent } from './organicuser/organicuser.component';
import { MissingpaymentComponent } from './splashscreen/missingpayment.component';
import { LogindobformComponent } from './logindobform/logindobform.component';
import { MatRadioModule} from '@angular/material/radio';

@NgModule({
  declarations: [ConvertToINRPipe, LoginFormComponent, OrganicuserComponent,MissingpaymentComponent, LogindobformComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    MatTabsModule,
    MatInputModule,
    MatRadioModule
  ],
  exports: [ConvertToINRPipe,LoginFormComponent,OrganicuserComponent,MissingpaymentComponent,LogindobformComponent]
})
export class SharedModule { }
