<mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="changeTab($event);" >
    <mat-tab>
        <div class="mt-4">
            <div class="bankLoginStepsBottomBlock px-0" *ngIf="global.showLoginScreen == 1">
                <form #mobileForm="ngForm" (ngSubmit)="mobileForm.valid && submitMobile(mobileNumber)">
                    <div class="bankLoginStepsBottomInnerBlock">
                        <div class="bankLoginstepperHeadings">
                            {{global.content.YourRegisteredMobileNumber}}</div>
                        <div class="bankLoginNumberStep mt-4">
                            <div class="d-flex bankLoginStepsInputBlock">
                                <div>+91</div>
                                <input type="number" name="contact" #contact="ngModel"
                                    [(ngModel)]="mobileNumber" ngModel pattern="^([6-9]){1}([0-9]){9}?$"
                                    [ngClass]="{ 'is-invalid': mobileForm.submitted && contact.invalid }"
                                    required />
                            </div>
                            <div class="validationBox text-danger">
                                <div
                                    *ngIf="mobileForm.submitted && contact.invalid && contact.errors && contact.errors.required">
                                    {{global.content.MobileNoisRequired}}
                                </div>
                                <div
                                    *ngIf="contact.invalid && contact.errors && contact.errors.pattern">
                                    {{global.content.EnterValidMobileNumber}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bankLoginBtnBlock d-flex justify-content-end">
                        <button>
                            {{global.content.NextBtn}} <img src="assets/images/icons/nextArrowWhite.png"
                                alt="netcArrowIcon">
                        </button>
                    </div>
                    <div class="d-flex orLineBlock align-items-center">
                        <div></div><span>Or</span><div></div>
                    </div>
                    <div (click)="changeTabViaLink(1)" class="loginLinksBlock">
                        <svg xmlns="http://www.w3.org/2000/svg" class="tabicons" viewBox="0 0 15 12"> <path d="M16.5,6H4.5A1.5,1.5,0,0,0,3.008,7.5L3,16.5A1.5,1.5,0,0,0,4.5,18h12A1.5,1.5,0,0,0,18,16.5v-9A1.5,1.5,0,0,0,16.5,6Zm0,3-6,3.75L4.5,9V7.5l6,3.75,6-3.75Z" transform="translate(-3 -6)"/></svg> {{global.content.verifyViaEmail}}
                    </div>
                </form>
            </div>
        </div>
    </mat-tab>

    <mat-tab>
        <div class="mt-4">
            <div class="bankLoginStepsBottomBlock px-0" *ngIf="global.showLoginScreen == 1">
                <form #emailForm="ngForm" (ngSubmit)="emailForm.valid && submitEmail(emailForm.value)">
                    <div class="bankLoginStepsBottomInnerBlock">
                        <div class="bankLoginstepperHeadings">{{global.content.registeredEmail}}</div>
                        <div class="bankLoginNumberStep mt-4">
                            <div class="d-flex bankLoginStepsInputBlock">
                                <input type="email" name="email" #email="ngModel"
                                    [(ngModel)]="emailAddress"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
                                    [ngClass]="{ 'is-invalid': emailForm.submitted && email.invalid }"
                                    required class="px-0" />
                            </div>
                            <div class="validationBox text-danger">
                                <div
                                    *ngIf="emailForm.submitted && email.invalid && email.errors && email.errors.required">
                                    {{global.content.emailRequired}}
                                </div>
                                <div *ngIf="email.invalid && email.errors && email.errors.pattern">
                                    {{global.content.enterValidEmail}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bankLoginBtnBlock d-flex justify-content-end">
                        <button>
                            {{global.content.NextBtn}} <img src="assets/images/icons/nextArrowWhite.png"
                                alt="netcArrowIcon">
                        </button>
                    </div>
                    <div class="d-flex orLineBlock align-items-center">
                        <div></div><span>Or</span><div></div>
                    </div>
                    <div (click)="changeTabViaLink(0)" class="loginLinksBlock">
                        <svg xmlns="http://www.w3.org/2000/svg" class="tabicons" viewBox="0 0 9.952 17"> <path d="M15.474,1.928H7.332a1,1,0,0,0-.9,1.063V17.866a1,1,0,0,0,.9,1.062h8.143a1,1,0,0,0,.9-1.062V2.991A1,1,0,0,0,15.474,1.928ZM11.4,18.161a.837.837,0,0,1,0-1.653.837.837,0,0,1,0,1.653Zm4.071-2.42H7.332V4.053h8.143Z" transform="translate(-6.427 -1.928)" /></svg> {{global.content.verifyViaMobile}}
                    </div>
                </form>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>


<div class="bankLoginStepsBottomBlock px-0" *ngIf="global.showLoginScreen == 2">
    <form #verifyDobForm="ngForm">
        <div class="bankLoginStepsBottomInnerBlock">
            <div class="bankLoginstepperHeadings">{{global.content.SelectYourBirthYear}}
            </div>
            <div class="bankLoginNumberStep mt-4">
                <div class="d-flex justify-content-between mt-2 mt-lg-3 BankLoginDobBlock"
                    *ngFor="let dob of dobList;index as i" (click)="submitDob(dob)">
                    <div>{{dob}}</div>
                </div>
            </div>
        </div>
        <div class="bankLoginBtnBlock d-flex justify-content-end dobBtnBlock">
        </div>
    </form>
</div>
<div class="bankLoginStepsBottomBlock px-0" *ngIf="global.showLoginScreen == 3">
    <form #otpForm="ngForm">
        <div class="bankLoginStepsBottomInnerBlock">
            <div class="bankLoginstepperHeadings">{{global.content.EnterTheOtp}}</div>
            <div class="bankLoginNumberStep mt-4">
                <ng-otp-input #ngOtpInput [config]="config" required name="one-time-code" 
                    autocomplete="one-time-code" (onInputChange)="onOtpChange($event)"
                    ngDefaultControl [(ngModel)]="otp"></ng-otp-input>
            </div>
            <div class="resendOtp">
                <span class="cursorPointer resendOtpStyle"
                    (click)="resendOtp()">{{global.content.ResendOTP}}</span>
            </div>
            <diV>
                <!-- <div class="ticBoxTocontinue">{{global.content.tickBoxToContinue}}</div> -->
                <!-- <div class="g-recaptcha mt-2" data-theme="light">
                    <re-captcha (resolved)="captchaData($event)"
                        [siteKey]="configuration.captchaKey">
                    </re-captcha>
                </div> -->
            </diV>
        </div>
        <div class="bankLoginBtnBlock d-flex justify-content-end">
            <button (click)="submitOTP()">
                {{global.content.NextBtn}} <img src="assets/images/icons/nextArrowWhite.png"
                    alt="nextArrowIcon">
            </button>
        </div>
    </form>
</div>

<ng-template #logoutSessoin let-modal>
    <div class="modal-body px-0 py-4">
        <div class="col-12 px-0 d-flex flex-wrap">
            <div class="col-12 text-center"><img src="assets/images/icons/logoutIconTwo.png" alt="logoutIcon"
                    class="mt-2 logoutIcon">
            </div>
            <div class="col-12 my-3 f-16 px-2 text-center">{{global.content.PleaseLogoutFromAllOtherDevices}}</div>
            <div class="col-12 d-flex justify-content-around p-0 pb-3">
                <button class="btn timeoutPopUpClose"
                    (click)="logoutSessoinHistory('No');modal.close();clickTrack('Close-logoutSession','click')">{{global.content.CloseBtn}}</button>
                <button class="btn timeoutPopUpWait"
                    (click)="logoutSessoinHistory('Yes');modal.close();clickTrack('Logout-logoutSession','click')">{{global.content.LogoutBtn}}</button>
            </div>
        </div>
    </div>
</ng-template>
