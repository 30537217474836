import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { Global } from '../../global';

@Component({
  selector: 'app-missingpayment',
  templateUrl: './missingpayment.component.html',
  styleUrls: ['./missingpayment.component.scss']
})
export class MissingpaymentComponent implements OnInit,AfterViewInit {

  constructor(
    public global: Global,
    public router:Router,
    public commonServices:CommonService
  ) { }

  private onboardInterval:any;

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.skipTimer()
  }

  @Output()notRelevantChild = new EventEmitter();
  public timer:number = 10;

  navToLogin(orboardScreenNumber){
    this.commonServices.trackUser('onboarding0'+orboardScreenNumber, 'close', null)
    this.global.displaySplashScreens = null;
  }

  public skipTimer() {
    if(this.global.displaySplashScreens){
      this.onboardInterval = setInterval(() => {
        if(this.timer <= 1 && this.global.displaySplashScreens){
          this.commonServices.trackUser('onboarding0'+this.global.displaySplashScreens , 'autoClose', null)
          this.global.displaySplashScreens = null;
        }
        else{
          this.timer = this.timer - 1;
        }
      }, 1000);
    }
  }

  notRelevant(){
    this.global.displaySplashScreens = null;
    this.notRelevantChild.emit('ARD')
  }

  ngOnDestroy(){
   clearInterval(this.onboardInterval);
  }

						
   

}
