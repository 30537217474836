<div *ngIf="global && global.content">
    <!-- mobile -->
    <div class="formBlockScreen " *ngIf="global.showLoginScreen == 1 && registerScreen == 0">
            <form #mobileForm="ngForm" (ngSubmit)="mobileForm.valid && submitMobile(mobileNumber)">
                <div class="bankLoginStepsBottomInnerBlock">
                    <div class="bankLoginstepperHeadings">
                        {{global.content.enterMobileNumber}}</div>
                    <div class="bankLoginNumberStep mt-4">
                        <div class="d-flex bankLoginStepsInputBlock p-0">
                            <div class=" px-3 d-flex align-items-center inputPrevBlock"><b>+91</b></div>
                            <input type="number" name="contact" #contact="ngModel" placeholder="{{global.content.enterMobileNumberLoginPlaceholder}}"
                                [(ngModel)]="mobileNumber" ngModel pattern="^([6-9]){1}([0-9]){9}?$" 
                                [ngClass]="{ 'is-invalid': mobileForm.submitted && contact.invalid }"
                                required />
                        </div>
                        <div class="validationBox text-danger">
                            <div *ngIf="mobileForm.submitted && contact.invalid && contact.errors && contact.errors.required">
                                {{global.content.MobileNoisRequired}}
                            </div>
                            <div *ngIf="contact.invalid && contact.errors && contact.errors.pattern">
                                {{global.content.EnterValidMobileNumber}}
                            </div>
                            <div *ngIf="attemptValidation">
                                {{global.content.noAccountFound}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bankLoginBtnBlock d-flex justify-content-end">
                    <button>
                        {{global.content.NextBtn}} <img src="assets/images/icons/nextArrowWhite.png"
                            alt="netcArrowIcon">
                    </button>
                </div>
                <div class="px-2 px-lg-3 orOptionBlock">
                    <div class="d-flex orLineBlock align-items-center">
                        <div></div><span>OR</span><div></div>
                    </div>
                    <div (click)="registerScreen = 1;" class="loginLinksBlock d-flex">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class=" emailIcon" viewBox="0 0 15 12"> <path d="M16.5,6H4.5A1.5,1.5,0,0,0,3.008,7.5L3,16.5A1.5,1.5,0,0,0,4.5,18h12A1.5,1.5,0,0,0,18,16.5v-9A1.5,1.5,0,0,0,16.5,6Zm0,3-6,3.75L4.5,9V7.5l6,3.75,6-3.75Z" transform="translate(-3 -6)"/></svg> 
                            <span>{{global.content.verifyViaEmail}}</span>
                        </div>
                    </div>
                </div>
            </form>
    </div>
    <!-- email -->
    <div class="formBlockScreen " *ngIf="global.showLoginScreen == 1 && registerScreen == 1">
        <form #emailForm="ngForm" (ngSubmit)="emailForm.valid && submitEmail(emailForm.value)">
            <div class="bankLoginStepsBottomInnerBlock">
                <div class="bankLoginstepperHeadings">{{global.content.enterEmail}}</div>
                <div class="bankLoginNumberStep mt-4">
                    <div class="d-flex bankLoginStepsInputBlock p-0">
                        <div class=" px-3 d-flex align-items-center inputPrevBlock" ><svg xmlns="http://www.w3.org/2000/svg" class="emailScreenIcon" viewBox="0 0 15 12"> <path d="M16.5,6H4.5A1.5,1.5,0,0,0,3.008,7.5L3,16.5A1.5,1.5,0,0,0,4.5,18h12A1.5,1.5,0,0,0,18,16.5v-9A1.5,1.5,0,0,0,16.5,6Zm0,3-6,3.75L4.5,9V7.5l6,3.75,6-3.75Z" transform="translate(-3 -6)"/></svg></div>
                        <input type="email" name="email" #email="ngModel"  
                            placeholder="{{global.content.enterEmailLoginPlaceholder}}"
                            [(ngModel)]="emailAddress"
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"
                            [ngClass]="{ 'is-invalid': emailForm.submitted && email.invalid }"
                            required />
                    </div>
                    <div class="validationBox text-danger">
                        <div *ngIf="emailForm.submitted && email.invalid && email.errors && email.errors.required">
                            {{global.content.emailRequired}}
                        </div>
                        <div *ngIf="email.invalid && email.errors && email.errors.pattern">
                            {{global.content.enterValidEmail}}
                        </div>
                        <div *ngIf="attemptValidation" >
                            {{global.content.noAccountFound}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="bankLoginBtnBlock d-flex justify-content-end">
                <button>
                    {{global.content.NextBtn}} <img src="assets/images/icons/nextArrowWhite.png"
                        alt="netcArrowIcon">
                </button>
            </div>
            <div class="px-2 px-lg-3 orOptionBlock">
                <div class="d-flex orLineBlock align-items-center">
                    <div></div><span>OR</span><div></div>
                </div>
                <div (click)="registerScreen = 0;" class="loginLinksBlock  d-flex">
                    <div>                    
                        <svg xmlns="http://www.w3.org/2000/svg" class="tabicons" viewBox="0 0 9.952 17"> <path d="M15.474,1.928H7.332a1,1,0,0,0-.9,1.063V17.866a1,1,0,0,0,.9,1.062h8.143a1,1,0,0,0,.9-1.062V2.991A1,1,0,0,0,15.474,1.928ZM11.4,18.161a.837.837,0,0,1,0-1.653.837.837,0,0,1,0,1.653Zm4.071-2.42H7.332V4.053h8.143Z" transform="translate(-6.427 -1.928)" /></svg>
                    </div>
                    <span>{{global.content.verifyViaMobile}}</span>
                </div>
            </div>
        </form>
    </div>
    <!-- dob -->
    <div class="formBlockScreen " *ngIf="global.showLoginScreen == 2">
        <app-logindobform  (retunrPreLogin)="openArdPreLogin()"   (returnAnotherLogin)="anotherLogin()" [notRelevantYou]="showRelevant" (returnSelectedValue)="submitDob($event)" [mobileNumberChild]="mobileNumber" [trimmedChildValue]="trimmedValue" [userRemaningAttemptChild]="userRemaningAttempt" [heading]="global.content.confirmBirthYear" [attemptValidationChild]="attemptValidation" [dataList]="dobList"></app-logindobform>
        <!-- <form #verifyDobForm="ngForm">
            <div class="bankLoginStepsBottomInnerBlock">
                <div class="bankLoginstepperHeadings text-center">{{global.content.confirmBirthYear}}
                </div>
                <div class="bankLoginNumberStep mt-4">
                    <div class="d-flex justify-content-center mt-2 mt-lg-3 BankLoginDobBlockNew w-100"
                        *ngFor="let dob of dobList;index as i" (click)="submitDob(dob)">
                        <div>{{dob}}</div>
                    </div>
                </div>
            </div>
            <div class="bankLoginBtnBlock d-flex " *ngIf="attemptValidation">
                <div>
                    <img src="assets/images/icons/cautionMark.png" alt="cautionMarkIcon" class="cautionMarkIcon">
                </div>
                <div *ngIf="attemptValidation">
                    <div class="incorrectResponseBlock">{{global.content.incorrectRes}}
                        <span *ngIf="userRemaningAttempt && userRemaningAttempt > 0 ">
                            {{global.content.attemptsLeft}} {{userRemaningAttempt}} {{global.content.attemptsLeft2}}
                        </span>
                    </div>
                    <div class="selectCarefully">{{global.content.selectCarefully}}</div>
                </div>
            </div>
            <div class="px-2 px-lg-3 orOptionBlock">
                <div class="d-flex orLineBlock align-items-center">
                    <div></div><span>OR</span><div></div>
                </div>
                <div  class="d-flex justify-content-center py-2 flex-wrap">
                    <div class="loggedInBlock">{{global.content.notKeyword}} {{mobileNumber ? 'xxxxxxx'  : 'xxx' }}{{trimmedValue}}?</div>
                    <div class="useAnotherLoginBlock" (click)="anotherLogin()">{{global.content.anotherLogin}}</div>
                </div>
            </div>
        </form> -->
    </div>
    <!-- otp -->
    <div class="formBlockScreen " *ngIf="global.showLoginScreen == 3">
        <form #otpForm="ngForm">
            <div class="bankLoginStepsBottomInnerBlock">
                <div class="bankLoginstepperHeadings text-center">{{global.content.enterOtp}}</div>
                <div class="bankLoginNumberStep customizeOtp mt-4">
                    <ng-otp-input #ngOtpInput [config]="config" required name="one-time-code" 
                        autocomplete="one-time-code" (onInputChange)="onOtpChange($event)"
                        ngDefaultControl [(ngModel)]="otp"></ng-otp-input>
                </div>
                <div class="resendOtp">
                    {{global.content.didntRecieveOtp}}
                    <span class="cursorPointer resendOtpStyle"
                        (click)="resendOtp()">{{global.content.ResendOTP}}</span>
                </div>
                <div class="d-flex align-items-center mt-4" *ngIf="showRelevant">
                    <div>
                        <img src="assets/images/icons/cautionMark.png" alt="cautionMarkIcon" class="mx-2">
                    </div>
                    <div>{{global.content.agreeCommunication}} {{global.content.bankFullName}} {{global.content.agreeCommunication1}}</div>
                </div>
            </div>
            <div class="bankLoginBtnBlock d-flex " *ngIf="attemptValidation">
                <div>
                    <img src="assets/images/icons/cautionMark.png" alt="cautionMarkIcon" class="cautionMarkIcon">
                </div>
                <div *ngIf="attemptValidation">
                    <div class="incorrectResponseBlock">{{global.content.incorrectRes}}</div>
                    <div class="selectCarefully">{{global.content.enterCorrectOtp}}</div>
                </div>
            </div>
            <div class="bankLoginBtnBlock d-flex justify-content-end">
                <button (click)="submitOTP()">
                    {{global.content.loginKyword}}<img src="assets/images/icons/nextArrowWhite.png"
                        alt="nextArrowIcon">
                </button>
            </div>
            <div  class="d-flex justify-content-center py-2 flex-wrap orOptionBlock" *ngIf="!showRelevant">
                <div class="loggedInBlock">{{global.content.notKeyword}} {{mobileNumber ? 'xxxxxxx'  : 'xxx' }}{{trimmedValue}}?</div>
                <div class="useAnotherLoginBlock" (click)="anotherLogin()">{{global.content.anotherLogin}}</div>
            </div>
            <div  class="d-flex justify-content-center py-4 flex-wrap" *ngIf="showRelevant">
                <div class="loggedInBlock">{{global.content.notRelevantToYou}}</div>
                <div class="useAnotherLoginBlock" (click)="openArdPreLogin()">{{global.content.clickHereKeyword}}</div>
            </div> 
        </form>
    </div>
    <!-- user details if no aacount -->
    <div class="formBlockScreen noAccountScreen" *ngIf="global.showLoginScreen == 4" >
        <form #userDetailForm="ngForm" (ngSubmit)="userDetailForm.valid && submitUserDetails(userDetailForm.value)">
            <div class="bankLoginStepsBottomInnerBlock">
                <div class="bankLoginstepperHeadings text-center">{{global.content.submitDetails}}</div>
                <div class="reachOutBlock">{{global.content.reachOutShortly}}</div>
                <div class="bankLoginNumberStep mt-3">
                        <div>
                            <input type="text" name="userName" #userName="ngModel"  
                            placeholder="{{global.content.namePlaceholder}}*" ngModel
                            [ngClass]="{ 'is-invalid': userDetailForm.submitted && userName.invalid }"
                            required />
                            <div class="validationBox text-danger">
                                <div
                                    *ngIf="userDetailForm.submitted && userName.invalid && userName.errors && userName.errors.required">
                                    {{global.content.nameReq}}
                                </div>
                            </div>
                        </div>
                        <div  *ngIf="mobileNumber">
                            <input type="email" name="emailAdd" #emailAdd="ngModel"  
                            placeholder="{{global.content.enterEmailLoginPlaceholder}}" [ngClass]="{ 'is-invalid': userDetailForm.submitted && emailAdd.invalid }" ngModel pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$"  >
                            <div class="validationBox text-danger">
                                <div *ngIf="emailAdd.invalid && emailAdd.errors && emailAdd.errors.pattern">
                                    {{global.content.enterValidEmail}}
                                </div>
                            </div> 
                        </div>
                        <div  *ngIf="emailAddress">
                            <input type="number" name="mobNum" #mobNum="ngModel"  
                            placeholder="{{global.content.enterMobPlaceholder}}" ngModel  pattern="^([6-9]){1}([0-9]){9}?$" 
                            [ngClass]="{ 'is-invalid': userDetailForm.submitted && mobNum.invalid }">
                            <div class="validationBox text-danger">
                                <div *ngIf="mobNum.invalid && mobNum.errors && mobNum.errors.pattern">
                                    {{global.content.EnterValidMobileNumber}}
                                </div>
                            </div>
                        </div>
                        <div>
                            <input type="text" name="crnNumber" #crnNumber="ngModel"  
                            placeholder="{{global.content.enterCRNPlaceholder}}" ngModel pattern="^[0-9]+$"/>
                            <div class="validationBox text-danger">
                                <div *ngIf="crnNumber.invalid && crnNumber.errors && crnNumber.errors.pattern">
                                    {{global.content.queryOnlyNumberError}}
                                </div>
                            </div> 
                        </div>
                        <div>
                            <input type="number" name="accNum" #accNum="ngModel"  
                            placeholder="{{global.content.enterAccPlaceholder}}" ngModel required>
                            <div class="validationBox text-danger">
                                <div
                                    *ngIf="userDetailForm.submitted && accNum.invalid && accNum.errors && accNum.errors.required">
                                    {{global.content.loanCradNumReq}}
                                </div>
                            </div>
                        </div>
                        <div>
                            <textarea placeholder={{global.content.detailFormCommentPlaceholder}} name="comment" ngModel
                            #comment="ngModel" minlength="20"  pattern="^[a-zA-Z\d-_., ]+$"></textarea>
                        </div>
                        <div class="validationBox text-danger">
                            <div *ngIf="comment.invalid && comment.errors && comment.errors.pattern">
                                {{global.content.queryMsgError}}
                            </div>
                            <div *ngIf="comment.invalid && comment.errors && comment.errors.minlength">
                                {{global.content.minlengthError}}
                            </div>
                        </div>
                </div>
            </div>
            <div class="bankLoginBtnBlock py-0 d-flex justify-content-end">
                <button>
                    {{global.content.submit}} <img src="assets/images/icons/nextArrowWhite.png"
                        alt="netcArrowIcon">
                </button>
            </div>
            <div  class="d-flex justify-content-center py-2 flex-wrap orOptionBlock">
                <div class="loggedInBlock">{{global.content.notKeyword}} {{mobileNumber ? 'xxxxxxx'  : 'xxx' }}{{trimmedValue}}?</div>
                <div class="useAnotherLoginBlock" (click)="anotherLogin()">{{global.content.anotherLogin}}</div>
            </div>
        </form>
    </div>
    <!-- user details submit thanku -->
    <div  *ngIf="global.showLoginScreen == 5 || global.showLoginScreen == 8 ">
        <div class=" formBlockScreen noAccountScreen">
            <div *ngIf="global.showLoginScreen == 5 ">
                <div class="detailSubmittedHaeding text-center colorBlue" >{{global.content.detailsSubmitted}}</div>
                <div class="mt-3 text-center getBackBlock" >{{global.content.getBackShortly}}</div>
            </div>
            <div *ngIf="global.showLoginScreen == 8 ">
                <div class="thankuHeadingBlock text-center colorBlue" >{{global.content.thankYou}}</div>
                <div class="mt-3 text-center underReviewBlock" >{{global.content.reqUnderReview}}</div>
            </div>
            <div class="d-flex justify-content-center"> 
                <img src="assets/images/organicLogin/detailsSubmittedImg.png" width="100%" height="auto">
            </div>
        </div>
        <div class="bankLoginBtnBlock d-flex justify-content-end p-0 my-4" *ngIf="global.showLoginScreen == 5 ">
            <button (click)="global.showLoginScreen = 1">
               {{global.content.goHome}} <img src="assets/images/icons/nextArrowWhite.png"
                    alt="netcArrowIcon">
            </button>
        </div>
    </div>
    <!-- account locked -->
    <div class="formBlockScreen " *ngIf="global.showLoginScreen == 6">
            <div class="bankLoginStepsBottomInnerBlock d-flex flex-column">
                <img src="assets/images/organicLogin/lockedScreenImg.png" class="lockedScreenImg">
                <div class="accountLockHeading ">{{global.content.yourAccountLocked}}</div>
                <div class="accountLockSubHeading ">
                    {{global.content.limitReached}}<br>{{global.content.loinAfter24Hours}}
                </div>
            </div>
            <div class="px-2 px-lg-3 orOptionBlock">
                <div class="d-flex orLineBlock align-items-center">
                    <div></div><span>OR</span><div></div>
                </div>
                <div  class="d-flex justify-content-center py-2 flex-wrap">
                    <div class="loggedInBlock">{{global.content.notKeyword}} {{mobileNumber ? 'xxxxxxx'  : 'xxx' }}{{trimmedValue}}?</div>
                    <div class="useAnotherLoginBlock" (click)="anotherLogin()">{{global.content.anotherLogin}}</div>
                </div>
            </div>
    </div>
      <!-- ARC Pre Login Form -->
    <div  *ngIf="global.showLoginScreen == 7 && commonServices ">
        <div class="text-center arcPopupBlock">
            <div class="d-flex justify-content-center">
                <img src="assets/images/organicLogin/ardPopupImg.png" width="150" height="auto">
            </div>
            <div class="arcHeadingBlock">{{global.content.pendingDues}}</div>
            <div class="mt-2">{{global.content.asPerRecords}} {{global.content.bankFullName}} {{global.content.asPerRecords1}}</div>
            <div class="mt-3">{{global.content.continue}} <span (click)="global.showLoginScreen = global.tempShowLoginScreen ;showOptOthers = false;clickTrack('redirectToLogin','click')" >{{global.content.continue1}}</span>{{global.content.andResolveDues}}</div>
            <div class="d-flex orLineBlock align-items-center my-3">
                <div></div><span>OR</span><div></div>
            </div>
            <div class="arcSecHeadingBlock">{{global.content.stillThinkNotRlevant}}</div>
            <div class="mt-1">{{global.contentletKnowWhy}}</div>
            <div>
                <form  #arcPreLoginForm="ngForm" (ngSubmit)="arcPreLoginForm.valid && submitArcPreLoginForm(arcPreLoginForm.value)">
                    <div>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group " >
                            <mat-radio-button *ngFor="let que of commonServices.preLoginArcQ" class="example-radio-button arcPreFormQBlock"  name="arcPreLogin" ngDefaultControl  ngModel #arcPreLogin="ngModel" required (change)="selectedOption($event)" [value]="que.value">
                                {{que.preLoginOpt}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div  class="arcPreFormQABlock" *ngIf="showOptOthers">
                            <textarea  placeholder="Type Here..." name="otherArea" ngModel #otherArea="ngModel" minlength="20" required pattern="^[a-zA-Z\d-_., ]+$"></textarea>
                            <div class="surveyValidationBox text-danger">
                                <div *ngIf="arcPreLoginForm.submitted && otherArea.invalid && otherArea.errors && otherArea.errors.required">
                                    {{global.content.optTextareaReq}}
                                </div>
                                <div *ngIf="otherArea.invalid && otherArea.errors && otherArea.errors.pattern">
                                    {{global.content.queryMsgError}}
                                </div>
                                <div *ngIf="otherArea.invalid && otherArea.errors && otherArea.errors.minlength">
                                    {{global.content.minlengthError}}
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="bankLoginBtnBlock px-0 mt-2">
                        <button type="submit">{{global.content.submit}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- Name List -->
    <div class="formBlockScreen "  *ngIf="global.showLoginScreen == 9">
        <app-logindobform (returnAnotherLogin)="anotherLogin()" [mobileNumberChild]="mobileNumber" [trimmedChildValue]="trimmedValue" (retunrPreLogin)="openArdPreLogin()" (returnSelectedValue)="submitNameARC($event)"  [notRelevantYou]="showRelevant"  [heading]="global.content.verifyName"  [dataList]="nameList"></app-logindobform>
    </div>

      <!-- Masked Mobile Number List -->
    <div class="formBlockScreen " *ngIf="global.showLoginScreen == 11">
        <app-logindobform (returnAnotherLogin)="anotherLogin()" [mobileNumberChild]="mobileNumber" [trimmedChildValue]="trimmedValue" (retunrPreLogin)="openArdPreLogin()"  [notRelevantYou]="showRelevant"  (returnSelectedValue)="submitMobile($event,'primaryNumber')"  [heading]="global.content.verifyMobile "  [dataList]="mobileNumberList"></app-logindobform>
    </div>

    <!-- no accound found -->
    <!-- <div class="formBlockScreen " *ngIf="global.showLoginScreen == 7">
        <div class="bankLoginStepsBottomInnerBlock">
            <div class="bankLoginstepperHeadings text-center">{{global.content.noAccountFound}}</div>
        </div>
        <div class="px-2 px-lg-3 orOptionBlock">
            <div class="d-flex orLineBlock align-items-center">
                <div></div><span>OR</span><div></div>
            </div>
            <div  class="d-flex justify-content-center py-2 flex-wrap">
                <div class="loggedInBlock">{{global.content.notKeyword}} {{mobileNumber ? 'xxxxxxx'  : 'xxx' }}{{trimmedValue}}?</div>
                <div class="useAnotherLoginBlock" (click)="anotherLogin()">{{global.content.anotherLogin}}</div>
            </div>
        </div>
    </div> -->
</div>


<ng-template #logoutSessoin let-modal>
    <div class="modal-body px-0 py-4">
        <div class="col-12 px-0 d-flex flex-wrap">
            <div class="col-12 text-center"><img src="assets/images/icons/logoutIconTwo.png" alt="logoutIcon"
                    class="mt-2 logoutIcon">
            </div>
            <div class="col-12 my-3 f-16 px-2 text-center">{{global.content.PleaseLogoutFromAllOtherDevices}}</div>
            <div class="col-12 d-flex justify-content-around p-0 pb-3">
                <button class="btn timeoutPopUpClose"
                    (click)="logoutSessoinHistory('No');modal.close();clickTrack('Close-logoutSession','click')">{{global.content.CloseBtn}}</button>
                <button class="btn timeoutPopUpWait"
                    (click)="logoutSessoinHistory('Yes');modal.close();clickTrack('Logout-logoutSession','click')">{{global.content.LogoutBtn}}</button>
            </div>
        </div>
    </div>
</ng-template>








