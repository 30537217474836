export const configObj = {
    baseUrl: 'http://uatpay.indusind.com/ethccx',
    baseUrlUuid: 'https://9gb0yuvsx9.execute-api.ap-south-1.amazonaws.com/test/uuidinfo/',
    key: 'enEthcred78cc6ui',
    paymentUrl: 'https://test.payu.in/_payment',
    paymentSurl: 'http://uatpay.indusind.com/ethccx/paymentGateway/response',
    paymentFurl: 'http://uatpay.indusind.com/ethccx/paymentGateway/response',
    dirPaymentSurl: 'http://uatpay.indusind.com/ethccx/paymentGateway/responsegen',
    dirPaymentFurl: 'http://uatpay.indusind.com/ethccx/paymentGateway/responsegen',
    captchaKey: '',
    termsAndCondition: 'https://www.indusind.com/in/en/personal/terms-and-conditions.html',
    privacyPolicy: 'https://www.indusind.com/in/en/personal/privacy-policy.html',
    creditcardTermsAndCondition: 'https://www.indusind.com/in/en/personal/cards/welcome-kit.html',
    disclaimer:'',
    bankEmail: '',
    settlementSuerveyQId: '1',
    contactEmail1:'reachus@indusind.com',
    contactEmail2: 'premium.care@indusind.com',
    bankMobile1: ['18602677777','022-44066666'],
    bankMobile2: ['18602677777','91-22-42207777'],
    bankId : 13,
    clouserLetterRequireEmail:""
};