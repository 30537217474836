import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Global } from './global';
import * as moment from 'moment';
import { CommonService } from './services/common.service'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { configObj } from './configuration';
import { DomSanitizer } from '@angular/platform-browser';
import * as engContent from '../assets/languagesConfig/en.json';

declare var bdPayment: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'BankCXFrontend';
  @ViewChild('paymentSuccessful') paymentSuccessful: any;
  @ViewChild('paymentPlan') paymentPlan: any;
  @ViewChild('paymentPlanSettelment') paymentPlanSettelment: any;
  @ViewChild('paymentForm') paymentForm: any;
  @ViewChild('offerRejected') offerRejected: any;
  @ViewChild('paymentplanApproved') paymentplanApproved: any;
  @ViewChild('timeOutModal') timeOutModal: any;
  @ViewChild('paymentRejectedModal') paymentRejectedModal: any;
  @ViewChild('paymentPageRedirectionModal') paymentPageRedirectionModal: any;
  @ViewChild('OfferLetterModal') OfferLetterModal: any;
  @ViewChild('paymentAlerts') paymentAlerts: any;
  @ViewChild('languagepopup') languagepopup: any;
  @ViewChild('callingPopup') callingPopup: any;
  @ViewChild('thankuCallingPopup') thankuCallingPopup: any;

  
  public engLang = engContent['default'];
  public config = configObj;
  public selectedTime:any;
  public intervalMain: any;
  public intervalSecond: any;
  public countdownTime: any;
  public countdownTimeSecond: any;
  public modalReference: any;
  public waiverRes: any;
  public num = 1000;
  public languageoption: any = [
    { full: 'English', short: 'en', langFull: 'English' },
    { full: 'हिन्दी', short: 'hi', langFull: 'Hindi' },
    { full: 'ಕನ್ನಡ', short: 'kn', langFull: 'Kannada' },
    { full: 'తెలుగు', short: 'te', langFull: 'Telugu' },
    { full: 'தமிழ்', short: 'ta', langFull: 'Tamil' },
    { full: 'ਪੰਜਾਬੀ', short: 'pb', langFull: 'Punjabi' },
    { full: 'বাংলা', short: 'bn', langFull: 'Bangla' },
    { full: 'मराठी', short: 'mr', langFull: 'Marathi' },
    { full: 'ગુજરાતી', short: 'gu', langFull: 'Gujarati' },
    { full: 'മല്യത്തിൽ', short: 'ma', langFull: 'Malayalam' }
  ];
  public queryTypeData = []
  public callPopupContent:any;
  public setupACall = false;
  public openSettlement:boolean = false;
  public settlementConsentRefrrer;
  constructor(
    private modalService: NgbModal,
    private bottomSheet: MatBottomSheet,
    public global: Global,
    public commonServices: CommonService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,


  ) {

  }
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    if ((this.commonServices && this.commonServices.userLogedIn) && !this.intervalSecond) {
      clearInterval(this.intervalMain);
      clearInterval(this.intervalSecond);
      this.firstTimeout();
    } else {
      clearInterval(this.intervalMain);
    }
  }
  ngOnInit(): void {

    if(window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations()
      .then(function(registrations) {
        for(let registration of registrations) {
          registration.unregister();
        }
      });
    }


    this.commonServices.trackUser('app_loaded_successfully','load',null);
    if(window && window.sessionStorage){
      this.commonServices.trackUser('enabled_sessionStorage','load',null)    
    }
    else{
      this.commonServices.trackUser('disabled_sessionStorage','load',null)    
    }
        
    var x = window.matchMedia("(max-width: 992px)")
    if (x.matches) {
      this.global['mobile'] = true;
    } else {
      this.global['mobile'] = false;
    }
    setTimeout(() => {
      if (this.commonServices && this.commonServices.userLogedIn) {
        this.firstTimeout();
      }
    }, 10);
  }

  public checkSession() {
    this.commonServices.checkSession().then(
      data => {
      }
    ).catch(
      error => {
        this.router.navigate(['login'])
        // this.commonServices['selectedAccountData'] = '';
      }
    );
  }


  public firstTimeout() {
    this.countdownTime = 900;
    this.intervalMain = setInterval(() => {
      if (this.commonServices && this.commonServices.userLogedIn) {
        this.countdownTime = this.countdownTime - 1;
        if (this.countdownTime == 0) {
          this.opentimeOutPopup();
          clearInterval(this.intervalMain);
        }
      }
    }, 1000);
  }
  opentimeOutPopup() {
    this.countdownTimeSecond = 300;
    this.modalReference = this.modalService.open(this.timeOutModal, { centered: true });
    this.intervalSecond = setInterval(() => {
      if (this.commonServices && this.commonServices.userLogedIn) {
        this.countdownTimeSecond = this.countdownTimeSecond - 1;
        if (this.countdownTimeSecond == 0) {
          this.modalReference.close();
          this.commonServices.logout().subscribe(
            data => {
              sessionStorage.clear();
              this.commonServices.responseData = undefined
              this.commonServices.selectedAccountData = undefined
              setTimeout(() => {
                this.router.navigate(['login'])
              }, 10);
            },
            error => {
            }
          );
        }
      }
    }, 1000);
  }
  clearIntervalFunction() {
    clearInterval(this.intervalMain);
  }
  public convertHoursForTable(value) {
    if (value == 0) {
      return "00:00";
    }
    value = Number(value);
    // var h = Math.floor(value / 3600);
    var m = Math.floor(value % 3600 / 60);
    var s = Math.floor(value % 3600 % 60);
    // var hDisplay = this.setrZero(h);
    var mDisplay = this.setrZero(m);
    var sDisplay = this.setrZero(s);
    return mDisplay + ':' + sDisplay;
  }

  private setrZero(val) {
    if (val) {
      val = val.toString();
      if (val.length == 1) {
        val = '0' + val
      }
    } else {
      val = '00'
    }
    return val
  }
  restartTimeOut() {
    this.clearIntervalFunction();
    clearInterval(this.intervalSecond);
    this.intervalSecond = undefined;
    this.firstTimeout();
    this.modalReference.close();
  }

  paymentSuccess() {
    this.modalService.open(this.paymentSuccessful, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
  }
  paymentUnsucessful() {
    this.modalService.open(this.paymentRejectedModal, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
  }

  public nochtml: any;
  opennocOfferLetterPopup() {
    var type = 'PreNoc';
    this.nochtml = '';
    var id = this.commonServices['selectedAccountData']['accountId'];
    this.commonServices.viewNocLetter(type, id).subscribe(
      res => {
        this.nochtml = this.sanitizer.bypassSecurityTrustResourceUrl(res['preNocHtmlLetterUrl']);
        this.modalService.open(this.OfferLetterModal, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'letterModal' });
        //Hide loader
        this.ngxService.stop();
      },
      error => {
        //Hide loader
        this.ngxService.stop();
        var err = error;
        this.ngxService.stop();
        this.commonServices.returnValidError(err)
      }
    );
    this.closeModal();
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  closepaymentModal() {
    this.modalService.dismissAll();
    this.router.navigate(['dashboard'])
  }

  openPaymentPlanPopUp() {
    this.modalService.open(this.paymentPlan, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
  }

  openSettlementPaymentPlanPopUp() {
    this.modalService.open(this.paymentPlanSettelment, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
  }

  otherAmountValidate: any = ''
  selectedAccountData: any = {};
  paymentSumary(status, paybleAmount) {
    this.commonServices['paymentModeStatus'] = status
    // this.commonServices.selectedAccountData = {}
    this.commonServices['otherAmountValidate'] = undefined
    this.commonServices['saAmountValidate'] = undefined
    this.commonServices['waAmountValidate'] = undefined
    this.commonServices['pAmountValidate'] = undefined
    var payAmount = paybleAmount
    if(status != 'OA' && status != 'SA' && status != 'PO' && status != 'WA'){
      payAmount = paybleAmount;
    }
    
    else if (status == 'OA') {
      payAmount = paybleAmount
      var minMAxArray = []
      if (this.commonServices.selectedAccountData.MinimumAmountDue) {
        minMAxArray.push(this.commonServices.selectedAccountData.MinimumAmountDue)
      }
      if (this.commonServices.selectedAccountData.OverDueAmount) {
        minMAxArray.push(this.commonServices.selectedAccountData.OverDueAmount)
      }
      if (this.commonServices.selectedAccountData.CurrentBalance) {
        minMAxArray.push(this.commonServices.selectedAccountData.CurrentBalance)
      }
      if (this.commonServices.selectedAccountData.baseSettlementAmount) {
        minMAxArray.push(this.commonServices.selectedAccountData.baseSettlementAmount)
      }
      if (this.commonServices.selectedAccountData.LastBucketAmount) {
        minMAxArray.push(this.commonServices.selectedAccountData.LastBucketAmount)
      }
      if (this.commonServices.selectedAccountData.Balance) {
        minMAxArray.push(this.commonServices.selectedAccountData.Balance)
      }
      if (this.commonServices.selectedAccountData.EmiAmount && this.commonServices.selectedAccountData.Penalty) {
        let amo = this.commonServices.selectedAccountData.EmiAmount + this.commonServices.selectedAccountData.Penalty
        minMAxArray.push(amo)
      }
      if (this.commonServices.selectedAccountData.EmiAmount && !this.commonServices.selectedAccountData.Penalty) {
        let amo = this.commonServices.selectedAccountData.EmiAmount
        minMAxArray.push(amo)
      }
      if (this.commonServices.selectedAccountData.TotalEmiAmount && this.commonServices.selectedAccountData.Penalty) {
        let amo = this.commonServices.selectedAccountData.TotalEmiAmount + this.commonServices.selectedAccountData.Penalty
        minMAxArray.push(amo)
      }
      if (this.commonServices.selectedAccountData.TotalEmiAmount && !this.commonServices.selectedAccountData.Penalty) {
        let amo = this.commonServices.selectedAccountData.TotalEmiAmount
        minMAxArray.push(amo)
      }
      if ((!this.commonServices.selectedAccountData.Balance && !this.commonServices.selectedAccountData.TotalEmiAmount && !this.commonServices.selectedAccountData.EmiAmount) && this.commonServices.selectedAccountData.Penalty) {
        let amo = this.commonServices.selectedAccountData.Penalty
        minMAxArray.push(amo)
      }
      if (this.commonServices.selectedAccountData.singleEmiPlusCharges ) {
        minMAxArray.push(this.commonServices.selectedAccountData.singleEmiPlusCharges )
      }
      if (this.commonServices.selectedAccountData.AllEmiOverduePlusCharges ) {
        minMAxArray.push(this.commonServices.selectedAccountData.AllEmiOverduePlusCharges )
      }
      

      var sortedArray = minMAxArray.sort(function (a, b) { return a - b })
      var minValue = 0
      var maxValue = 0

      if (sortedArray && sortedArray.length > 0) {
        minValue = sortedArray[0]
        maxValue = sortedArray[sortedArray.length - 1]
      }

      var finalMinValue = minValue
      var finalMaxValue = Math.ceil(maxValue)

      if ( this.config.bankId && this.config.bankId == 16 && minValue && this.commonServices.selectedAccountData['Bucket'] &&  this.commonServices.selectedAccountData['Bucket'] == 1) {
        minValue = this.findOtherMinValue()
        finalMinValue = Math.ceil(minValue)
      }
      else if (minValue < 100) {
        finalMinValue = 1
      } else if (minValue >= 100) {
        finalMinValue = 99
      } else {
        finalMinValue = minValue
      }

      if (finalMinValue == finalMaxValue) {
        if (minValue <= 100) {
          finalMinValue = 1
        }
        else {
          finalMinValue = 99
        }
      }


      if (paybleAmount <= finalMinValue) {
        this.commonServices['otherAmountValidate'] = this.global.content.amountGreaterThan + ' ' + this.commonServices.transformInr(finalMinValue) + this.global.content.amountGreatherThan2;
        this.showOfferErrorModal(this.commonServices['otherAmountValidate'])
        return false;
      }
      else if (paybleAmount > finalMaxValue) {
        this.commonServices['otherAmountValidate'] = this.global.content.amountLessThan + ' ' + this.commonServices.transformInr(finalMaxValue) + this.global.content.amountLessThan2;
        this.showOfferErrorModal(this.commonServices['otherAmountValidate'])
        return false;
      } else if (payAmount <= 0) {
        this.commonServices['otherAmountValidate'] = this.global.content.amountGreaterThanZero;
        this.showOfferErrorModal(this.commonServices['otherAmountValidate'])
        return false;
      } else {
        this.commonServices['otherAmountValidate'] = undefined
      }
    }

    else if (status == 'SA') {
      payAmount = paybleAmount

      if (this.commonServices.selectedAccountData.Balance && (paybleAmount > this.commonServices.selectedAccountData.Balance)) {
        this.commonServices['saAmountValidate'] = this.global.content.amountLessThan + this.commonServices.transformInr(this.commonServices.selectedAccountData.Balance) + this.global.content.amountLessThan2;
        this.showOfferErrorModal(this.commonServices['saAmountValidate'])

        return false;
      }

      if (this.commonServices.selectedAccountData.baseSettlementAmount && (paybleAmount > this.commonServices.selectedAccountData.baseSettlementAmount)) {
        this.commonServices['saAmountValidate'] = this.global.content.amountLessThan + this.commonServices.transformInr(this.commonServices.selectedAccountData.baseSettlementAmount) + this.global.content.amountLessThan2;
        this.showOfferErrorModal(this.commonServices['saAmountValidate'])

        return false;
      }

      if (!this.commonServices['saAmountValidate']) {
        this.setOneEmiOnPayTotalSettlement()
        this.clickTrack(this.commonServices.payRepeatScreen['googleTrack'], 'click')
        return false;
      }
    }

    else if (status == 'PO') {
      payAmount = paybleAmount

      if (this.commonServices.selectedAccountData.Balance && (paybleAmount > this.commonServices.selectedAccountData.Balance)) {
        this.commonServices['pAmountValidate'] = this.global.content.amountLessThan + this.commonServices.transformInr(this.commonServices.selectedAccountData.Balance) + this.global.content.amountLessThan2;
        this.showOfferErrorModal(this.commonServices['pAmountValidate']);
        return false;
      }
      if (this.commonServices.selectedAccountData.baseSettlementAmount && (paybleAmount > this.commonServices.selectedAccountData.baseSettlementAmount)) {
        this.commonServices['pAmountValidate'] = this.global.content.amountLessThan + this.commonServices.transformInr(this.commonServices.selectedAccountData.baseSettlementAmount) + this.global.content.amountLessThan2;
        this.showOfferErrorModal(this.commonServices['pAmountValidate'])
        return false;
      }
      if (!this.commonServices['pAmountValidate']) {
        this.setOneEmiOnPayTotalPrincipal()
        this.clickTrack(this.commonServices.payRepeatScreen['googleTrack'], 'click')
        return false;
      }
    }

    else if (status == 'WA') {
      payAmount = paybleAmount

      if (this.commonServices.selectedAccountData.Balance && (paybleAmount > this.commonServices.selectedAccountData.Balance)) {
        this.commonServices['waAmountValidate'] = this.global.content.amountLessThan + this.commonServices.transformInr(this.commonServices.selectedAccountData.Balance) + this.global.content.amountLessThan2;
        this.showOfferErrorModal(this.commonServices['waAmountValidate'])

        return false;
      }
      else if (this.commonServices.selectedAccountData.Penalty && (paybleAmount > this.commonServices.selectedAccountData.Penalty)) {
        this.commonServices['waAmountValidate'] = this.global.content.amountLessThan + this.commonServices.transformInr(this.commonServices.selectedAccountData.Penalty) + this.global.content.amountLessThan2;
        this.showOfferErrorModal(this.commonServices['waAmountValidate'])

        return false;
      }

      if (!this.commonServices['waAmountValidate']) {
        this.setOneEmiOnPayTotalWaiver()
        this.clickTrack(this.commonServices.payRepeatScreen['googleTrack'], 'click')
        return false;
      }
    }

    if (!this.commonServices['otherAmountValidate']) {
      if (payAmount) {
        this.openPaymentSummeryPopUp(new Date(), payAmount);
      }
    }

  }


  private setOneEmiOnPayTotalSettlement() {
    let arr = [];
    for (let i = 1; i <= 1; i++) {
      arr.push({
        paymentDate: moment(new Date()).add(i - 1, 'M').format('YYYY-MM-DD'), amount: (this.commonServices['selectedAccountData']['settlementAmount'] / 1)
      });
    }
    this.commonServices['paymentplanData'] = {
      selectedEmi: 1,
      selectedEmiDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedEmiAmount: (this.commonServices['selectedAccountData']['settlementAmount'] / 1),
      paymentPlan: arr
    }

    this.openSettlementPaymentPlanPopUp()
  }

  private setOneEmiOnPayTotalPrincipal() {
    let arr = [];
    for (let i = 1; i <= 1; i++) {
      arr.push({
        paymentDate: moment(new Date()).add(i - 1, 'M').format('YYYY-MM-DD'), amount: (this.commonServices['selectedAccountData']['principalAmount'] / 1)
      });
    }
    this.commonServices['paymentplanData'] = {
      selectedEmi: 1,
      selectedEmiDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedEmiAmount: (this.commonServices['selectedAccountData']['principalAmount'] / 1),
      paymentPlan: arr
    }
    this.openSettlementPaymentPlanPopUp()
  }

  private setOneEmiOnPayTotalWaiver() {
    let arr = [];
    for (let i = 1; i <= 1; i++) {
      arr.push({
        paymentDate: moment(new Date()).add(i - 1, 'M').format('YYYY-MM-DD'), amount: (this.commonServices['selectedAccountData']['waiverAmount'] / 1)
      });
    }
    this.commonServices['paymentplanData'] = {
      selectedEmi: 1,
      selectedEmiDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedEmiAmount: (this.commonServices['selectedAccountData']['waiverAmount'] / 1),
      paymentPlan: arr
    }

    this.openSettlementPaymentPlanPopUp()
  }

  public clickTrack(label, action) {
    this.commonServices.trackUser(label, action, this.commonServices['selectedAccountData'])
  }
  private makeFakePayment(data) {
    this.closeModal();
    setTimeout(() => {
      this.modalService.open(this.paymentPageRedirectionModal, { windowClass: 'animated zoomIn', keyboard: false, backdrop: 'static', centered: true });
    }, 1);
    setTimeout(() => {
      this.makePayment(data)
      // this.closeModal();
      // this.modalService.open(this.paymentSuccessful, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
    }, 10);
  }




  paymentSummeryModalData: any = []
  public openPaymentSummeryPopUp(date, amount) {
    this.paymentSummeryModalData = []
    if (amount && date) {
      let paymentSummeryModalData = {
        paymentAmount: amount,
        currentDate: moment(date).format('YYYY-MM-DD'),
        accountId: this.commonServices.selectedAccountData.accountId
      }
      this.paymentSummeryModalData.push(paymentSummeryModalData)
    }
    this.makeFakePayment(this.paymentSummeryModalData[0])
    // this.modalService.open(this.paymentSummery, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
  }

  showLoaderWait: any = false


  paymentResponse: object = {}
  private makePayment(data) {
    this.paymentResponse = {}
    // this.ngxService.start();
    // var amount = parseInt(data['paymentAmount'].toFixed(2))
    // var amount = data['paymentAmount'].toString()
    this.commonServices.payment(data['accountId'], data['paymentAmount']).subscribe(
      data => {
        this.paymentResponse = data
        this.paymentResponse['encKey'] = this.commonServices.generateDecRequest(data['encKey'])
        if(this.commonServices.selectedAccountData && this.commonServices.selectedAccountData['ProjectType'] && this.commonServices.selectedAccountData['ProjectType'] == 'ARD' ){
            this.paymentPopupOpen(data);
            this.closeModal();
          }
          else{
            setTimeout(() => {
              this.closeModal();
              this.paymentForm.nativeElement.submit();
            }, 5000);
          }
        this.ngxService.stop();
      },
      error => {
        this.closeModal();
        var err = error;
        this.ngxService.stop();
        this.commonServices.returnValidError(err)
        // this.toastr.error(this.global.content.somethingWentWrong);
        // this.modalService.open(this.paymentRejectedModal, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
      }
    );
  }
  selectedPaymentplanAmount: any = {}
  public submitPaymentplan(paymentPlanData) {
    this.selectedPaymentplanAmount = paymentPlanData
    this.commonServices['offerRequestResponse'] = undefined
    this.ngxService.start();
    this.commonServices.offerToClosedPlace(paymentPlanData, this.commonServices.selectedAccountData).subscribe(
      data => {
        this.matchandshowmodalMakeOffer(data)
        this.ngxService.stop();
      },
      error => {
        var err = error;
        this.ngxService.stop();
        this.matchandshowmodalMakeOffer(null)
      }
    );
    // this.closeModal();
    // this.modalService.open(this.paymentplanApproved, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
  }

  public submitSettelmentPaymentplan(paymentPlanData) {
    this.selectedPaymentplanAmount = paymentPlanData
    this.commonServices['offerRequestResponse'] = undefined
    // this.commonServices.openCards = true
    this.ngxService.start();
    this.commonServices.offerToSettelPlace(paymentPlanData, this.commonServices.selectedAccountData).subscribe(
      data => {
        this.matchandshowmodalMakeOffer(data)
        this.ngxService.stop();
      },
      error => {
        var err = error;
        this.ngxService.stop();
        if (err.status == 400) {
          this.matchandshowmodalMakeOffer(null)
        } else {
          this.commonServices.returnValidError(err)
        }
      }
    );
    // this.closeModal();
    // this.modalService.open(this.paymentplanApproved, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
  }

  public submitWaiverPaymentplan(paymentPlanData) {
    this.selectedPaymentplanAmount = paymentPlanData
    this.commonServices['offerRequestResponse'] = undefined
    // this.commonServices.openCards = true
    this.ngxService.start();
    this.commonServices.offerToWaiverPlace(paymentPlanData, this.commonServices.selectedAccountData).subscribe(
      data => {
        this.waiverRes = data;
        this.matchandshowmodalMakeOffer(data)
        this.ngxService.stop();
      },
      error => {
        var err = error;
        this.ngxService.stop();
        if (err.status == 400) {
          this.matchandshowmodalMakeOffer(null)
        } else {
          this.commonServices.returnValidError(err)
        }
      }
    );
    // this.closeModal();
    // this.modalService.open(this.paymentplanApproved, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
  }
  private matchandshowmodalMakeOffer(data) {
    if (data) {
      this.commonServices['offerRequestResponse'] = data;
      this.closeModal();
      if (data['offerStatus'].toUpperCase() == 'OFFERAPPROVED') {
        this.commonServices.openCards = true
        this.checkSession();
        // if(this.config.bankId && this.config.bankId == 13){
        //     this.checkSettlementConsent()
        // }
        // else{
          this.modalService.open(this.paymentplanApproved, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
        // }
      } else {
        // this.openSettlement = false;
        this.openOfferRejectModal()
      }
    } else {
      // this.openSettlement = false;
      this.openOfferRejectModal()
    }
  }

  openOfferRejectModal() {
    if (this.commonServices['offerRequestResponse'] && !this.commonServices['offerRequestResponse'].remainigPlacementChances) {
      this.commonServices['offerRequestResponse'] = undefined;
    }
    // this.commonServices['offerRequestResponse'] = undefined;
    this.commonServices.openCards = true
    this.checkSession();
    this.closeModal();
    this.modalService.open(this.offerRejected, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
  }

  offerErrorText: any = ''
  showOfferErrorModal(data) {
    this.offerErrorText = data
    this.modalService.open(this.paymentAlerts, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' })
    // alert(data)
  }
  /**
  *  This method is used to get last selected language 
  */
  public checkLang() {
    // var languageData = {};
    // if ((this.commonServices && this.commonServices.responseData && this.commonServices.responseData.accounts) || this.commonServices.detailsForLang['mobileNumber']) {
    //   languageData['mobileNumber'] = (this.commonServices.responseData && this.commonServices.responseData.accounts && this.commonServices.responseData.accounts[0].MobileNumber ) ? this.commonServices.responseData.accounts[0].MobileNumber : this.commonServices.detailsForLang.mobileNumber;
    //   languageData['email'] = (this.commonServices.responseData && this.commonServices.responseData.accounts) ? this.commonServices.responseData.accounts[0].email : this.commonServices.detailsForLang.email;
    //   this.commonServices.getLanguage(languageData).subscribe(
    //     data => {
    //       if (data && data['language']) {
    //         this.commonServices.setDataAfterLanguage(data['language']);
    //       }
    //     },
    //     error => {
    //     }
    //   );
    // }
  }
  public openlangPopup() {
    // const bottomSheetRef = this.bottomSheet.open(this.languagepopup, { panelClass: 'languageUsModal' });
    //chnage language on popup close
    // bottomSheetRef.afterDismissed().subscribe(() => {
    //   this.commonServices.chnglang(this.commonServices.detailsForLang['language']);
    // });
  }

  public openCallingPopup() {
    this.setupACall = false;
    this.modalService.open(this.callingPopup, { backdrop:'static' ,centered: true, windowClass: 'callingModal' });
  }

  public usercallingService() {
    this.ngxService.start();
    var number = this.commonServices['selectedAccountData']['MobileNumber'];
    this.commonServices.callingservice(number).subscribe(
      data => {
        this.ngxService.stop();
        this.setupACall = true;
      },
      error => {
        var err = error;
        this.ngxService.stop();
        this.commonServices.returnValidError(err)
      }
    )
  }

  reqForCallPlace(){
    this.ngxService.start();
    this.clickTrack('getCallStatus','load')
    this.commonServices.getCallDetails().subscribe(
      res => {
        this.ngxService.stop();
        this.callPopupContent = null
        if(res['status'] && res['status'].toLowerCase() == 'valid'){
          this.declareQueryType()
          this.callPopupContent = 'operational'
        }
        else if(res['status'] && res['status'].toLowerCase() == 'non_operational_hours'){
          this.callPopupContent = 'non-operational'
        }
        else if(res['status'] && res['status'].toLowerCase() == 'on_holiday'){
          this.callPopupContent = 'holiday'
        }
        else if(res['status'] && res['status'].toLowerCase() == 'no_agent_available'){
          this.declareQueryType()
          this.callPopupContent = 'operational'
        }
        this.openCallingPopup()
      },
      err =>{
        this.ngxService.stop();
        this.commonServices.returnValidError(err)
      }
    )
  }

  getDate(){
     var today = moment().format('YYYY-MM-DD') 
     return today ;
  }
  callPlaceDetails(value){
    // this.ngxService.start();
    this.clickTrack('submitCallDetails','submit')
    this.commonServices.postCallingDetails(value,this.commonServices.selectedAccountData['MobileNumber'],this.getDate()).subscribe(
      res =>{
        this.ngxService.stop();
        this.selectedTime = null
        this.closeModal()
        this.openThankuCallingPopup()
      },
      err =>{
        this.ngxService.stop();
        this.commonServices.returnValidError(err)
      }
    )
  }

  openThankuCallingPopup(){
    this.clickTrack('thankuPopupCallPlace','click')
    this.modalService.open(this.thankuCallingPopup, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
  }


  declareQueryType(){
    this.queryTypeData = [
      {"value":this.engLang.accountDetail,"showValue":this.global.content.accountDetail},
      {"value":this.engLang.offersSettlement,"showValue":this.global.content.offersSettlement},
      {"value":this.engLang.letters,"showValue":this.global.content.letters},
      {"value":this.engLang.payments,"showValue":this.global.content.payments},
      {"value":this.engLang.CIBILClosure,"showValue":this.global.content.CIBILClosure},
      {"value":this.engLang.other,"showValue":this.global.content.other}
    ]
  }
  paymentPopupOpen(data) {
    if(data['requestPG'] && data['callBackUrl']){
      bdPayment.initialize({
        "msg": data['requestPG'],

        "options": {
            "enableChildWindowPosting": true

        },
        "callbackUrl": data['callBackUrl']
    });
    }
}

private findOtherMinValue(){
  if( this.commonServices.selectedAccountData['EmiAmount'] && this.commonServices.selectedAccountData['EmiAmount'] > 0 &&  this.commonServices.selectedAccountData['Penalty'] > 0  ){
    return this.commonServices.selectedAccountData['EmiAmount']
  }
}

public openPaymentplanApproved(){
  this.clickTrack("openPlanApprovedPopup","auto")
  this.modalService.open(this.paymentplanApproved, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'bankModal' });
}

public checkSettlementConsent(paymentPlanData){
  this.clickTrack('check','getSettlementContsent')
  this.ngxService.stop()
  this.commonServices.okayForSettlementGet().subscribe(
    res =>{
      this.ngxService.stop()
      if(res && !res['isSettlementConsent']){
        this.modalService.dismissAll()
        this.openSettlement = true;
      }
      else{
        this.consentAlreadyGiven(paymentPlanData)
      }
    },
    err =>{
      this.ngxService.stop()
      // this.consentAlreadyGiven(paymentPlanData,status)
      this.commonServices.returnValidError(err)
    }
  )
}

public getSettlementApprovalStatus(paymentPlanData){
    if(this.config && this.config.bankId == 13){
      this.ngxService.start()
      this.commonServices.offerToSettelPlace(paymentPlanData,this.commonServices.selectedAccountData,null,null,true).subscribe(
        res => {
          this.ngxService.stop()
          if(res && res['offerStatus'].toUpperCase() == 'OFFERAPPROVED'){
            this.checkSettlementConsent(paymentPlanData)
          }
          else if(res && res['offerStatus'].toUpperCase() == 'OFFERREJECTED'){
              this.consentAlreadyGiven(paymentPlanData)
              // this.openOfferRejectModal()
          }
          else{
            this.toastr.error(this.global.content.somethingWentWrong)
          }
        },
        err => {
          this.ngxService.stop()
          this.commonServices.returnValidError(err)
        }
      )
    }
    else{
        this.consentAlreadyGiven(paymentPlanData)
    }
}

private consentAlreadyGiven(paymentPlanData){
  this.openSettlement = false;
  this.submitSettelmentPaymentplan(paymentPlanData)
}

}
